import { PromptResultFeature, PromptResultFeatureType } from '../enums';

export enum ReportType {
    // retool report named 'Extracted Data Report' is used for comparing fields extraction
    ContentQualityMeasurement = 'ContentQualityMeasurement',
    // retool report named 'Content Quality Measurement' is used for comparing generated content for editor
    ExtractedDataReport = 'ExtractedDataReport',
}

type PromptResultByReportType = Record<PromptResultFeatureType, ReportType>;

export const promptResultByReportTypeConfig: PromptResultByReportType = {
    [PromptResultFeature.SubmissionInsuredName]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionCoverageLines]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionApplicantPrimaryAddress]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionApplicantLocation]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionApplicantMailingAddress]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionTotalPayroll]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionTotalAnnualRevenue]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionTotalAssets]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionYearsOfOperation]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionLegalEntity]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionApplicationDomainName]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionIndustryClassification]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionOrganizationsCorporateStructure]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionPhoneNumber]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionPartTimeEmployeesNumber]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionFullTimeEmployeesNumber]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionEmployeesNumber]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionHasDomain]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionPlEffectiveDate]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionGlEffectiveDate]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionCyberEffectiveDate]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionMlEffectiveDate]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionEffectiveDate]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionNeedByDate]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionCyberLossRunIndicator]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionPLLossRunIndicator]: ReportType.ExtractedDataReport,
    [PromptResultFeature.QuoteInsight]: ReportType.ContentQualityMeasurement,
    [PromptResultFeature.AcknowledgmentEmail]: ReportType.ContentQualityMeasurement,
    [PromptResultFeature.QuotePremium]: ReportType.ExtractedDataReport,
    [PromptResultFeature.QuoteExpiresAt]: ReportType.ExtractedDataReport,
    [PromptResultFeature.QuoteTriaPremium]: ReportType.ExtractedDataReport,
    [PromptResultFeature.QuoteAggregateLimit]: ReportType.ExtractedDataReport,
    [PromptResultFeature.PolicyExpirationDate]: ReportType.ExtractedDataReport,
    [PromptResultFeature.QuoteCarrierFees]: ReportType.ExtractedDataReport,
    [PromptResultFeature.RiskAssessment]: ReportType.ContentQualityMeasurement,
    [PromptResultFeature.MarketingUpdateEmail]: ReportType.ContentQualityMeasurement,
    [PromptResultFeature.SubmissionDataApplicantContactName]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionDataApplicantEmailAddress]: ReportType.ExtractedDataReport,
    [PromptResultFeature.FileClassification]: ReportType.ExtractedDataReport,
};
