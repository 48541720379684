import { ReactNode } from 'react';
import { Box } from './mui-index';

interface MarkWrapperProps {
  children: ReactNode;
  color?: string;
}

export default function MarkedText({ children, color = 'green.200' }: MarkWrapperProps) {
  return (
    <Box
      component="span"
      sx={{
        '& mark': {
          backgroundColor: color,
        },
      }}
    >
      <Box component="mark">{children}</Box>
    </Box>
  );
}
