import { get, isEmpty } from 'lodash';
import { FieldErrors, FieldValues, UseFormReturn } from 'react-hook-form';

export async function asyncHandleSubmit<T>(formMethods: UseFormReturn<any, object>): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    formMethods.handleSubmit(
      (formData) => {
        resolve(formData);
      },
      (e) => {
        reject(e);
      },
    )();
  });
}

export function normalizeError(errors: FieldErrors<FieldValues>, name: string) {
  const errorMessage = get(errors, name)?.message;

  if (isEmpty(errorMessage)) {
    return undefined;
  }
  return typeof errorMessage === 'string' ? errorMessage : JSON.stringify(errorMessage);
}
