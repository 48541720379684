import { Button, Divider, IconButton, SearchBar, Stack } from '@common-components';
import { Close as CloseIcon } from '@icons';
import { messages } from 'i18n';
import Filter, { FilterButtonTypes } from 'broker/components/common/Filter';
import { UseProductFilterAttributes } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/AddProducts/types';
import CoveragesFilter from 'broker/pages/SubmissionWorkspacePage/components/TabsPanel/common/MarketRequestsWorkspace/components/Header/CoveragesFilter';

interface HeaderBottomSideContentProps {
  productsFilters: UseProductFilterAttributes;
  isToolboxOpened: boolean;
}

export default function HeaderBottomSideContent({ isToolboxOpened, productsFilters }: HeaderBottomSideContentProps) {
  const {
    filters: { coverageLines, isAdmitted, isPrimary },
  } = productsFilters;

  return (
    <Stack gap={1}>
      <Stack direction="row" alignItems="center" gap={1}>
        <SearchBar
          value={productsFilters.search}
          onChange={productsFilters.setSearch}
          placeholder={messages.addProductsPage.search}
          id="search-products"
          sx={{ minWidth: '40%' }}
        />
        <Filter
          buttonType={FilterButtonTypes.Dropdown}
          title={messages.addProductsPage.coverageLinesFilter.title}
          filters={coverageLines.filters}
          handleFilterChange={coverageLines.handleFilterChange}
          isAllFilters={coverageLines.isAllSelected}
          handleAllSelected={coverageLines.handleSelectAll}
          search
          searchPlaceholder={messages.addProductsPage.coverageLinesFilter.search}
        />
        <Filter
          buttonType={FilterButtonTypes.Dropdown}
          title={messages.addProductsPage.layersFilter.title}
          handleFilterChange={isPrimary.handleFilterChange}
          filters={isPrimary.filters}
          isAllFilters={isPrimary.isAllSelected}
          handleAllSelected={isPrimary.handleSelectAll}
        />
        <Filter
          buttonType={FilterButtonTypes.Dropdown}
          title={messages.addProductsPage.admittedFilter.title}
          handleFilterChange={isAdmitted.handleFilterChange}
          filters={isAdmitted.filters}
          isAllFilters={isAdmitted.isAllSelected}
          handleAllSelected={isAdmitted.handleSelectAll}
        />

        {productsFilters.isFilterApplied && (
          <Stack direction="row" gap={1}>
            <Divider flexItem orientation="vertical" variant="fullWidth" />
            {isToolboxOpened ? (
              <IconButton
                size="small"
                aria-label="close"
                onClick={productsFilters.clearFilters}
                icon={CloseIcon}
                color="secondary"
                variant="contained"
                tooltipContent={messages.buttons.clearFilters}
              />
            ) : (
              <Button startIcon={<CloseIcon />} size="small" color="secondary" onClick={productsFilters.clearFilters}>
                {messages.buttons.clearFilters}
              </Button>
            )}
          </Stack>
        )}
      </Stack>
      <CoveragesFilter
        coverageFilter={productsFilters.coverageLineFilters}
        handleVisibleLabelChange={productsFilters.handleVisibleCoverageChange}
      />
    </Stack>
  );
}
