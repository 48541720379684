import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { Grid, Stack } from '@common-components';
import { UserRole } from 'enums';
import { useFormProvider, useMutateTeam, useMutateUser } from 'hooks';
import { messages } from 'i18n';
import { User } from 'types';
import { DialogContent, DialogFooter } from 'components/Dialog';
import FormTextField from 'components/hookFormComponents/FormTextField';
import { BaseRoutedDialogContentProps } from 'broker/components/RoutedDialog';

import { AgentFormDataProps, AgentFormFieldsNames } from './types';
import { formFieldsConfig, FormMode, schema } from './utils';

interface FormContentProps
  extends Pick<BaseRoutedDialogContentProps, 'onClose' | 'closeWithoutPrompt' | 'setDialogIsDirty'> {
  defaultValues: AgentFormDataProps;
  mode: FormMode;
  id: string | null;
  organizationId: string;
  organizationName: string;
  teamId?: string;
  onAgentCreated?: (user: User) => void;
}

export default function FormContent({
  setDialogIsDirty,
  onClose,
  closeWithoutPrompt,
  id,
  defaultValues,
  organizationId,
  organizationName,
  teamId,
  mode,
}: FormContentProps) {
  const { methods } = useFormProvider({ defaultValues, schema, setIsDirty: setDialogIsDirty });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { createTeam } = useMutateTeam();
  const { updateUser, createUser } = useMutateUser();

  const onSubmitUser = async (data: AgentFormDataProps) => {
    const { firstName, lastName, phoneNumber, email, title } = data;
    setIsSubmitting(true);
    setDialogIsDirty(false);

    try {
      if (mode === FormMode.Update && id) {
        await updateUser.mutateAsync({
          id,
          data: {
            firstName,
            lastName,
            phoneNumber: phoneNumber ?? null,
            title: title ?? null,
          } as Partial<User>,
        });
      } else {
        const userTeamId =
          teamId ??
          (
            await createTeam.mutateAsync({
              data: {
                organizationId,
                name: organizationName,
              },
            })
          )?.id;

        if (userTeamId) {
          await createUser.mutateAsync({
            data: {
              firstName,
              lastName,
              phoneNumber: phoneNumber ?? undefined,
              email,
              role: UserRole.Retailer,
              organizationId,
              teamId: userTeamId,
              title: title ?? undefined,
            },
          });
        }
      }
      closeWithoutPrompt();
    } catch (e) {
      setDialogIsDirty(true);
      throw e;
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <DialogContent>
        <Stack maxWidth={640} margin="auto" gap={1}>
          <Grid columnSpacing={1} container>
            <Grid item xs={6}>
              <FormTextField {...formFieldsConfig[AgentFormFieldsNames.FirstName]} fullWidth />
            </Grid>
            <Grid item xs={6}>
              <FormTextField {...formFieldsConfig[AgentFormFieldsNames.LastName]} fullWidth />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                {...formFieldsConfig[AgentFormFieldsNames.Email]}
                disabled={mode === FormMode.Update}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField {...formFieldsConfig[AgentFormFieldsNames.PhoneNumber]} fullWidth />
            </Grid>
            <Grid item xs={6}>
              <FormTextField {...formFieldsConfig[AgentFormFieldsNames.Title]} fullWidth />
            </Grid>
          </Grid>
        </Stack>
      </DialogContent>
      <DialogFooter
        proceedButton={{
          children: messages.agenciesPage.agentForm.submit[mode],
          onClick: methods.handleSubmit(onSubmitUser),
          loading: isSubmitting,
        }}
        cancelButton={{
          children: messages.buttons.cancel,
          onClick: onClose,
        }}
      />
    </FormProvider>
  );
}
