import { ExtractedAttribute, SubmissionExtractedData } from '@common/types';
import { isUndefined, omitBy } from 'lodash';
import { useMemo } from 'react';
import { PromptResultFeature, PromptResultFeatureType } from 'enums';
import { ExtractedDataFields, SuggestedValueProps } from 'hooks';
import { Insured } from 'types';
import { isValidDomain, logger } from 'utils';
import { convertIndustryObjectToOption } from 'broker/components/common/IndustryAutoComplete/utils';
import { getValidDateSuggestion, getValidNumberSuggestion } from 'broker/utils';
import { SubmissionFormFieldsNames } from './types';

function buildSuggestedValueProps(
  extractedAttribute: ExtractedAttribute<any>,
  promptResultFeature: PromptResultFeatureType | null,
): SuggestedValueProps {
  return {
    suggestion: {
      value: extractedAttribute.value,
      reason: extractedAttribute.reason,
      citations: extractedAttribute.citations,
    },
    promptResultFeature,
  };
}

function extractValidNumberSuggestion(
  extractedAttribute: ExtractedAttribute<string>,
  promptResultFeature: PromptResultFeatureType,
): SuggestedValueProps | undefined {
  const validSuggestion = getValidNumberSuggestion(extractedAttribute);
  if (validSuggestion) {
    return buildSuggestedValueProps(
      {
        ...validSuggestion,
        value: validSuggestion.value?.toString(),
      },
      promptResultFeature,
    );
  }
  return undefined;
}

// function extractValidAddressSuggestion(
//   extractedAttribute: ExtractedAttribute<Address>,
//   promptResultFeature: PromptResultFeatureType,
// ): SuggestedValueProps | undefined {
//   const validSuggestion = getValidAddressSuggestion(extractedAttribute);
//   if (validSuggestion) {
//     return buildSuggestedValueProps(validSuggestion, promptResultFeature);
//   }
//
//   return undefined;
// }

function extractValidDateSuggestion(
  extractedAttribute: ExtractedAttribute<string>,
  promptResultFeature: PromptResultFeatureType,
): SuggestedValueProps | undefined {
  const validSuggestion = getValidDateSuggestion(extractedAttribute);
  if (validSuggestion) {
    const suggestedValueProps = buildSuggestedValueProps(validSuggestion, promptResultFeature);
    return {
      ...suggestedValueProps,
      promptResultFeature,
    };
  }

  return undefined;
}

export function getDueDateSuggestion(submissionExtractedData: SubmissionExtractedData) {
  const { extractedData } = submissionExtractedData;
  if (!extractedData) {
    return undefined;
  }
  return (
    extractedData.effectiveDateCyber ||
    extractedData.effectiveDateGL ||
    extractedData.effectiveDatePL ||
    extractedData.effectiveDateML
  );
}

interface GetStaticSubmissionExtractedDataProps {
  submissionExtractedData?: SubmissionExtractedData;
  insuredList: Insured[];
  insuredListLoading: boolean;
}

export function useGetStaticSubmissionExtractedData({
  submissionExtractedData,
  insuredList,
  insuredListLoading,
}: GetStaticSubmissionExtractedDataProps): ExtractedDataFields | undefined {
  return useMemo(() => {
    const extractedData = submissionExtractedData?.extractedData;

    if (extractedData) {
      try {
        const insuredId =
          extractedData.insuredName?.value && !insuredListLoading
            ? insuredList.find((insured) => insured.name === extractedData.insuredName!.value)?.id
            : undefined;

        // const primaryAddressSuggestion = extractedData.address?.value?.primary
        //   ? extractValidAddressSuggestion(
        //       {
        //         value: extractedData.address.value.primary,
        //         reason: extractedData.address.reason,
        //         citations: extractedData.address.citations,
        //       },
        //       PromptResultFeature.SubmissionApplicantPrimaryAddress,
        //     )
        //   : undefined;
        //
        // const mailingAddressSuggestion = extractedData.address?.value?.mailing
        //   ? extractValidAddressSuggestion(
        //       {
        //         value: extractedData.address.value.mailing,
        //         reason: extractedData.address.reason,
        //         citations: extractedData.address.citations,
        //       },
        //       PromptResultFeature.SubmissionApplicantMailingAddress,
        //     )
        //   : undefined;

        const dueDateSuggestion = getDueDateSuggestion(submissionExtractedData);

        const suggestedValueProps: Record<string, SuggestedValueProps | undefined> = {
          [SubmissionFormFieldsNames.InsuredName]: extractedData.insuredName
            ? buildSuggestedValueProps(extractedData.insuredName, PromptResultFeature.SubmissionInsuredName)
            : undefined,
          insuredId: extractedData.insuredName
            ? buildSuggestedValueProps(
                {
                  ...extractedData.insuredName,
                  value: insuredId,
                },
                null,
              )
            : undefined,
          [SubmissionFormFieldsNames.InsuredWebsite]:
            extractedData?.applicationDomainName && isValidDomain(extractedData.applicationDomainName.value)
              ? buildSuggestedValueProps(
                  extractedData.applicationDomainName,
                  PromptResultFeature.SubmissionApplicationDomainName,
                )
              : undefined,
          [SubmissionFormFieldsNames.InsuredEmployeeCount]: extractedData?.employeesNumber
            ? extractValidNumberSuggestion(extractedData.employeesNumber, PromptResultFeature.SubmissionEmployeesNumber)
            : undefined,
          [SubmissionFormFieldsNames.InsuredAnnualRevenue]: extractedData?.totalAnnualRevenue
            ? extractValidNumberSuggestion(
                extractedData.totalAnnualRevenue,
                PromptResultFeature.SubmissionTotalAnnualRevenue,
              )
            : undefined,
          [SubmissionFormFieldsNames.Industry]: extractedData?.industry?.value?.mappedIndexEntry?.[0]?.heraldId
            ? buildSuggestedValueProps(
                {
                  value: convertIndustryObjectToOption({
                    heraldId: extractedData.industry?.value.mappedIndexEntry?.[0].heraldId,
                    naicsCode: extractedData.industry?.value.mappedIndexEntry?.[0].naicsCode,
                    description: extractedData.industry?.value.mappedIndexEntry?.[0].indexEntryDescription,
                  }),
                  reason: extractedData.industry.reason,
                  citations: extractedData.industry.citations,
                },
                PromptResultFeature.SubmissionIndustryClassification,
              )
            : undefined,
          // [SubmissionFormFieldsNames.InsuredPrimaryAddress]: primaryAddressSuggestion?.suggestion.value
          //   ? {
          //       ...primaryAddressSuggestion,
          //       suggestion: {
          //         ...primaryAddressSuggestion.suggestion,
          //         value: convertAddressToAddressDetails(primaryAddressSuggestion.suggestion.value),
          //       },
          //     }
          //   : undefined,
          // [SubmissionFormFieldsNames.InsuredMailingAddress]: mailingAddressSuggestion?.suggestion.value
          //   ? {
          //       ...mailingAddressSuggestion,
          //       suggestion: {
          //         ...mailingAddressSuggestion.suggestion,
          //         value: convertAddressToAddressDetails(mailingAddressSuggestion.suggestion.value),
          //       },
          //     }
          //   : undefined,
          [SubmissionFormFieldsNames.DueDate]: dueDateSuggestion
            ? extractValidDateSuggestion(dueDateSuggestion, PromptResultFeature.SubmissionEffectiveDate)
            : undefined,
          [SubmissionFormFieldsNames.NeedByDate]: extractedData.needByDate
            ? extractValidDateSuggestion(extractedData.needByDate, PromptResultFeature.SubmissionNeedByDate)
            : undefined,
        };
        return omitBy(suggestedValueProps, isUndefined) as ExtractedDataFields;
      } catch (error) {
        logger.log('error', {
          message: 'useGetSubmissionStaticExtractedData: unexpected error, extracted data will not be used',
          error,
          extractedData,
        });
      }
    }
    return undefined;
  }, [insuredList, insuredListLoading, submissionExtractedData]);
}
