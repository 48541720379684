import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom-latest';
import { OrganizationType } from 'enums';
import { useRetrieveUserMarket } from 'hooks';
import { messages } from 'i18n';
import { UserMarket } from 'types';
import { DialogContent, DialogFooter, DialogHeader } from 'components/Dialog';
import { BaseRoutedDialogContentProps } from 'broker/components/RoutedDialog';
import FormSkeleton from 'broker/components/skeletons/FormSkeleton';
import { getMarketNameWithType } from 'broker/components/UserMarkets/components/utils';
import FormContent from './FormContent';
import { ContactFormProps, UserMarketDialogLocationState, UserMarketFormProps } from './types';
import { FormMode, UrlParam } from './utils';

const getFormUserMarket = (userMarket: UserMarket): UserMarketFormProps => {
  const { marketName, riskAppetite, memo, contacts, categories, organizationType } = userMarket;

  return {
    marketName,
    organizationId: userMarket.organizationId,
    marketId: userMarket.marketId,
    riskAppetite,
    memo,
    marketOrganizationType: organizationType,
    categories,
    contacts:
      contacts.map(
        (contact): ContactFormProps => ({
          id: contact.id,
          firstName: contact.firstName || '',
          lastName: contact.lastName || '',
          email: contact.email,
          phoneNumber: contact.phoneNumber || '',
          title: contact.title || '',
          role: contact.role,
          organizationType: contact.organizationType,
        }),
      ) || [],
  };
};

export interface ContentProps extends BaseRoutedDialogContentProps {
  onMarketCreated?: (market: UserMarket) => void;
  locationState?: UserMarketDialogLocationState;
}

export default function Content({
  ariaLabelId,
  onClose,
  setDialogIsDirty,
  closeWithoutPrompt,
  onMarketCreated,
  locationState,
}: ContentProps) {
  const [searchParams] = useSearchParams();

  const state = locationState;

  const id = searchParams.get(UrlParam.Id) ?? '';

  const { data, isLoading } = useRetrieveUserMarket({ id });

  const mode = id ? FormMode.Update : FormMode.Create;

  const title =
    mode === FormMode.Update
      ? messages.userMarketModal.title[mode](data ? getMarketNameWithType(data.marketName, data.organizationType) : '')
      : messages.userMarketModal.title[mode];

  const defaultValues = useMemo<UserMarketFormProps>(() => {
    const formUserMarket = data && getFormUserMarket(data);
    return {
      ...formUserMarket,
      marketName: state?.marketName ?? '',
      organizationId: formUserMarket?.organizationId ?? '',
      marketId: formUserMarket?.marketId ?? '',
      contacts: formUserMarket?.contacts ?? [],
      marketOrganizationType: formUserMarket?.marketOrganizationType ?? OrganizationType.Carrier,
    };
  }, [data, state?.marketName]);

  return (
    <>
      <DialogHeader id={ariaLabelId} onClose={onClose} title={title} />

      {!id || (data && !isLoading) ? (
        <FormContent
          mode={mode}
          setDialogIsDirty={setDialogIsDirty}
          onClose={onClose}
          defaultValues={defaultValues}
          closeWithoutPrompt={closeWithoutPrompt}
          id={id}
          scrollTo={state?.scrollTo}
          onMarketCreated={onMarketCreated}
          defaultMarketName={state?.marketName}
        />
      ) : (
        <>
          <DialogContent>
            <FormSkeleton />
          </DialogContent>
          <DialogFooter showSkeleton />
        </>
      )}
    </>
  );
}
