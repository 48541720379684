import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty, isEqual, isNil } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { DefaultValues, FieldValues, Path, useForm, useWatch } from 'react-hook-form';
import { AnyObjectSchema } from 'yup';
import { PromptResultFeatureType } from 'enums';
import { Suggestion } from 'types';
import { getFirstErrorId, scrollToElement } from 'utils';

export type ExtractedDataFields = Record<string, SuggestedValueProps>;

export interface SuggestedValueProps {
  suggestion: Suggestion;
  // intentionally put null if this field is not relevant to the report
  promptResultFeature: PromptResultFeatureType | null;
  setSuggestedValue?: (newValue: any, oldValue?: any) => any;
  isFormFieldEmpty?: (formValue: any) => any;
  isEqualToFormValue?: (suggestion: Suggestion, formValue: any) => boolean;
}

interface FormProps<DataType> {
  schema: AnyObjectSchema;
  defaultValues?: DefaultValues<DataType>;
  suggestedValues?: ExtractedDataFields;
  applySuggestionsOnEmptyFields?: boolean;
  setIsDirty?: (state: boolean) => void;
  viewMode?: boolean;
  mode?: 'onSubmit' | 'onChange' | 'onTouched' | 'onBlur';
  reValidateMode?: 'onChange' | 'onBlur' | 'onSubmit';
}

export interface FormFieldsConfigProps<T> {
  name: T;
  label: string;
  placeholder?: string;
  id: string;
  optional?: boolean;
}

export function useFormProvider<DataType extends FieldValues>({
  schema,
  defaultValues,
  setIsDirty,
  mode = 'onTouched',
  reValidateMode = 'onChange',
  suggestedValues,
  applySuggestionsOnEmptyFields = false,
}: FormProps<DataType>) {
  // Holds a subset of the suggested values that were applied to the form according to the logic of not overriding user input,
  // this will be used to determine the fields that should be displayed as suggested in the UI (with the overlay)
  const [appliedSuggestedValuesMap, setAppliedSuggestedValuesMap] = useState(new Map<string, Suggestion>());

  // Holds the fields that were already applied with a suggestion at least once,
  // in case the user wants to clear the suggestion we will know not to re-apply it
  const [suggestionAppliedOnce] = useState(new Set<string>());
  const methods = useForm<DataType>({
    mode,
    reValidateMode,
    resolver: yupResolver(schema),
    defaultValues,
    delayError: 200,
    criteriaMode: 'firstError',
  });
  const { setValue: setFormValue, formState, control } = methods;
  const watchedValues = useWatch({ control });

  useEffect(() => {
    if (!formState.isValid) {
      scrollToElement(getFirstErrorId(formState.errors));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.submitCount]);

  useEffect(() => {
    if (formState.isDirty) {
      setIsDirty?.(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.isDirty]);

  // Tracking suggested values changes and updating the form values accordingly
  // Simplifying assumption: once we set a field value by a suggestion we do not automatically clear if the suggestions change
  useEffect(() => {
    const currentValues = watchedValues;
    if (suggestedValues) {
      // Recreate newSuggestedValueMap to reflect the values that are relevant now
      const newSuggestedValueMap = new Map<string, Suggestion>();
      Object.keys(suggestedValues).forEach((key) => {
        if (!suggestedValues[key]) {
          return;
        }
        const suggestionValueConfig = suggestedValues[key]!;
        const {
          suggestion: { value },
          isEqualToFormValue,
          setSuggestedValue,
          isFormFieldEmpty,
        } = suggestionValueConfig;
        const currentFormValue = currentValues[key as Path<DataType>];
        // Only add suggestions with values and if the field (key) exists in the form
        if (key in currentValues && !isNil(value)) {
          const isEmptyValue = isFormFieldEmpty ? isFormFieldEmpty(currentFormValue) : isEmpty(currentFormValue);
          const wasSuggestionAppliedOnce = suggestionAppliedOnce.has(key);

          // Allow setting the value only if it is currently empty and wasn't applied before and removed by the user
          if (applySuggestionsOnEmptyFields && isEmptyValue && !wasSuggestionAppliedOnce) {
            setFormValue(key as Path<DataType>, setSuggestedValue ? setSuggestedValue(value, currentFormValue) : value);
            newSuggestedValueMap.set(key, suggestionValueConfig.suggestion);
            setIsDirty?.(true);
            suggestionAppliedOnce.add(key);
            // If the current value matches the suggested value consider it as suggested as well
          } else if (
            isEqualToFormValue
              ? isEqualToFormValue(suggestionValueConfig.suggestion, currentFormValue)
              : isEqual(currentFormValue, value)
          ) {
            newSuggestedValueMap.set(key, suggestionValueConfig.suggestion);
          }
        }
      });
      setAppliedSuggestedValuesMap(newSuggestedValueMap);
    }
    // suggestedValues is undefined: clear all the values from suggested values map
    else if (appliedSuggestedValuesMap.size > 0) {
      setAppliedSuggestedValuesMap(new Map<string, Suggestion>());
    }
    // Not adding appliedSuggestedValuesMap and suggestionAppliedOnce as a dependency to avoid infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestedValues, setFormValue, watchedValues]);

  return {
    methods,
    appliedSuggestions: useMemo(
      () => Object.fromEntries(appliedSuggestedValuesMap.entries()),
      [appliedSuggestedValuesMap],
    ),
  };
}
