import { compact, intersectionBy, isEmpty, orderBy } from 'lodash';
import { LinkProps } from '@common-components';
import {
  ActivityType,
  DeclinationType,
  FormMode,
  HeraldQuoteStatus,
  SubmissionMarketRequestStatus,
  SubmissionMethod,
} from 'enums';
import { messages } from 'i18n';
import theme from 'themes';
import { ActivityLog, ExtendedMarketRequest, SubmissionMarketRequest } from 'types';
import {
  attachmentPointText,
  calculateDaysDiff,
  formatBigNumber,
  formatPrice,
  getCoverageLinesText,
  getDisplayDateWithHoursAmPm,
} from 'utils';
import { getDeclinationMetadataByType } from 'broker/configuration-mappers/declination-config';
import { informationRequestedConfig } from 'broker/configuration-mappers/information-request-config';
import { RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';
import { useWorkspaceUrls } from 'broker/pages/SubmissionWorkspacePage/hooks';
import useEmailActivityLink from './useEmailActivityLink';

const workspaceMessages = messages.submissionWorkspace.marketRequestsWorkspace;

const getDeclinedEmptySlot = (marketRequest: ExtendedMarketRequest) => {
  const title = compact(
    marketRequest.declination?.declinationReasons.map((declinationReason) =>
      declinationReason.type === DeclinationType.OTHER
        ? declinationReason.details
        : getDeclinationMetadataByType(declinationReason.type)!.reason,
    ),
  ).join(', ');

  const subtitle = workspaceMessages.declinedEmptySlot(getDisplayDateWithHoursAmPm(marketRequest.updatedAt));

  return {
    title,
    subtitle,
    isAIExtracted: marketRequest.declination?.isAIExtracted,
  };
};

const getPendingCustomerEmptySlot = (marketRequest: SubmissionMarketRequest) => {
  if (isEmpty(marketRequest.informationRequested)) {
    return {
      title: '',
      subtitle: '',
    };
  }

  const subtitle = workspaceMessages.pendingCustomerEmptySlot.informationRequestedOn(
    getDisplayDateWithHoursAmPm(marketRequest.updatedAt),
  );

  const informationRequests = marketRequest.informationRequested!;
  const informationRequestsConfig = informationRequestedConfig(marketRequest);

  const title =
    informationRequests[0].type === 'Other'
      ? informationRequests[0].details!
      : informationRequestsConfig[informationRequests[0].type]!;

  return {
    title:
      informationRequests.length === 1
        ? title
        : workspaceMessages.pendingCustomerEmptySlot.informationRequests(informationRequests.length),
    subtitle,
  };
};
const useStatusSpecificMetadata = (
  marketRequest: ExtendedMarketRequest,
  emailActivity?: ActivityLog,
):
  | {
      title: string;
      subtitle: string;
      isAIExtracted?: boolean;
      linkProps?: Omit<LinkProps, 'children'> & { linkText?: string };
      titleTextAfterLink?: string;
    }
  | undefined => {
  const navigate = useNavigate();
  const { quoteFormUrl } = useWorkspaceUrls();
  const emailActivityLinkProps = useEmailActivityLink({ emailActivity });
  const timestampSubtitle = getDisplayDateWithHoursAmPm(marketRequest.updatedAt);

  if (marketRequest.status === SubmissionMarketRequestStatus.Declined) {
    return getDeclinedEmptySlot(marketRequest);
  }

  if (marketRequest.status === SubmissionMarketRequestStatus.PendingCustomer) {
    return getPendingCustomerEmptySlot(marketRequest);
  }

  // If the status is QuoteReady but quote is still partial (Flow quote wasn't generated yet), invite the user to continue editing
  if (
    marketRequest.status === SubmissionMarketRequestStatus.QuoteReady &&
    marketRequest.quote &&
    isEmpty(marketRequest.quote.capitolaQuoteFileIds)
  ) {
    return {
      title: workspaceMessages.continueQuoteEditingTitle(
        marketRequest.quote.createdByUserDetails?.role,
        marketRequest.quote.source,
      ),
      subtitle: timestampSubtitle,
      linkProps: {
        onClick: () =>
          navigate(quoteFormUrl, {
            routeKey: RouteKey.Quote,
            state: {
              selectedMarket: marketRequest.market,
              selectedQuote: marketRequest.quote,
              mode: FormMode.edit,
              insuranceProductId: marketRequest.insuranceProduct?.id,
              submissionMarketRequestId: marketRequest.id,
            },
          }),
        linkText: workspaceMessages.continueQuoteEditingLinkText,
      },
      titleTextAfterLink: workspaceMessages.continueQuoteEditingLinkSuffix,
    };
  }

  const { awaitingQuoteEmptySlot, readyToMarketEmptySlot, methods, awaitingQuoteReferral, awaitingQuoteReferred } =
    workspaceMessages;

  if (
    [SubmissionMarketRequestStatus.AwaitingQuote, SubmissionMarketRequestStatus.ReadyToMarket].includes(
      marketRequest.status,
    )
  ) {
    const { viaApi, openPortal } = methods;
    const isIncumbent = !isEmpty(marketRequest.incumbentInfo);
    const title =
      marketRequest.status === SubmissionMarketRequestStatus.AwaitingQuote
        ? awaitingQuoteEmptySlot
        : readyToMarketEmptySlot.addedToSubmission(isIncumbent);

    const getTitleForSubmitViaApiMetadata = () => {
      switch (marketRequest.heraldQuoteStatus) {
        case HeraldQuoteStatus.Referred:
          return awaitingQuoteReferred;
        case HeraldQuoteStatus.Referral:
          return awaitingQuoteReferral;
        default:
          return `${title} ${viaApi}`;
      }
    };

    switch (marketRequest.submissionMethod) {
      case SubmissionMethod.API:
        return {
          title: getTitleForSubmitViaApiMetadata(),
          subtitle: timestampSubtitle,
        };
      case SubmissionMethod.Portal:
        return {
          title,
          subtitle: timestampSubtitle,
          linkProps: {
            href: marketRequest.insuranceProduct?.submissionPortal,
            target: '_blank',
            linkText: openPortal,
            ...emailActivityLinkProps,
          },
        };
      case SubmissionMethod.Email:
        return {
          title,
          subtitle: timestampSubtitle,
          linkProps: {
            ...emailActivityLinkProps,
          },
        };
      default:
        return { title, subtitle: timestampSubtitle };
    }
  }

  return undefined;
};

export function useGetQuoteMetadataData(
  marketRequest: ExtendedMarketRequest,
  emailActivity?: ActivityLog,
): {
  title: string;
  description?: string;
  subtitle?: string;
  isAIExtracted?: boolean;
  linkProps?: Omit<LinkProps, 'children'> & { linkText?: string };
  titleTextAfterLink?: string;
} {
  const statusSpecificMetadata = useStatusSpecificMetadata(marketRequest, emailActivity);

  // Some statuses metadata is unique, if this is the case, return it, otherwise fallback to the default behavior
  if (statusSpecificMetadata) {
    return statusSpecificMetadata;
  }

  if (!marketRequest.quote) {
    return { title: '', subtitle: '' };
  }

  // Build default quote summary
  const detailsArray = [
    `${messages.general.attachmentPoint}: ${attachmentPointText(marketRequest.layer?.attachmentPoint)}`,
    `${messages.general.premium}: $${formatPrice(marketRequest.quote.premium)}`,
    marketRequest.quote.limit
      ? `${messages.general.aggregateLimit}: $${formatBigNumber(marketRequest.quote.limit)}`
      : '',
  ];

  const productCoverageLines = marketRequest.insuranceProduct?.coverageLines.map((item) => item.coverageLine);
  if (marketRequest.submissionCoverageLines && productCoverageLines && productCoverageLines.length > 0) {
    const coverageLines = intersectionBy(marketRequest.submissionCoverageLines, productCoverageLines);
    if (coverageLines && coverageLines.length > 0) {
      detailsArray.push(`${messages.general.coverageLines}: ${getCoverageLinesText(coverageLines)}`);
    }
  }

  return {
    title: `${detailsArray.filter(Boolean).join(' | ')}`,
    description: '',
    subtitle: '',
  };
}

export const mapMarketRequestToEmailActivity = (
  marketRequest: ExtendedMarketRequest,
  activities: ActivityLog[],
): ActivityLog | undefined => {
  const logEntries = orderBy(activities, 'date', 'desc');
  switch (marketRequest.status) {
    case SubmissionMarketRequestStatus.AwaitingQuote:
      return logEntries.find((log) => ActivityType.RequestForQuote === log.activityType);
    case SubmissionMarketRequestStatus.QuoteReady:
      return logEntries.find((log) => ActivityType.BindRequest === log.activityType);
    case SubmissionMarketRequestStatus.Bound:
      return logEntries.find((log) => ActivityType.PolicyRequested === log.activityType);
    default:
      return undefined;
  }
};

export const getQuoteReadyColor = (marketRequest: ExtendedMarketRequest): string | undefined => {
  if (!marketRequest.quote?.expiresAt) {
    return undefined;
  }
  return calculateDaysDiff(marketRequest.quote?.expiresAt) <= 0
    ? theme.palette.coralRed[100]
    : theme.palette.aquaGreen[100];
};

export const getQuoteExpirationSubtitle = (marketRequest: ExtendedMarketRequest) => {
  if (!marketRequest.quote?.expiresAt) {
    return undefined;
  }
  const daysDiff = calculateDaysDiff(marketRequest.quote?.expiresAt);
  if (daysDiff < 0) {
    return `* ${messages.market.expiredOn(marketRequest.quote?.expiresAt)}`;
  }
  if (daysDiff > 0) {
    return `* ${messages.market.expiresIn(daysDiff)}`;
  }
  return `* ${messages.market.expiresToday}`;
};
