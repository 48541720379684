import { formatDateWithoutTime } from '@common/utils/date-utils';
import { isNil, toNumber } from 'lodash';
import { HeraldSchemaFormat } from 'clients/types';
import { HeraldNormalizedParameter } from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/DynamicForm/types';
import { doesObjectContainNonEmptyValues } from './validations-utils';

export function removeEndingAfterUnderscore(str: string) {
  const lastIndex = str.lastIndexOf('_');
  if (lastIndex !== -1) {
    return str.substring(0, lastIndex);
  }
  return str; // No underscore found, return original string
}

export function normalizeValueToHeraldBySchema(value: any, schema: HeraldNormalizedParameter['schema']) {
  // if user deleted all text field then we send to herald null, so they will know this question is not answered
  // or if value is null/undefined we send null to herald
  if (value === '' || isNil(value)) {
    return null;
  }

  if (['integer', 'number'].includes(schema.type)) {
    return toNumber(value);
  }
  if (schema.format === HeraldSchemaFormat.Date) {
    return formatDateWithoutTime(value, 'YYYY-MM-DD');
  }

  if (schema.type === 'object') {
    return doesObjectContainNonEmptyValues(value)
      ? // normalizing properties values inside object
        Object.keys(value).reduce((acc, curr) => {
          // @ts-ignore
          acc[curr] = normalizeValueToHeraldBySchema(value[curr], schema.properties![curr]);
          return acc;
        }, {})
      : // object is empty send null instead of object with empty properties
        null;
  }

  return value;
}
