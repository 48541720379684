import { Transition } from 'history';
import { isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import { flushSync } from 'react-dom';
import { FormProvider } from 'react-hook-form';
import { Box, Button, Stack } from '@common-components';
import { CoverageLine, SubmissionStatus, SubmissionTask, SubmissionTaskStatus } from 'enums';
import { ExtractedDataFields, useFormProvider, useMutateSubmission } from 'hooks';
import { messages } from 'i18n';
import { PartialSubmission } from 'types';
import { coverageLineConfig } from 'utils';
import { FormMultiSelectAutocomplete } from 'components/hookFormComponents/FormMultiSelectAutocomplete';
import { BROKER_NESTED_ROUTES } from 'broker/broker-routes';
import Banner, { BannerMode } from 'broker/components/Banner';
import InfoBanner from 'broker/components/common/InfoBanner';
import { DirtyRouteGuard } from 'broker/components/DirtyContentGuard';
import { AIReason } from 'broker/pages/SubmissionWorkspacePage/components/AIGeneratedContent/AIReason';
import InnerPageLayout from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/InnerPageLayout';
import ApiProductsDropDown from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/EditSubmissionNew/components/ApiProductsDropDown';
import { useIsAcknowledgmentFlow } from 'broker/pages/SubmissionWorkspacePage/hooks';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { CoverageLineChanges } from 'broker/pages/SubmissionWorkspacePage/utils/types';
import { convertCoverageLinesTextToEnum } from 'broker/utils/submission-utills';
import { useSendPromptReport } from 'broker/utils/use-send-prompt-report';
import { extractValidCoverageLinesSuggestion } from './extracted-coverage-lines-utils';
import { PreFormData } from './types';
import { useApiProductsByCoverageLines } from './useApiProductsByCoverageLines';
import { formFieldsConfig, formValidationShape } from './utils';

interface PreFormProps {
  submission: PartialSubmission;
  afterSubmissionUpdated: ({ oldCoverageLines, updatedCoverageLines }: CoverageLineChanges) => Promise<void>;
  firstVisit: boolean;
}

export default function PreForm({ submission, afterSubmissionUpdated, firstVisit }: PreFormProps) {
  const [isDirty, setIsDirty] = useState(false);
  const { submissionExtractedData } = submission;
  const { updateSubmission } = useMutateSubmission();
  const { reFetchWorkspace, updateSubmissionTasks } = useSubmissionsWorkspace();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { isAcknowledgmentFlow } = useIsAcknowledgmentFlow();
  const showProcessingBanner = submission.status === SubmissionStatus.Processing;
  const sendPromptReport = useSendPromptReport();

  const extractedCoverageLines = useMemo(() => {
    if (!submissionExtractedData?.extractedData?.coverageLines?.value) {
      return submissionExtractedData?.extractedData?.coverageLines;
    }

    const value = convertCoverageLinesTextToEnum(submissionExtractedData?.extractedData.coverageLines.value);

    if (value.length === 0) {
      return undefined;
    }

    return {
      ...submissionExtractedData?.extractedData?.coverageLines,
      value,
    };
  }, [submissionExtractedData?.extractedData?.coverageLines]);

  const preFormSuggestions: ExtractedDataFields | undefined = useMemo(() => {
    if (!extractedCoverageLines) {
      return undefined;
    }
    const coverageLinesSuggestionProps = extractValidCoverageLinesSuggestion(
      extractedCoverageLines,
      Object.values(CoverageLine),
    );
    if (!coverageLinesSuggestionProps) {
      return undefined;
    }
    return {
      [formFieldsConfig.coverageLines.name]: coverageLinesSuggestionProps,
    };
  }, [extractedCoverageLines]);

  const { methods, appliedSuggestions } = useFormProvider<PreFormData>({
    schema: formValidationShape(),
    setIsDirty,
    defaultValues: {
      coverageLines: submission ? submission.coverageLines : [],
    },
    suggestedValues: preFormSuggestions,
    applySuggestionsOnEmptyFields: firstVisit,
  });

  const appliedCoverageLinesSuggestions = useMemo(
    () =>
      Array.isArray(appliedSuggestions[formFieldsConfig.coverageLines.name]?.value)
        ? {
            value: (appliedSuggestions[formFieldsConfig.coverageLines.name].value as CoverageLine[]).map(
              (appliedCoverageLine) => coverageLineConfig[appliedCoverageLine as CoverageLine].text,
            ),
            reason: <AIReason suggestion={appliedSuggestions[formFieldsConfig.coverageLines.name]} />,
          }
        : undefined,
    [appliedSuggestions],
  );

  const coverageLines = methods.watch('coverageLines');
  const { apiProductsByCoverageLines } = useApiProductsByCoverageLines(coverageLines);

  const onSubmit = () => {
    methods.handleSubmit(async (data: PreFormData) => {
      setIsSubmitting(true);
      try {
        await updateSubmission.mutateAsync({
          id: submission!.id,
          data: {
            coverageLines: isEmpty(data.coverageLines) ? undefined : data.coverageLines,
            draftAckSelectedProducts: [],
          },
        });

        if (submission.tasks.ReviewSubmission?.status === SubmissionTaskStatus.ToDo) {
          await updateSubmissionTasks({
            submissionId: submission!.id,
            status: SubmissionTaskStatus.InProgress,
            task: SubmissionTask.ReviewSubmission,
          });
        }

        await reFetchWorkspace();

        // Force React to flush the dirty flag update before we navigate away
        flushSync(() => {
          setIsDirty(false);
        });

        await afterSubmissionUpdated({
          oldCoverageLines: submission.coverageLines,
          updatedCoverageLines: data.coverageLines,
        });
        methods.reset(undefined, { keepValues: true });
        if (preFormSuggestions) {
          await sendPromptReport({
            formState: data,
            suggestedValueProps: preFormSuggestions,
            entityId: submission.id,
            submissionId: submission.id,
          });
        }
        setIsSubmitting(false);
      } catch (e) {
        setIsSubmitting(false);
      }
    })();
  };

  const titleText = () => {
    if (isAcknowledgmentFlow) {
      return messages.acknowledgmentFlow.title;
    }
    return messages.editSubmission.coverageLines;
  };

  const beforeNavigation = async (transition: Transition) =>
    transition.location.pathname.includes(BROKER_NESTED_ROUTES.WORKSPACE.EDIT_SUBMISSION);

  return (
    <DirtyRouteGuard isDirty={isDirty} beforeNavigation={beforeNavigation}>
      <InnerPageLayout
        title={titleText()}
        className="edit-submission-pre-form"
        hideBackButton={isAcknowledgmentFlow}
        headerTopContent={
          showProcessingBanner
            ? () => (
                <Banner
                  title={messages.acknowledgmentFlow.processingBanner.title}
                  subtitle={messages.acknowledgmentFlow.processingBanner.subTitle}
                  mode={BannerMode.Warning}
                />
              )
            : undefined
        }
      >
        {() => (
          <FormProvider {...methods}>
            <Box gap={2} maxWidth={640} margin="auto">
              <Box mb={5}>
                <InfoBanner
                  sections={[
                    {
                      title: messages.acknowledgmentFlow.overviewBanner.title1,
                      content: messages.acknowledgmentFlow.overviewBanner.content1,
                    },
                    {
                      title: messages.acknowledgmentFlow.overviewBanner.title2,
                      content: messages.acknowledgmentFlow.overviewBanner.content2,
                    },
                  ]}
                />
              </Box>
              <FormMultiSelectAutocomplete
                dropdownAfterTyping
                hideOptionalLabel
                freeSolo
                defaultValue={[]}
                {...formFieldsConfig.coverageLines}
                options={Object.keys(coverageLineConfig).map((coverageLine) => ({
                  label: coverageLineConfig[coverageLine as CoverageLine].text,
                  value: coverageLine,
                }))}
                suggestion={appliedCoverageLinesSuggestions}
              />
              <Stack direction="row" gap={2} justifyContent="space-between" alignItems="center">
                <ApiProductsDropDown menuPosition="left" products={apiProductsByCoverageLines} />
                <Box minWidth={190}>
                  <Button fullWidth loading={isSubmitting} onClick={onSubmit} variant="contained">
                    {messages.buttons.start}
                  </Button>
                </Box>
              </Stack>
            </Box>
          </FormProvider>
        )}
      </InnerPageLayout>
    </DirtyRouteGuard>
  );
}
