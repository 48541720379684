import {
  BoxTemplateLabels,
  FormMode,
  QuoteFileIdType,
  SubmissionMarketRequestStatus,
  SubmissionMethod,
  SubmissionStatus,
} from 'enums';
import { useHotjar, useToast } from 'hooks';
import { messages } from 'i18n';
import { ExtendedMarketRequest, SharingDetails } from 'types';
import { HotjarEvents } from 'utils/hotjar-events';
import { activeTabUrlParamValues, BrokerUrlParams } from 'broker/broker-routes';
import { convertSubmissionMarketToRecipientGroup } from 'broker/components/Emails/recipient-utils';
import { EmailEditorPageMode, RouteKey } from 'broker/enums';
import { useNavigate, useNavigateToOverlay } from 'broker/hooks';
import { useModalActions } from 'broker/pages/SubmissionWorkspacePage/dialogs/modalProvider/modalProvider';
import { useStatusChangeDialog } from 'broker/pages/SubmissionWorkspacePage/dialogs/SubmissionStatusChangeDialog/useStatusChangeDialog';
import { useWorkspaceUrls } from 'broker/pages/SubmissionWorkspacePage/hooks';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { useUiStoreActions } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';
import {
  getSubmissionMarketStatusFromQuote,
  useSubmitMarketRequestsViaApi,
} from 'broker/pages/SubmissionWorkspacePage/utils';

export const useSubmissionMarketRequestActions = (
  marketRequest: ExtendedMarketRequest,
  setIsSubmittingMarketRequest?: (isSubmitting: boolean) => void,
) => {
  const navigate = useNavigate();
  const submitMarketRequestsViaApi = useSubmitMarketRequestsViaApi();
  const hotjar = useHotjar();
  const { showToast } = useToast();

  const {
    emailEditorUrl,
    quoteFormUrl,
    createQuoteFormUrl,
    flowQuoteUrl,
    binderUrl,
    policyUrl,
    createBinderUrl,
    createPolicyUrl,
  } = useWorkspaceUrls();

  const modalActions = useModalActions();
  const navigateToOverlay = useNavigateToOverlay();

  const {
    updateSubmissionMarketRequest,
    removeQuote: removeQuoteFromWorkspace,
    deleteSubmissionMarketRequest,
    updateQuote,
    submission,
  } = useSubmissionsWorkspace();
  const { setFilesExplorer } = useUiStoreActions();
  const { showStatusChangeDialog } = useStatusChangeDialog();

  const submitToMarketByEmail = () => {
    hotjar.event(HotjarEvents.SubmitToMarket);
    navigate(emailEditorUrl, {
      routeKey: RouteKey.EmailEditor,
      state: {
        mode: EmailEditorPageMode.SendRfq,
        recipientGroups: [convertSubmissionMarketToRecipientGroup(marketRequest.market, marketRequest.market.contacts)],
      },
    });
  };

  const changeToAwaitingQuote = async () => {
    hotjar.event(HotjarEvents.ChangeQuoteStatus);
    await updateSubmissionMarketRequest(marketRequest.id, {
      submissionSent: true,
      status: SubmissionMarketRequestStatus.AwaitingQuote,
    });
  };

  const changeToAwaitingBinder = async () => {
    hotjar.event(HotjarEvents.ChangeQuoteStatus);
    await updateSubmissionMarketRequest(marketRequest.id, {
      status: SubmissionMarketRequestStatus.AwaitingBinder,
    });
    if (submission) {
      showStatusChangeDialog({
        current: submission.status,
        newStatus: SubmissionStatus.Binding,
      });
    }
  };

  const changeToAwaitingPolicy = async () => {
    hotjar.event(HotjarEvents.ChangeQuoteStatus);
    await updateSubmissionMarketRequest(marketRequest.id, {
      status: SubmissionMarketRequestStatus.AwaitingPolicy,
    });
  };

  const changeToAIndicationQuote = async () => {
    hotjar.event(HotjarEvents.ChangeQuoteStatus);
    await updateSubmissionMarketRequest(marketRequest.id, {
      status: SubmissionMarketRequestStatus.Indication,
    });
    showToast('success', {
      message: messages.submissionWorkspace.marketRequestItem.statusActions.statusChangedToIndication,
    });
  };

  const changeToAwaitingQuoteViaPortal = async () => {
    hotjar.event(HotjarEvents.ChangeQuoteStatus);
    await updateSubmissionMarketRequest(marketRequest.id, {
      submissionSent: true,
      status: SubmissionMarketRequestStatus.AwaitingQuote,
      submissionMethod: SubmissionMethod.Portal,
    });
  };

  const openInformationRequested = () => {
    hotjar.event(HotjarEvents.InformationRequested);
    navigateToOverlay({
      routeKey: RouteKey.InformationRequest,
      state: { selectedSubmissionMarketRequest: marketRequest },
    });
  };
  const openDeclination = () => {
    hotjar.event(HotjarEvents.MarketDeclined);
    navigateToOverlay({
      routeKey: RouteKey.Declination,
      state: { selectedMarket: marketRequest.market, marketRequestId: marketRequest.id },
    });
  };

  const attachQuote = () => {
    hotjar.event(HotjarEvents.AttachQuote);
    navigate(createQuoteFormUrl, {
      routeKey: RouteKey.Quote,
      state: {
        selectedMarket: marketRequest.market,
        mode: FormMode.create,
        insuranceProductId: marketRequest.insuranceProduct?.id,
        submissionMarketRequestId: marketRequest.id,
      },
    });
  };

  const editQuote = () => {
    hotjar.event(HotjarEvents.EditQuote);
    navigate(quoteFormUrl, {
      routeKey: RouteKey.Quote,
      state: {
        selectedQuote: marketRequest.quote,
        mode: FormMode.edit,
        submissionMarketRequestId: marketRequest.id,
      },
    });
  };

  const viewQuote = () => {
    hotjar.event(HotjarEvents.ViewQuote);
    navigate(flowQuoteUrl, {
      routeKey: RouteKey.Quote,
      state: {
        selectedQuote: marketRequest.quote,
        mode: FormMode.view,
        submissionMarketRequestId: marketRequest.id,
      },
    });
  };

  const editBinder = () => {
    hotjar.event(HotjarEvents.EditBinder);
    navigate(binderUrl, {
      routeKey: RouteKey.Quote,
      state: {
        selectedMarket: marketRequest.market,
        selectedQuote: marketRequest.quote,
        quoteFileIds: marketRequest.quote?.binderFileIds,
        fileType: QuoteFileIdType.Binder,
        mode: FormMode.edit,
        insuranceProductId: marketRequest.insuranceProduct?.id,
      },
    });
  };

  const viewBinder = () => {
    hotjar.event(HotjarEvents.ViewBinder);
    navigate(binderUrl, {
      routeKey: RouteKey.Quote,
      state: {
        selectedMarket: marketRequest.market,
        selectedQuote: marketRequest.quote,
        quoteFileIds: marketRequest.quote?.binderFileIds,
        fileType: QuoteFileIdType.Binder,
        mode: FormMode.view,
        insuranceProductId: marketRequest.insuranceProduct?.id,
      },
    });
  };

  const attachStatus = (fileType: QuoteFileIdType) => () => {
    navigate(fileType === QuoteFileIdType.Binder ? createBinderUrl : createPolicyUrl, {
      routeKey: RouteKey.Quote,
      state: {
        fileType,
        mode: FormMode.create,
        selectedMarket: marketRequest.market,
        selectedQuote: marketRequest.quote,
        insuranceProductId: marketRequest.insuranceProduct?.id,
      },
    });

    if (fileType === QuoteFileIdType.Binder) {
      hotjar.event(HotjarEvents.AddBinder);
    }
    if (fileType === QuoteFileIdType.Policy) {
      hotjar.event(HotjarEvents.AddPolicy);
    }
  };

  const rejectQuote = async () => {
    hotjar.event(HotjarEvents.RejectQuote);
    modalActions?.openQuoteNotAcceptedDialog({
      marketActiveQuotes: [marketRequest.quote!],
      quoteType: QuoteFileIdType.MarketQuote,
      disableBackdrop: false,
    });
  };

  const submitProductViaApi = async () => {
    if (submission) {
      try {
        setIsSubmittingMarketRequest?.(true);
        await submitMarketRequestsViaApi(submission, [marketRequest]);
        showToast('success', { message: messages.heraldApi.rfqSentViaApi });
        setIsSubmittingMarketRequest?.(false);
      } catch (error) {
        setIsSubmittingMarketRequest?.(false);
        throw error;
      }
    }
  };

  const submitToMarketByPortal = () => {
    hotjar.event(HotjarEvents.SubmitProductViaPortal);
    window.open(marketRequest.insuranceProduct?.submissionPortal, '_blank');
  };

  const removeDeclination = async () => {
    hotjar.event(HotjarEvents.RemoveDeclination);
    await updateSubmissionMarketRequest(marketRequest.id, {
      declination: { declinationReasons: [], isAIExtracted: false },
      status: marketRequest.quote
        ? getSubmissionMarketStatusFromQuote(marketRequest.quote)
        : SubmissionMarketRequestStatus.AwaitingQuote,
    });
  };

  const removeQuote = async () => {
    hotjar.event(HotjarEvents.RemoveQuote);
    if (marketRequest.quoteId) {
      await removeQuoteFromWorkspace(marketRequest.quoteId);
    }
  };

  const removeBinder = async () => {
    hotjar.event(HotjarEvents.RemoveBinder);
    if (marketRequest.quoteId) {
      await updateQuote(marketRequest.quoteId, { binderFileIds: [] });
    }
  };

  const removePolicy = async () => {
    hotjar.event(HotjarEvents.RemoveBinder);
    if (marketRequest.quoteId) {
      // @ts-ignore
      await updateQuote(marketRequest.quoteId, { policyFileIds: [] });
    }
  };

  const editPolicy = () => {
    hotjar.event(HotjarEvents.EditPolicy);
    navigate(policyUrl, {
      routeKey: RouteKey.Quote,
      state: {
        selectedMarket: marketRequest.market,
        selectedQuote: marketRequest.quote,
        quoteFileIds: marketRequest.quote?.policyFileIds,
        fileType: QuoteFileIdType.Policy,
        mode: FormMode.edit,
        insuranceProductId: marketRequest.insuranceProduct?.id,
      },
    });
  };

  const viewPolicy = () => {
    hotjar.event(HotjarEvents.ViewPolicy);
    navigate(policyUrl, {
      routeKey: RouteKey.Quote,
      state: {
        selectedMarket: marketRequest.market,
        selectedQuote: marketRequest.quote,
        quoteFileIds: marketRequest.quote?.policyFileIds,
        fileType: QuoteFileIdType.Policy,
        mode: FormMode.view,
        insuranceProductId: marketRequest.insuranceProduct?.id,
      },
    });
  };

  const removeInformationRequested = async () => {
    hotjar.event(HotjarEvents.RemoveInformationRequested);
    await updateSubmissionMarketRequest(marketRequest.id, {
      informationRequested: [],
      status: marketRequest.quote
        ? getSubmissionMarketStatusFromQuote(marketRequest.quote)
        : SubmissionMarketRequestStatus.AwaitingQuote,
    });
  };

  const composeAdditionalInformationToMarketEmail = () => {
    hotjar.event(HotjarEvents.SendAdditionalInformationToMarket);
    navigate(emailEditorUrl, {
      routeKey: RouteKey.EmailEditor,
      state: {
        mode: EmailEditorPageMode.SendMarket,
        recipientGroups: [
          convertSubmissionMarketToRecipientGroup(
            marketRequest.market,
            marketRequest.insuranceProduct?.contacts ?? marketRequest.market.contacts,
          ),
        ],
        updateSubmissionMarketRequestStatus: {
          submissionMarketRequestId: marketRequest.id,
          targetStatus: marketRequest.quote
            ? getSubmissionMarketStatusFromQuote(marketRequest.quote)
            : SubmissionMarketRequestStatus.AwaitingQuote,
        },
      },
    });
  };

  const markAsShared = async (sharedKey: keyof SharingDetails) => {
    if (marketRequest.quote) {
      const { sharingDetails } = marketRequest.quote;
      await updateQuote(marketRequest.quote.id, {
        sharingDetails: { ...sharingDetails, [sharedKey]: true },
      });
    }
  };

  const navigateToEmailEditorAndMarkShared = async (
    mode: EmailEditorPageMode,
    sharedKey: keyof SharingDetails,
    activeTab?: activeTabUrlParamValues,
  ) => {
    const toolboxQueryPath = activeTab ? `?${BrokerUrlParams.ACTIVE_TAB}=${activeTab}` : undefined;
    navigate(
      { pathname: emailEditorUrl, search: toolboxQueryPath },
      {
        routeKey: RouteKey.EmailEditor,
        state: {
          mode,
          triggeringQuoteId: marketRequest.quoteId,
        },
        replace: true,
      },
    );
    await markAsShared(sharedKey);
  };

  const shareQuote = async () => {
    setFilesExplorer({
      filter: {
        [BoxTemplateLabels.MarketId]: [
          {
            value: marketRequest.market.id,
            label: marketRequest.market.marketName,
          },
        ],
      },
    });
    await navigateToEmailEditorAndMarkShared(
      EmailEditorPageMode.NewQuote,
      'quoteShared',
      activeTabUrlParamValues.FILES,
    );
  };

  const shareBinder = async () => {
    await navigateToEmailEditorAndMarkShared(EmailEditorPageMode.LayerBinder, 'binderShared');
  };

  const sharePolicy = async () => {
    await navigateToEmailEditorAndMarkShared(EmailEditorPageMode.LayerPolicy, 'policyShared');
  };

  const markQuoteAsShared = async () => {
    await markAsShared('quoteShared');
  };

  const markBinderAsShared = async () => {
    await markAsShared('binderShared');
  };

  const markPolicyAsShared = async () => {
    await markAsShared('policyShared');
  };

  return {
    submitToMarketByEmail,
    changeToAwaitingQuote,
    changeToAwaitingQuoteViaPortal,
    openInformationRequested,
    openDeclination,
    attachQuote,
    editQuote,
    editBinder,
    editPolicy,
    viewQuote,
    viewBinder,
    viewPolicy,
    attachStatus,
    rejectQuote,
    submitToMarketByPortal,
    submitProductViaApi,
    removeDeclination,
    removeQuote,
    removeBinder,
    removePolicy,
    deleteSubmissionMarketRequest,
    changeToAwaitingBinder,
    removeInformationRequested,
    composeAdditionalInformationToMarketEmail,
    shareQuote,
    shareBinder,
    sharePolicy,
    markQuoteAsShared,
    markBinderAsShared,
    markPolicyAsShared,
    changeToAIndicationQuote,
    changeToAwaitingPolicy,
  };
};
