// a list of parameter_ids that should be excluded from the form
import {
  FormStateBaseProps,
  HeraldFormState,
  HeraldNormalizedParameter,
} from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/DynamicForm/types';
import { normalizeValueToHeraldBySchema } from './mapping-utils';

export const formValidationErrorExceptionMessage = 'Form validation error';

export function getNormalizeHeraldFormStateValues(
  heraldFormState: HeraldFormState,
  heraldNormalizedParameters: HeraldNormalizedParameter[],
): HeraldFormState {
  const normalizedHeraldFormState: HeraldFormState = {};

  // Overloaded signatures
  function getNormalizedValueFromFormStateBaseProps(
    formStateBaseProps: FormStateBaseProps,
    normalizedParameter: HeraldNormalizedParameter,
  ): FormStateBaseProps;

  function getNormalizedValueFromFormStateBaseProps(
    formStateBaseProps: FormStateBaseProps[],
    normalizedParameter: HeraldNormalizedParameter,
  ): FormStateBaseProps[];

  // Implementation
  function getNormalizedValueFromFormStateBaseProps(
    formStateBaseProps: FormStateBaseProps | FormStateBaseProps[],
    normalizedParameter: HeraldNormalizedParameter,
  ): FormStateBaseProps | FormStateBaseProps[] {
    if (Array.isArray(formStateBaseProps)) {
      return formStateBaseProps.map((formStateItem) =>
        getNormalizedValueFromFormStateBaseProps(formStateItem, normalizedParameter),
      );
    }

    return {
      ...formStateBaseProps,
      main: normalizeValueToHeraldBySchema(formStateBaseProps.main, normalizedParameter.schema),
      children: formStateBaseProps.children
        ? Object.keys(formStateBaseProps.children).reduce((acc, childParameterId) => {
            const val = formStateBaseProps.children![childParameterId];
            const childNormalizedParameter = normalizedParameter?.arrayElements
              ?.flatMap((arrayElement) =>
                arrayElement.childValues?.find((childValue) => childValue.parameter_id === childParameterId),
              )
              .find(Boolean);

            // Ensure childNormalizedParameter is not undefined before calling the function
            if (childNormalizedParameter) {
              // Check if 'val' is an array or a single object
              if (Array.isArray(val)) {
                acc[childParameterId] = getNormalizedValueFromFormStateBaseProps(val, childNormalizedParameter);
              } else if (val) {
                acc[childParameterId] = getNormalizedValueFromFormStateBaseProps(val, childNormalizedParameter);
              } else {
                acc[childParameterId] = undefined;
              }
            } else {
              acc[childParameterId] = undefined;
            }

            return acc;
          }, {} as Record<string, FormStateBaseProps | FormStateBaseProps[] | undefined>)
        : undefined,
    };
  }

  // Normalize the form state
  Object.keys(heraldFormState).forEach((heraldParameterId) => {
    const normalizedParameter = heraldNormalizedParameters.find((param) => param.parameter_id === heraldParameterId);

    if (normalizedParameter) {
      const formValue = heraldFormState[heraldParameterId];

      if (formValue) {
        // Use type guard to call the correct overload
        if (Array.isArray(formValue)) {
          normalizedHeraldFormState[heraldParameterId] = getNormalizedValueFromFormStateBaseProps(
            formValue,
            normalizedParameter,
          );
        } else {
          normalizedHeraldFormState[heraldParameterId] = getNormalizedValueFromFormStateBaseProps(
            formValue,
            normalizedParameter,
          );
        }
      }
    }
  });

  return normalizedHeraldFormState;
}
