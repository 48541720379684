import { findLast } from 'lodash';
import { Backdrop, Box, MarkedText, Tooltip, Typography } from '@common-components';
import { SubmissionEmailLabel } from 'enums';
import { useBoolean } from 'hooks';

import { messages } from 'i18n';
import { ExtendedMarketRequest } from 'types';
import { ConditionalWrap } from 'utils';
import { DirtyActionsGuard } from 'broker/components/DirtyContentGuard/DirtyActionsGuard';
import useGetSubmissionRelatedEmails from 'broker/pages/SubmissionWorkspacePage/components/Toolbox/components/Email/useGetSubmissionRelatedEmails';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import IndicationForm from './IndicationForm';

interface IndicationProps {
  marketRequest: ExtendedMarketRequest;
}

export default function Indication({ marketRequest }: IndicationProps) {
  const [isOpen, { off: close, on: open }] = useBoolean(false);
  const indicationContent = marketRequest.indication?.value || messages.general.noIndicationReason;
  const { partialSubmission } = useSubmissionsWorkspace();

  const { submissionEmailsList } = useGetSubmissionRelatedEmails({ submissionId: partialSubmission?.id });
  const emailWithIndication = findLast(
    submissionEmailsList,
    (email) => email.submissionMarketRequestId === marketRequest.id && email.label === SubmissionEmailLabel.Indication,
  );

  return (
    <DirtyActionsGuard>
      {({ closeAttemptWithAction, closeWithoutPromptWithAction, isContentDirty, setIsContentDirty }) => (
        <Tooltip
          open={isOpen}
          tooltipContent={
            <IndicationForm
              indicationEmail={emailWithIndication}
              isContentDirty={isContentDirty}
              setIsContentDirty={setIsContentDirty}
              closeWithoutPrompt={() => closeWithoutPromptWithAction(close)}
              id={marketRequest.id}
              indicationText={marketRequest.indication?.value}
            />
          }
        >
          <Box>
            <Typography
              onClick={open}
              sx={{
                '&: hover': {
                  textDecoration: 'underline',
                },
              }}
              variant="body2"
              color="text.primary"
            >
              <ConditionalWrap
                condition={!!marketRequest.indication?.isAIExtracted}
                wrap={(wrappedChildren) => <MarkedText>{wrappedChildren}</MarkedText>}
              >
                {indicationContent}
              </ConditionalWrap>
            </Typography>
            <Backdrop
              invisible
              open={isOpen}
              sx={{ zIndex: (theme) => theme.zIndex.tooltip - 1 }}
              onClick={() => closeAttemptWithAction(close)}
            />
          </Box>
        </Tooltip>
      )}
    </DirtyActionsGuard>
  );
}
