import { PartialSubmission } from 'types';
import { SubmissionFormFieldsNames } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/EditSubmissionNew/Screens/SubmissionStaticForm/StaticForm/types';

export const submissionFormFieldsToEntityPropMapper: Record<SubmissionFormFieldsNames, keyof PartialSubmission> = {
  [SubmissionFormFieldsNames.InsuredName]: 'insuredName',
  [SubmissionFormFieldsNames.Industry]: 'industry',
  [SubmissionFormFieldsNames.InsuredPrimaryAddress]: 'insuredPrimaryAddress',
  [SubmissionFormFieldsNames.InsuredMailingAddress]: 'insuredMailingAddress',
  [SubmissionFormFieldsNames.Limit]: 'limit',
  [SubmissionFormFieldsNames.DueDate]: 'dueDate',
  [SubmissionFormFieldsNames.NeedByDate]: 'needByDate',
  [SubmissionFormFieldsNames.InsuredAnnualRevenue]: 'insuredAnnualRevenue',
  [SubmissionFormFieldsNames.InsuredEmployeeCount]: 'insuredEmployeeCount',
  [SubmissionFormFieldsNames.InsuredWebsite]: 'insuredWebsite',
  [SubmissionFormFieldsNames.InsuredCompanyType]: 'insuredCompanyType',
};
