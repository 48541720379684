import { User } from '@common/types';
import moment from 'moment';
import pluralize from 'pluralize';
import {
  AIFeature,
  QuoteSource,
  ShareMenuAction,
  SubmissionMarketRequestStatus,
  SubmissionMethod,
  SubmissionStatus,
  SubmissionTask,
  SubmissionTaskStatus,
  SubmissionTerminationStatus,
  UserRole,
} from 'enums';
import { formatBigNumber } from 'utils/money-utils';

export const en = {
  tabs: {
    marketing: {
      title: 'Marketing',

      tooltip: {
        title: 'Good Job! 👏',
        message: 'Looks like you’re ready to start\n marketing your program',
        buttonText: 'Start Marketing',
      },
      statuses: {
        [SubmissionMarketRequestStatus.ReadyToMarket]: 'Ready to Market',
        [SubmissionMarketRequestStatus.AwaitingQuote]: 'Awaiting Quote',
        [SubmissionMarketRequestStatus.QuoteReady]: 'Quote Ready',
        [SubmissionMarketRequestStatus.AwaitingBinder]: 'Awaiting Binder',
        [SubmissionMarketRequestStatus.Bound]: 'Bound',
        [SubmissionMarketRequestStatus.AwaitingPolicy]: 'Awaiting Policy',
        [SubmissionMarketRequestStatus.PolicyIssued]: 'Policy Issued',
        [SubmissionMarketRequestStatus.Declined]: 'Declined',
        [SubmissionMarketRequestStatus.QuoteNotAccepted]: 'Quote Not Accepted',
        [SubmissionMarketRequestStatus.PendingCustomer]: 'Pending Customer',
        [SubmissionMarketRequestStatus.Indication]: 'Indication',
      },
    },
    preMarketing: {
      title: 'Pre-Marketing',
    },
    proposal: {
      filesStep: {
        header: 'Proposal Files',
        proceedButton: 'Next: Compose Proposal',
        bucketTitlePrefix: 'Select from toolbox to populate',
        additionalAttachments: 'Additional Attachments',
        buckets: {
          flowQuote: {
            title: 'Flow Quotes',
            actionTitle: 'Create Flow Quote',
          },
          quoteComparison: {
            title: 'Quote Comparison',
            actionTitle: 'Create Quote Comparison',
          },
          marketingReport: {
            title: 'Marketing Report',
            actionTitle: 'Create Marketing Report',
          },
        },
      },
      emailStep: {
        header: 'Proposal Email',
        submitButton: 'Send to Client',
        successMessage: 'Proposal was sent',
        aiPopupTitle: 'FlowAI Proposal Insights',
      },
      emptyState: {
        header: 'Create a Proposal',
        title:
          'Start by selecting Flow-Quotes, create a quote comparison, update the marketing summary, and finalize with a\n' +
          '        proposal email.',
        createProposal: 'Create Proposal',
      },
      title: 'Proposal',
      tooltip: {
        title: 'All Set!',
        message: 'Time to send a proposal to your customer and approve the program',
        buttonText: 'Write a Proposal',
      },
    },
    files: { title: 'Files' },
    binderPolicy: {
      title: 'Binders & Policies',
      tooltip: {
        title: 'Perfect!',
        message: 'Now all you’ve got left to do is to \n obtain the binder and policy',
        buttonText: 'Obtain Binders & Policies',
      },
    },
    sendNotificationEmail: 'Send email notification',
    sendEscalationPolicy: 'Send Co-Pilot escalation',
  },
  header: {
    details: {
      effectiveDate: (date: string) => `Effective Date: ${date}`,
      needByDate: (date: string) => `Need By: ${date}`,
    },
  },
  downloadFilesAndEmails: {
    allEmailsAndFiles: 'All Emails And Files',
    customerEmailsOutgoing: 'Customer Emails Outgoing',
    marketingEmailsOutgoing: 'Marketing Emails Outgoing',
    incomingEmails: 'Incoming Emails',
    downloading: 'Downloading',
    noFilesToDownload: 'No files to download',
    marketingEmailsFolder: 'Marketing emails',
    customerEmailsFolder: 'Customer emails',
    menuLabel: 'Download Emails and Files',
  },
  termination: {
    archiveMenuLabel: 'Archive',
    closeMenuLabel: 'Close',
    reasons: {
      couldNotPursue: 'Could not pursue this submission',
      couldNotMatch: 'Could not match markets',
      unacceptedPricing: 'Unaccepted by agent/insured - Pricing',
      unacceptedCoverage: 'Unaccepted by agent/insured - Coverage',
      unacceptedService: 'Unaccepted by agent/insured - Service',
      unacceptedGhosted: 'Unaccepted by agent/insured - Ghosted',
      agentPulledBack: 'Agent pulled back on Submission',
      agentLostAccount: 'Agent lost/didn’t win the account',
      addedByMistake: 'Added by mistake',
      addedForTesting: 'Added for testing',
      other: 'Other, please specify',
    },
    terminationDetails: (
      status: SubmissionTerminationStatus,
      reason: string,
      terminatedAt: string,
      terminatedBy?: User,
    ) => {
      if (!terminatedBy) {
        return `This submission was ${status === SubmissionStatus.Archived ? 'archived' : 'closed'}. Reason: ${reason}`;
      }

      return `${status === SubmissionStatus.Archived ? 'Archived' : 'Closed'} By ${terminatedBy.firstName} ${
        terminatedBy.lastName
      } on ${terminatedAt}. Reason: ${reason}`;
    },
    confirmationDialog: {
      title: (targetStatus: SubmissionTerminationStatus) =>
        `${targetStatus === SubmissionStatus.Closed ? 'Close' : 'Archive'} submission`,
      message: (targetStatus: SubmissionTerminationStatus) =>
        `You are about to ${
          targetStatus === SubmissionStatus.Closed ? 'close' : 'archive'
        } this submission. Please select the reason:`,
      details: 'More details if necessary...',
      confirmLabel: (targetStatus: SubmissionTerminationStatus) =>
        targetStatus === SubmissionStatus.Closed ? 'Close' : 'Archive',
    },
    successMessage: (status: SubmissionTerminationStatus) =>
      `Submission ${status === SubmissionStatus.Archived ? 'archived' : 'closed'} successfully`,
    banner: {
      title: (status: SubmissionTerminationStatus) =>
        `${status === SubmissionStatus.Archived ? 'Archived' : 'Closed'} submission`,
    },
  },
  missingApiInformationBanner: {
    title: 'Missing Required Information',
    description: 'Complete all required submission information to enable API product submissions.',
    button: 'Complete Submission Info',
  },
  missingInformationBanner: {
    title: {
      error: 'Missing required information and files',
      warning: 'Missing information and files',
    },
    description: 'Complete all required information and files before submitting to markets',
    button: 'Request Information',
  },
  toolbox: {
    openToolbox: 'Open Toolbox',
    closeToolbox: 'Close Toolbox',
    tabs: {
      tasks: 'Tasks',
      files: 'Files',
      tower: 'Tower',
      emails: 'Emails',
      contacts: 'Contacts',
      info: 'Info',
      marketActivityLog: 'Market Activity Log',
      submissionActivity: 'Submission Activity Log',
    },
  },
  customerSection: {
    messageCustomer: 'Message Customer',
    conceptualTower: { label: 'Conceptual Tower', description: 'A representation of your potential tower' },
    marketingReport: {
      label: 'Marketing Report',
      description: 'A summary of all the markets approached and their responses',
    },
    yoyReport: {
      label: 'Year Over Year Comparison Report',
      description: 'A Year over Year comparison of your program',
    },
    quote: {
      label: 'Quote',
      description: 'A representation of your quote',
    },
    expiringTower: { label: 'Expiring Tower', description: 'A representation of your expiring program tower' },
    informationRequests: {
      label: 'Information requests',
      description: 'A summary of all the markets with information requests',
    },
    composeMarketingReport: 'Compose Marketing Report',
    composeConceptualTower: 'Compose Conceptual Tower',
    composeEmail: 'Compose Email',
    chooseAction: 'Choose Action',
    coverLetter: 'Cover Letter',
    backToProposal: 'Back To Proposal',
    sendProposalDisabledTooltip: 'Please fill in all required fields',
    smartReportsToolbox: {
      infoTooltip: {
        title: 'Smart Reports',
        message: 'Select smart reports to add to the proposal.',
      },
    },
    submissionActivityLog: 'Submission Activity',
    submissionMarketActivityLog: 'Submission Market Activity',
    contact: (email: string) => `Contact: ${email}`,
    proposalEditor: 'Proposal Editor',
    subject: 'Subject',
    to: 'To',
    cc: 'Cc',
    smartReports: 'Smart Reports',
    preview: 'Preview',
    edit: 'Edit',
    save: 'Save',
    sendProposal: 'Send to Customer',
    proposalSaved: 'Proposal saved successfully',
    proposalSent: 'Proposal sent to customer',
    lastSaved: (date?: Date) => `Last saved: ${date ? moment(date).utc().fromNow() : 'Never'}`,
    neverSaved: 'Never',
    smartObjectDropdown: {
      successPasteToast: 'Report was copied',
      selectorText: 'Smart Reports',
      usedInEmail: 'Used in this email',
      notAddedToEmail: 'Not added to this email',
      edit: 'Edit',
      copy: 'Copy',
    },
    pasteTooltip: {
      title: 'Paste to add the report',
      content: 'Right-click where you want to place the smart report.',
    },
  },
  bulkActionsFooter: {
    getSelectedText: (number: number) => `${number} Selected`,
    getSelectedTextWithPreSelectedFileName: (number: number, preSelectedFileName: string) =>
      `Selected: ${preSelectedFileName} ${number > 0 ? ` and ${number} ${pluralize('quote', number)}` : ''}`,
  },
  menus: {
    sort: {
      status: 'By Status',
      updated: 'Last Updated',
      asc: 'A - Z',
      desc: 'Z - A',
    },
    towerHeader: {
      sendConceptualTower: {
        // title: 'Send Conceptual Tower',
        send: 'Share Conceptual Tower',
      },
      sendBindRequest: {
        title: 'Send Bind Request',
      },
    },
    shareReport: {
      shareButton: 'Share Report',
      options: {
        copy: 'Copy To Clipboard',
        download: 'Download as PDF',
        email: 'Email To Customer',
      },
      confirmations: {
        [ShareMenuAction.Copy]: 'Copied to clipboard',
        [ShareMenuAction.Download]: 'Downloading...',
        [ShareMenuAction.Email]: 'Email sent',
      },
      downloadYesterdayActivity: "Download Yesterday's Activities",
    },
    share: {
      marketingReport: 'Share Marketing Report',
      ExpiringProgramTower: 'Expiring Program Tower Report',
      informationRequests: 'Share Information Requests Report',
      yoyReport: 'Share Year Over Year Report',
      quoteComparison: 'Quote Comparison',
      compareToExpiringPolicy: 'Compare Quotes to Expiring Policy',
      noExpiringPolicyFiles: 'Label an expiring policy file to compare',
      marketingUpdate: 'Share Marketing Update',
      marketingUpdateSubLabel: (date: string) => `Share by: ${date}`,
    },
    submit: {
      submitViaEmail: 'Submit via Email',
      submitViaApi: 'Submit via API',
      apiNotReady: `Complete all required fields to enable\n API submissions for selected products.`,
    },
    marketsHeader: {
      sendRFQ: {
        title: 'Submit to Markets',
      },
      addMarketManually: {
        title: 'Add Market Manually',
      },
      sendMarketingReport: {
        title: 'Send Marketing Report',
      },
      sendMessage: {
        title: 'Send Message',
      },
      sendProposal: {
        title: 'Send Proposal',
      },
    },
    market: {
      submitToMarket: {
        title: 'Submit to Market',
      },
      attachQuote: {
        title: 'Attach Quote',
      },
      attachAdditionalQuote: {
        title: 'Attach Additional Quote',
      },
      informationRequested: {
        title: 'Information Requested',
      },
      declined: {
        title: 'Declined',
      },
      marketDetails: {
        title: 'Market Details',
      },
      viewAndUpdateQuote: {
        title: 'View & Update Quote',
      },
      removeQuote: {
        title: 'Remove Quote',
      },
      quoteNotAccepted: {
        title: 'Quote Not Accepted',
      },
      attachBinder: {
        title: 'Attach Binder',
      },
      attachPolicy: {
        title: 'Attach Policy',
      },
      viewAndUpdateBinder: {
        title: 'View & Update Binder',
      },
      removeBinder: {
        title: 'Remove Binder',
      },
      viewAndUpdatedPolicy: {
        title: 'View & Update Policy',
      },
      removePolicy: {
        title: 'Remove Policy',
      },
      addSubmissionNotes: {
        title: 'Add Submission Notes',
      },
      internalNote: {
        addSubmissionNotes: 'Add Internal Submission Notes',
        openNote: 'Edit Note',
        cancel: 'Cancel',
        save: 'Save',
        saving: 'Saving',
      },
    },
    submissionHeader: {
      cancelSubmission: {
        title: 'Cancel Submission',
      },
    },
    quoteSidePanel: {
      viewAndUpdateQuote: {
        title: 'View & update quote',
      },
      chooseQuote: {
        title: 'Remove quote',
      },
    },
    layer: {
      mainActions: {
        marketLayer: {
          title: 'Marketing',
        },
        attachExcessQuote: {
          title: 'Excess Quote',
        },
        attachAdditionalQuote: {
          title: 'Quote',
        },
        attachPrimaryQuote: {
          title: 'Primary Quote',
        },
        compareQuotes: {
          title: 'Compare',
        },
        attachBinder: {
          title: 'Binder',
        },
        attachPolicy: {
          title: 'Policy',
        },
      },
    },
  },
  tower: {
    targetLimitReached: 'Program Limit Reached',
    addAttachmentPoint: '+ Attachment Point',
    attachmentPointAlreadyExists: 'Attachment point already exists',
    attachmentPoint: 'Attachment Point',
  },
  dialogs: {
    market: {
      title: 'Market information',
    },
    removeExcessLayer: {
      headerTitle: 'Remove attachment point',
      disabled: {
        text: 'The layer cannot be removed if there are existing quotes or has overlaying attachment points',
      },
      enabled: {
        title: (attachmentPoint: number) =>
          `Are you sure you want to remove the $${formatBigNumber(attachmentPoint)} Attachment Point?`,
        button: 'Remove Attachment point',
      },
    },
    deleteContact: {
      title: 'Delete Contact',
      body: 'Are you sure you want to delete this contact?',
      button: 'Remove Contact',
    },
    removeAll: {
      header: (name: string) => `Remove all ${name}`,
      title: (name: string) => `You are about to remove all ${name}`,
      description: 'Are you sure?',
    },
    emailEditor: {
      sendMessageToMarkets: 'Email to Markets',
      sendMessage: 'Send Messages',
      missingInformationRequest: {
        title: 'Request missing information',
        subtitle: 'Send an email to the customer to request missing information and documents',
      },
      submitToMarkets: {
        title: 'Submit to Markets',
        subtitle: 'Send a submission email to markets and start quoting',
      },
      sendMessageToCustomers: 'Email to Customers',
      canNotPursue: 'Can Not Pursue',
      positiveExpectation: 'Positive Expectation',
    },
    addQuote: {
      uploadQuoteTitle: (isPrimary = false) => `Upload ${isPrimary ? 'Primary' : ''} Quote`,
      pickQuoteTitle: (isPrimary = false) => `Select ${isPrimary ? 'Primary' : ''} Quote File`,
      quoteDetails: {
        quote: 'Quote Details',
        bound: 'Binder Details',
        issued: 'Policy Issued Details',
      },
    },
    chooseQuote: {
      title: (fileType: string) => `Choose ${fileType}`,
      subtitle: (type: string) => `Choose ${type}`,
      proceedText: (quoteType: string) => `Choose ${quoteType}`,
    },
    removeQuote: {
      title: (fileType: string) => `Remove ${fileType}`,
      subtitle: (type: string) => `Choose ${type} to remove`,
      singleSubtitle: (quoteType: string, marketName: string) =>
        `Are you sure you want to remove ${marketName}'s ${quoteType}?`,
      proceedText: (quoteType: string) => `Remove ${quoteType}`,
    },
    quoteNotAccepted: {
      title: `Reject Quote`,
      subtitle: `Choose quote to reject`,
      singleSubtitle: (marketName: string) => `Are you sure you want to reject ${marketName}'s quote?`,
      proceedText: `Reject Quote`,
      successMessage: (marketName: string) => `${marketName}'s quote has been successfully rejected.`,
    },
    marketDetails: {
      tabs: {
        quotesAndPolicies: 'Quotes & Policies',
        contacts: 'Contacts',
        files: 'Files',
        about: 'About',
      },
      riskAppetite: 'Risk appetite',
      memo: 'Memo',
      categories: 'Categories',
      newContact: 'New contact',
      contactDescription: (name: string) =>
        `The selected contacts will be chosen as the default contacts whenever a message is sent to ${name} in this submission.`,
      noQuotes: 'No quote was added to',
      title: 'Market Details',
      noFiles: 'No Files',
      atLeastOneContact: 'At least one contact is needed per submission',
    },
    quoteStatusRequest: {
      bind: {
        headerTitle: 'Send Bind Request',
        disabled: {
          title: 'Cannot Send Bind Request',
          description:
            'A Bind Request can only be sent to one market per layer. Please make sure you have selected a market for each layer.',
        },
        enabled: {
          title: (layersCount: number) =>
            `Are you sure you want to send a bind order to all selected markets (${layersCount})?`,
        },
      },
      policy: {
        headerTitle: 'Send Policy Request',
        disabled: {
          title: 'Cannot Send Policy Request',
          description:
            'A Policy Request can only be sent to one market per layer. Please make sure you have selected a market for each layer.',
        },
        enabled: {
          title: (layersCount: number) =>
            `Are you sure you want to send a policy request to all selected markets (${layersCount})?`,
        },
      },
    },
    arfRequest: {
      headerTitle: 'Sending Files',
      body: 'Your Binders will be processed and converted, Once completed, they will be sent directly to your inbox.',
      confirmText: 'Yes, Send Binders',
      success: 'Processing binders to be sent to accounting',
    },
    arfSent: {
      headerTitle: 'Binders sent to accounting',
      body: 'Will add an indication within the submission activity log that binders have been sent to accounting',
      confirmText: 'Confirm',
      success: "The activity log 'Binders sent to accounting' was added to the submission",
    },
  },
  emptyState: {
    title: 'No Markets Yet',
    subTitleBeforeLink: 'In order to add incumbent markets, fill in ',
    expiringProgramLink: "'Expiring program'",
    subTitleAfterLink: ' info.',
  },
  excessTower: 'Program Tower',
  excess: 'Excess',
  markets: 'Markets',
  recommendedProducts: 'FlowAI Market Recommendation 🪄',
  generatingProducts: 'Generating Market Recommendations...',
  recommendationsReady: 'Market Recommendations Ready',
  preMarketing: {
    comingSoon: 'Coming Soon',
    title: 'Pre-Marketing',
    filesTitle: 'Files',
    viewFiles: 'View Files',
    uploadMore: 'Upload More',
    RequestMissingDocuments: 'Request Missing Documents',
    coverLetterForm: {
      successMessage: 'Cover Letter successfully updated',
      coverLetter: 'Cover Letter',
      copilotIconBadgeTooltip: 'Capitola Co-Pilot will generate a Cover Letter.',
    },

    tasks: {
      [SubmissionTask.ReviewSubmission]: {
        title: 'Review Submission Details',
        description:
          'Kick off the marketing journey by ensuring accuracy. Validate details now for the correct market-ready information.',
        missingAlert: {
          title: 'Required Information',
          subtitle: 'Please fill in all required fields',
          requiredFields: 'Required information',
          requiredFieldsSubtitle: 'Need full info to move forward to marketing',
          optionalFields: 'Optional information',
        },
        extraContent: 'Complete all required information to enable API submissions.',
      },
      [SubmissionTask.ExpiringPolicies]: {
        title: 'Expiring Policies',
        description:
          'Uploading the expiring policies will enable to auto-create a cover letter and an expiring program reports. ',
        comingSoonTooltipContent: 'Import expiring policies from AMS',
        missingAlert: {
          title: 'Missing files',
          requiredFields: 'Required files',
        },
      },
      [SubmissionTask.LossRuns]: {
        title: 'Loss Runs',
        description: 'Capitola Co-Pilot will remind you to request Loss Runs from incumbents 60 days before due date.',
        comingSoonTooltipContent: 'Import from carriers portal',
        missingAlert: {
          title: 'Missing files',
          requiredFields: 'Required files',
        },
      },
      [SubmissionTask.RenewalDocs]: {
        title: 'Renewal App & Supporting Docs',
        description:
          'Collecting the renewal documents into the submission to easily attach them to your marketing submissions.',
        uploadFilesTooltipTitle: 'Upload & Label Documents',
        uploadFilesTooltipMessage:
          'Use the files toolbox to upload your submission documents, then label them accordingly.',
        missingAlert: {
          title: 'Missing files',
          requiredFields: 'Required files',
        },
      },
    },
    statuses: {
      [SubmissionTaskStatus.ToDo]: 'To Do',
      [SubmissionTaskStatus.InProgress]: 'In Progress',
      [SubmissionTaskStatus.Done]: 'Done',
    },
    actions: {
      upload: 'Upload',
      editSubmission: 'Edit Submission',
      edit: 'Edit',
    },
  },
  uploadViaEmailDialog: {
    title: 'Upload Via Email',
    description: 'Sending an email to this address will upload the attached files to the submission files.',
    action: 'Copy email address to clipboard',
  },
  emailsTab: {
    title: 'Emails',
    submissionEmailMenuLabel: 'Submission Emails',
    unassignedEmailsMenuLabel: 'Unassigned Emails',
    noEmailsYet: 'No Emails Yet',
    noResultsTitle: 'No Results Found',
    noResultsSubtitle: 'Try different keywords or filters',
    composeEmailButton: 'Compose',
    emailToMarket: 'Email to Market',
    emailToCustomer: 'Email to Customer',
    sentTo: 'To',
    from: 'From',
    sentToRecipient: (recipient: string | undefined) => (recipient ? `Sent to ${recipient}` : ''),
    sentToMarkets: (numberOfMarkets: number) => `Sent to ${numberOfMarkets} markets`,
    attachedFiles: 'Attached Files',
    lastModified: 'Last modified: ',
    menuOptions: {
      reply: 'Reply',
      followUp: 'Follow-up',
      duplicate: 'Duplicate',
      download: 'Download',
      currentlyNotAvailable: 'Currently not available',
      assign: 'Assign to submission',
      comingSoon: 'Coming soon',
    },
    replyTemplatesMenu: {
      title: 'Customer email templates',
      empty: 'Empty',
    },
    downloadSubmissionEmailsButton: 'Download submission emails',
    downloadEmailsButton: 'Download emails',
    downloadAllMarketingEmailsButton: 'Marketing emails',
    downloadAllCustomerEmailsButton: 'Customer emails',
    noFilesToDownload: 'No emails to download',
    downloadEmailsButtonTooltip: 'Download emails that were sent from the platform',
    searchInEmails: 'Search',
    searchInSubmissionEmails: 'Search in Submission emails',
    searchInUnassignedEmails: 'Search in Unassigned emails',
    labelInUse: 'Label already assigned to another email',
    inboundLabelOnly: 'Available only for inbound emails',
    other: 'Other',
    agent: 'Agent',
    to: 'To',
    cc: 'Cc',
    sortMenu: {
      date: {
        title: 'Date',
      },
      organization: {
        title: 'Agent/Market correspondence',
      },
    },
    addLabelButton: 'Add Type',
  },
  marketRequestsWorkspace: {
    primary: 'Primary',
    excess: 'XS',
    startMarketing: 'Start Marketing',
    startMarketingInfoToolbox: {
      title: 'Good Job!',
      message: 'Now it’s time to market your excess layers, let’s find the right markets and start quoting',
    },
    columnTitles: {
      product: 'Product',
      status: 'Status',
      info: 'Info',
      note: 'Internal note',
    },
    addPrimaryMarket: 'Primary',
    addXsMarket: 'Excess',
    addMarketQuote: 'Add Market Quote',
    viewQuote: 'View Quote',
    addDeclination: 'Add Declination Reason',
    addInformationRequest: 'Add Information Request',
    quoteNotAccepted: 'Quote Not Accepted',
    compareQuotes: 'Compare Quotes',
    quoteSummary: 'Quote Summary',
    continueQuoteEditingTitle: (role?: UserRole, source?: QuoteSource) => {
      if (role !== UserRole.Machine) {
        return 'Quote attached.';
      }
      return source === QuoteSource.API ? 'Quote received via API.' : 'Quote received via AI.';
    },
    continueQuoteEditingLinkText: 'Continue editing',
    continueQuoteEditingLinkSuffix: ' to generate Flow quote.',
    compareQuotesDisabledText: (minFiles: number) => `Select ${minFiles} to 4 markets with quotes to compare`,
    addProducts: 'Add Products',
    submit: (num: number) => `Submit (${num})`,
    requestBinders: (marketsNum: number) =>
      `Request Binder from ${marketsNum > 0 ? marketsNum.toString() : ''} Markets`,
    requestBindersDisabled:
      'A Bind Request can only be sent to one market per layer. Please make sure you have only selected markets with a quote in attachment point that no other markets have also an active quote.',

    requestPolicies: (marketsNum: number) =>
      `Request Policy from ${marketsNum > 0 ? marketsNum.toString() : ''} Markets`,
    submitToXMarkets: (marketsNum: number) =>
      `Send Submission to ${marketsNum > 0 ? marketsNum.toString() : ''} Markets`,
    submitViaMethod: (method: SubmissionMethod) => `Submit via ${method}`,
    awaitingQuoteEmptySlot: 'Submission sent.',
    declinedEmptySlot: (date: string) => `Declined on ${date}`,
    pendingCustomerEmptySlot: {
      informationRequestedOn: (date: string) => `Information Requested on ${date}`,
      informationRequests: (numOfRequests: number) => `${numOfRequests} information requests`,
    },
    readyToMarketEmptySlot: {
      addedToSubmission: (isIncumbent?: boolean) =>
        isIncumbent ? 'Added to submission as incumbent.' : 'Added to submission.',
    },
    awaitingQuoteReferred: 'API Referred: Quote request was referred to an UW and is being examined',
    awaitingQuoteReferral: 'API Referral: Quote request needs to be referred to an UW',
    bindersPolicy: {
      sendArfRequestDisabledText: 'This process becomes available after adding binder(s)',
      generateArfRequest: (formName: string) => `Generate ${formName}`,
    },
    methods: {
      viewEmail: 'View Email',
      openPortal: 'Open Portal',
      viaApi: 'Via API',
    },
    quoteShared: (isShared: boolean) => `Quote ${isShared ? 'Shared' : 'Not Shared'}`,
    binderShared: (isShared: boolean) => `Binder ${isShared ? 'Shared' : 'Not Shared'}`,
    policyShared: (isShared: boolean) => `Policy ${isShared ? 'Shared' : 'Not Shared'}`,
  },
  marketRequestItem: {
    statusActions: {
      statusChangedToIndication: 'Status changed to Indication',
    },
    dropDownItems: {
      bindingAndPolicy: 'Binding & Policy',
      rejectQuote: 'Reject Quote',
      changeStatus: 'Change Status',
      sendSubmissionToMarket: 'Submit',
      addInformationRequest: 'Add Information Request',
      attachQuote: 'Attach Quote',
      attachMarketQuote: 'Attach Market Quote',
      addDeclinationReason: 'Add Declination Reason',
      addQuoteIndication: 'Add Quote Indication',
      quoteNotAccepted: 'Quote Not Accepted',
      attachBinder: 'Attach Binder',
      attachPolicy: 'Attach Policy',
      submitViaPortal: 'Submit via Portal',
      submitViaAPI: 'Submit via API',
      submitViaEmail: 'Submit via Email',
      recommendedSection: 'Recommended',
      actionsSection: 'Actions',
      setAwaitingQuote: 'Set as Awaiting Quote',
      removeDeclination: 'Remove Declination Reason',
      removeQuote: 'Remove Quote',
      more: 'More',
      resubmit: 'Resubmit',
      deleteProduct: 'Delete Product',
      confirmDeleteProduct: 'Are you sure you want to delete this product?',
      removeInformationRequest: 'Remove Information Request',
      updateInformationRequest: 'Update Information Request',
      setAwaitingQuoteViaPortal: 'Mark As Submitted via Portal',
      editDeclinationReason: 'Edit Declination Reason',
      confirmRemoveDeclination: 'Are you sure you want to remove the declination reason?',
      confirmRemoveQuote: 'Are you sure you want to remove the quote?',
      setAwaitingBinder: 'Mark as Awaiting Binder',
      setAwaitingPolicy: 'Mark as Awaiting Policy',
      viewEmails: 'View Emails',
      viewQuote: 'View Quote',
      editQuote: 'Edit Quote',
      editBinder: 'Edit Binder',
      editPolicy: 'Edit Policy',
      viewBinder: 'View Binder',
      viewPolicy: 'View Policy',
      portal: 'Portal',
      openPortal: 'Open Portal',
      sendAdditionalInformationToMarket: 'Send Additional Information to the Market',
      removeBinder: 'Remove Binder',
      confirmRemoveBinder: 'Are you sure you want to remove the binder?',
      removePolicy: 'Remove Policy',
      confirmRemovePolicy: 'Are you sure you want to remove the policy?',
      shareQuote: 'Share Quote',
      shareBinder: 'Share Binder',
      sharePolicy: 'Share Policy',
      markQuoteAsShared: 'Mark Quote as Shared',
      markBinderAsShared: 'Mark Binder as Shared',
      markPolicyAsShared: 'Mark Policy as Shared',
    },
  },
  emailIntegrationBanner: {
    unauthenticated: {
      message: 'Connect your inbox',
      description: 'Connect and sync your inbox with Flow to send from your own email address',
    },
    invalidToken: {
      message: 'Reconnect your email',
      description: 'Resync with Flow to send from your own email address',
    },
    connectButton: 'Connect',
    dontConnectButton: "Don't connect",
  },
  marketCard: {
    notification: {
      title: 'Notifications',
      dismiss: 'Dismiss',
    },
  },
  quoteComparison: {
    comparisonPopupTitle: 'FlowAI Quote Comparison 🪄',
  },
  aiGeneratedContentPopup: {
    openThreadInOpenAIPlatform: 'Navigate to the thread in OpenAI platform',
    contentTab: 'Content',
    promptTab: 'Prompt',
    copyToClipboard: 'Copy to Clipboard',
    emptyState: {
      title: 'No generated content',
      description: 'Use regenerate button to start generating content.',
    },
    generatingComparison: 'Generating Comparison...',
    comparisonReady: 'Comparison Ready',
    feedback: {
      tooltipContent: 'Write your feedback',
      success: {
        title: 'Thanks for your feedback! 😊',
        message: 'Your feedback is invaluable to us! Please feel free to submit multiple notes.',
      },
      form: {
        footerText: 'Feel free to submit multiple feedback notes',
        submit: 'Submit Feedback',
        placeholder: 'Type your feedback here...',
        disabledTooltipContent: 'Feedback is empty',
      },
    },

    featureToName: {
      [AIFeature.QuoteCompare]: 'quote comparison',
      [AIFeature.QuoteBinderCompare]: 'quote binder comparison',
      [AIFeature.BinderPolicyCompare]: 'binder policy comparison',
      [AIFeature.QuoteGeneration]: 'quote insights',
      [AIFeature.ProductRecommendation]: 'products recommendation',
      [AIFeature.SubmissionAcknowledgment]: 'submission acknowledgment',
      [AIFeature.QuotePolicyCompare]: 'quote policy comparison',
      [AIFeature.RiskAssessment]: 'submission risk assessment',
      [AIFeature.ProposalGeneration]: 'proposal generation',
      [AIFeature.MarketingUpdateGeneration]: 'marketing update generation',
    },
    inputData: 'Input Data',
    filesText: 'files',
    emailsText: 'emails',
    submissionEmail: 'submission email',
    inboundEmail: 'inbound email',
    marketBrochures: 'Market brochures',
    applicationData: 'Application data',
    insuredData: 'Insured data',
    submissionCoverageLines: 'Submission coverage lines',
    selectedProducts: 'Selected products',
    productsEmailSignals: 'Products data',
    skippedUnsupportedFilesTitle: 'Skipped unsupported files',
    skippedUnsupportedFilesMessage: 'Some files attempted for upload are unsupported',

    declinationEmails: (count: number) => `declination ${pluralize('email', count)}`,
    quoteEmails: (count: number) => `quote ${pluralize('email', count)}`,

    askAboutThis: (featureName: string) => `Ask about this ${featureName}`,
    citationTypes: {
      quoteEmail: 'Quote Email',
      declinationEmail: 'Declination Email',
      brochure: 'Brochure',
      policySpecimen: 'Policy Specimen',
      file: 'File',
    },
  },
  submitViaApiModal: {
    title: (numberOfProducts: number) => `${numberOfProducts} API products ready to market`,
    description: 'Get your first quotes now, submit to available API products',
    buttons: {
      submitNow: 'Submit Now',
      illSubmitLater: "I'll Submit Later",
    },
  },
  addProducts: {
    apiAvailable: 'API Available',
    otherProducts: 'Other Products',
  },
};
