import { fileTypeConfig } from '@common/config';
import { BoxItem } from 'box-ui-elements/es';
import { ReactComponent as CsvIcon } from 'assets/images/files-explorer/csv.svg';
import { ReactComponent as DocIcon } from 'assets/images/files-explorer/doc.svg';
import { ReactComponent as EmailIcon } from 'assets/images/files-explorer/email.svg';
import { ReactComponent as FileIcon } from 'assets/images/files-explorer/file.svg';
import { ReactComponent as JpgIcon } from 'assets/images/files-explorer/jpg.svg';
import { ReactComponent as PdfIcon } from 'assets/images/files-explorer/pdf.svg';
import { ReactComponent as PngIcon } from 'assets/images/files-explorer/png.svg';
import { ReactComponent as PptIcon } from 'assets/images/files-explorer/ppt.svg';
import { ReactComponent as XlsIcon } from 'assets/images/files-explorer/xls.svg';
import { BoxTemplateLabels, FileType, UserRole } from 'enums';
import { TEMPLATE_KEY, TEMPLATE_SCOPE } from 'types';

// Downloading files using an iframe. This way (instead of using a regular anchor element)
// we prevent the beforeunload event from happening, which will trigger a dirty state prompt
// See: https://stackoverflow.com/questions/2452110/download-binary-without-triggering-onbeforeunload
// Note, adding a "download" attribute for an anchor element did not prevent the event from triggering
// although the link above suggests it
export function downloadFile(dataUrl: string) {
  const DOWNLOAD_IFRAME_ID = 'capitola-download-iframe';

  let iframe = document.querySelector(DOWNLOAD_IFRAME_ID) as HTMLIFrameElement;

  if (!iframe) {
    iframe = document.createElement('iframe');
    iframe.setAttribute('id', DOWNLOAD_IFRAME_ID);
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
  }

  iframe.src = dataUrl;
}

export function downloadBlob(blob: Blob, fileName = 'download') {
  const href = URL.createObjectURL(blob);

  const anchorElement = document.createElement('a');
  anchorElement.href = href;
  anchorElement.setAttribute('download', fileName);
  anchorElement.setAttribute('target', '_blank');
  anchorElement.click();

  URL.revokeObjectURL(href);
  anchorElement.remove();
}

export const fileExtensionToIcon = (fileExtension?: string) => {
  if (fileExtension === 'pdf') {
    return PdfIcon;
  }
  if (fileExtension === 'doc' || fileExtension === 'docx') {
    return DocIcon;
  }
  if (fileExtension === 'xls' || fileExtension === 'xlsx') {
    return XlsIcon;
  }
  if (fileExtension === 'ppt' || fileExtension === 'pptx') {
    return PptIcon;
  }
  if (fileExtension === 'csv') {
    return CsvIcon;
  }
  if (fileExtension === 'eml') {
    return EmailIcon;
  }
  if (fileExtension === 'jpg' || fileExtension === 'jpeg') {
    return JpgIcon;
  }
  if (fileExtension === 'png') {
    return PngIcon;
  }

  return FileIcon;
};
export const extractExtensionFromFile = (fileName: string) => fileName.split('.').slice(-1)[0].toLowerCase();

export const getFileMetadataField = (file: BoxItem, metadataField: string) =>
  // @ts-ignore
  file.metadata?.[TEMPLATE_SCOPE]?.[TEMPLATE_KEY]?.[metadataField];

export const getFileType = (file: BoxItem): FileType | undefined =>
  getFileMetadataField(file, BoxTemplateLabels.FileType);

export const getBoxClassifiedByRoleMetadata = (file: BoxItem): UserRole | undefined =>
  getFileMetadataField(file, BoxTemplateLabels.ClassifiedByRole);

export const getFileMarketType = (file: BoxItem): string | undefined =>
  getFileMetadataField(file, BoxTemplateLabels.MarketId);

export const isMarketIdInFileMarketTypeLabel = (file: BoxItem, marketId: string): boolean => {
  const marketType = getFileMarketType(file) || '';
  return marketType.split(',').includes(marketId);
};

export const addFileMarketTypeLabel = (file: BoxItem, marketId: string): string => {
  if (isMarketIdInFileMarketTypeLabel(file, marketId)) {
    return getFileMarketType(file)!;
  }
  return getFileMarketType(file) ? `${getFileMarketType(file)},${marketId}` : marketId;
};

export const getFileLabel = (file: BoxItem): string | undefined => {
  const fileType = getFileType(file);
  return fileType ? fileTypeConfig[fileType]?.text : undefined;
};
