import { BoxItem } from 'box-ui-elements/es';
import { JSX } from 'react';
import { SvgIcon } from '@common-components';
import { BoxTemplateLabels, FileType } from 'enums';
import { SubmissionFileMetadata, SyncMetadata } from 'types';
import { MenuItemProps } from 'components/menu';
import { LabelsMenuAnchorType, Option } from 'broker/components/LabelsMenu';

export const componentId = 'cap-files-explorer';

export interface FilesExplorerProps {
  folderId: string;
  title?: string;
  subtitle?: string;
  filter?: FileExplorerFilter;
  hideFilesConfig?: HideFilesConfig;
  autoLabel?: boolean;
  selectionMode?: boolean;
  viewOnlyMode?: boolean;
  onFileSelected?: (file: ExtendedBoxItem) => void;
  visibleFileIds?: string[];
  preSelectedFileIds?: string[];
  selectedFiles?: ExtendedBoxItem[];
  setSelectedFiles?: (boxItems: ExtendedBoxItem[]) => void;
  syncSelectedFiles?: (boxItems: BoxItem[]) => void;
  alwaysShowUploader?: boolean;
  onFileViewerStateChange?: (isOpened: boolean) => void;
  onFileUploadCompleted?: (boxItems: ExtendedBoxItem[]) => void;
  additionalFileMetadata?: SubmissionFileMetadata[];
  isLoadingAdditionalFileMetadata?: boolean;
  getFileSecondaryActions?: GetFileSecondaryActions;
  getPrimaryActionComponent?: (file: ExtendedBoxItem) => JSX.Element;
  fileListFooterContent?: JSX.Element;
  highlightFileTypes?: FileType[];
  isScanning?: boolean;
  setIsScanning?: (isScanning: boolean) => void;
  labelsMetadata?: LabelMetadata[];
  submissionId?: string;
}

export interface LabelMetadata {
  options: Option[];
  metadataFieldName: BoxTemplateLabels;
  getAnchorType: (file: BoxItem, value: string | undefined) => LabelsMenuAnchorType;
  addLabelText?: string;
  icon?: typeof SvgIcon;
  getSelectedOption?: (value: string) => Option;
  multiSelect?: boolean;
}

export type FileExplorerFilter = Partial<
  Record<
    BoxTemplateLabels,
    Array<{
      value: string;
      label: string;
    }>
  >
>;

export type HideFilesConfig = Partial<Record<BoxTemplateLabels, Array<string>>>;

export interface ExtendedBoxItem extends BoxItem, SyncMetadata {}

export type GetFileSecondaryActions = (file: BoxItem, hideQuoteAction?: boolean) => MenuItemProps[];
