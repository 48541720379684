import { MutableRefObject, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Divider, Stack, Typography } from '@common-components';
import { useScrollSections } from 'hooks';
import { Insured } from 'types';
import { SuggestionProps } from 'components/hookFormComponents/types';
import { convertIndustryObjectToOption } from 'broker/components/common/IndustryAutoComplete/utils';
import CoverageDetails from './components/CoverageDetails';
import SubmissionInsuredInformation from './components/SubmissionInsuredInformation';
import { StaticFormSection } from './types';
import { staticSubmissionSectionConfig } from './utils/static-submission-section-config';

export interface SubmissionFormProps {
  activeSection: string | undefined;
  setActiveSection: (section: string | undefined) => void;
  appliedSuggestions?: Record<string, SuggestionProps>;
  insuredList: Insured[];
  scrollableDivRef: MutableRefObject<HTMLDivElement | null>;
  sectionRefs: MutableRefObject<Record<string, HTMLDivElement | null>>;
}

export function StaticForm({
  appliedSuggestions,
  insuredList,
  scrollableDivRef,
  sectionRefs,
  activeSection,
  setActiveSection,
}: SubmissionFormProps) {
  const [navigatedToCreateContact, setNavigatedToCreateContact] = useState(false);

  const methods = useFormContext();

  const dueDateValue = methods.watch('dueDate');

  useScrollSections({
    scrollableDivRef,
    sectionRefs,
    activeSection,
    setActiveSection,
  });

  useEffect(() => {
    if (methods.formState.dirtyFields.needByDate) {
      methods.trigger('needByDate');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dueDateValue]);

  const onSelectedInsuredChange = (selectedInsured?: string | null) => {
    const selectedInsuredItem = insuredList.find((insuredItem) => insuredItem.id === selectedInsured);
    if (selectedInsuredItem) {
      const annualRevenue =
        selectedInsuredItem.annualRevenue && selectedInsuredItem.annualRevenue !== 0
          ? selectedInsuredItem.annualRevenue?.toString()
          : '';
      const employeeCount =
        selectedInsuredItem.employeeCount && selectedInsuredItem.employeeCount !== 0
          ? selectedInsuredItem.employeeCount?.toString()
          : '';

      methods.setValue('insuredName', selectedInsuredItem.name);
      methods.setValue('insuredId', selectedInsuredItem.id);
      methods.setValue(
        'industry',
        selectedInsuredItem.industry ? convertIndustryObjectToOption(selectedInsuredItem.industry) : undefined,
      );
      methods.setValue('insuredCompanyType', selectedInsuredItem.companyType);
      methods.setValue('insuredPrimaryAddress', selectedInsuredItem.primaryAddress);
      methods.setValue('insuredAnnualRevenue', annualRevenue);
      methods.setValue('insuredEmployeeCount', employeeCount);
      methods.setValue('insuredWebsite', selectedInsuredItem.website || '');
    } else {
      methods.setValue('insuredId', undefined);
      methods.setValue('insuredName', selectedInsured!);
    }
  };

  return (
    <Stack>
      <Stack overflow="hidden" height={1}>
        <Stack
          ref={(el) => {
            sectionRefs.current[StaticFormSection.InsuredInformation] = el as HTMLDivElement | null; // Type assertion for el
          }} // Attach the ref for each section
        >
          <Typography variant="subtitle2" mb={2}>
            {staticSubmissionSectionConfig[StaticFormSection.InsuredInformation].label}
          </Typography>
          <SubmissionInsuredInformation
            insured={insuredList}
            onSelectedInsuredChange={onSelectedInsuredChange}
            navigatedToCreateContact={navigatedToCreateContact}
            setNavigatedToCreateContact={setNavigatedToCreateContact}
            appliedSuggestions={appliedSuggestions}
          />
          <Divider sx={{ mt: 2, mb: 5 }} />
        </Stack>
        <Stack
          ref={(el) => {
            sectionRefs.current[StaticFormSection.CoverageParameters] = el as HTMLDivElement | null; // Type assertion for el
          }} // Attach the ref for each section
        >
          <Typography variant="subtitle2" mb={2}>
            {staticSubmissionSectionConfig[StaticFormSection.CoverageParameters].label}
          </Typography>
          <CoverageDetails appliedSuggestions={appliedSuggestions} />
        </Stack>
      </Stack>
    </Stack>
  );
}
