import { isCustomerOrganization } from '../enums';
import { messages } from '../messages';
import { EmailTemplateContext, Recipient, RecipientGroup, Submission, User } from '../types';
import { buttonVariable } from './button-variable';
import { linkVariable } from './link-variable';
import { EmailTemplateVariables, TemplateVariablesResolver } from './types';
import { getTimezoneLessDisplayDate } from '../utils/date-utils';

function getCustomerFirstNames(submission: Submission, recipientGroup?: RecipientGroup) {
    let recipients: Recipient[] = [];

    if (!recipientGroup || !isCustomerOrganization(recipientGroup.type)) {
        if (submission.user) {
            recipients = [submission.user];
        }
    } else if (recipientGroup) {
        recipients = recipientGroup.recipients;
    }

    return recipients.map((recipientItem) => recipientItem.firstName).join(', ');
}

function getSignatureOf(user: User) {
    return { value: user.signature ? user.signature : `${user.firstName} ${user.lastName}` };
}

export const CommonTemplateVariablesResolver: Pick<
    TemplateVariablesResolver<EmailTemplateContext>,
    | EmailTemplateVariables.customerFirstNames
    | EmailTemplateVariables.signature
    | EmailTemplateVariables.insuredName
    | EmailTemplateVariables.insuredAddress
    | EmailTemplateVariables.insuredEmployeeCount
    | EmailTemplateVariables.insuredWebsite
    | EmailTemplateVariables.assigneeName
    | EmailTemplateVariables.customerOrganization
    | EmailTemplateVariables.customerFullName
    | EmailTemplateVariables.submissionInternalLink
    | EmailTemplateVariables.submissionExternalLinkButton
    | EmailTemplateVariables.quotePageLink
    | EmailTemplateVariables.submissionBoxEmailSubject
    | EmailTemplateVariables.policyNumber
    | EmailTemplateVariables.policyEffectiveDate
> = {
    [EmailTemplateVariables.customerFirstNames]: ({ submission, recipient }: EmailTemplateContext) => ({
        value: getCustomerFirstNames(submission, recipient),
    }),
    [EmailTemplateVariables.signature]: ({ user }: EmailTemplateContext) => getSignatureOf(user),
    [EmailTemplateVariables.insuredName]: ({ submission }: EmailTemplateContext) => ({
        value: submission.insuredName,
    }),
    [EmailTemplateVariables.insuredAddress]: ({ submission }: EmailTemplateContext) => ({
        value: submission.insuredPrimaryAddress?.formattedAddress,
    }),
    [EmailTemplateVariables.insuredEmployeeCount]: ({ submission }: EmailTemplateContext) => ({
        value: submission.insuredEmployeeCount ? submission.insuredEmployeeCount.toString() : undefined,
    }),
    [EmailTemplateVariables.insuredWebsite]: ({ submission }: EmailTemplateContext) => ({
        value: submission.insuredWebsite ?? undefined,
    }),
    [EmailTemplateVariables.assigneeName]: ({ submission }: EmailTemplateContext) => ({
        value: submission.assignee?.firstName,
    }),
    [EmailTemplateVariables.customerOrganization]: ({ submission }: EmailTemplateContext) => ({
        value: submission.organizationName,
    }),
    [EmailTemplateVariables.customerFullName]: ({ submission }: EmailTemplateContext) => ({
        value: `${submission.user?.firstName} ${submission.user?.lastName}`,
    }),
    [EmailTemplateVariables.submissionInternalLink]: ({ submission, websiteBaseUrl }: EmailTemplateContext) => ({
        value: websiteBaseUrl
            ? linkVariable(`${websiteBaseUrl}/broker/submission/${submission.id}`, messages.emailVariables.here)
            : undefined,
    }),
    [EmailTemplateVariables.submissionExternalLinkButton]: ({ submission, websiteBaseUrl }: EmailTemplateContext) => ({
        value: websiteBaseUrl
            ? buttonVariable(
                  `${websiteBaseUrl}/portal/submission/${submission.id}`,
                  messages.emailVariables.continueInFlow,
              )
            : undefined,
    }),
    [EmailTemplateVariables.quotePageLink]: ({
        submission,
        triggeringQuote,
        websiteBaseUrl,
    }: EmailTemplateContext) => ({
        value:
            triggeringQuote?.id && websiteBaseUrl
                ? buttonVariable(
                      `${websiteBaseUrl}/portal/submission/${submission.id}/quote?id=${triggeringQuote.id}`,
                      messages.emailVariables.openQuoteInFlow,
                  )
                : undefined,
    }),
    [EmailTemplateVariables.submissionBoxEmailSubject]: ({ submissionBoxEmailSubject }: EmailTemplateContext) => ({
        value: submissionBoxEmailSubject,
    }),
    [EmailTemplateVariables.policyNumber]: ({ triggeringQuote }: EmailTemplateContext) => ({
        value: triggeringQuote?.policyDetails?.policyNumber,
    }),
    [EmailTemplateVariables.policyEffectiveDate]: ({ triggeringQuote }: EmailTemplateContext) => ({
        value:
            triggeringQuote?.policyDetails?.effectiveDate &&
            getTimezoneLessDisplayDate(triggeringQuote.policyDetails.effectiveDate),
    }),
};
