import { FileType } from 'enums';

export type DefaultFileTypesConfig = {
  [fileType in FileType]: {
    filter: boolean;
    selectable: boolean;
  };
};
export const defaultFileTypesConfig: DefaultFileTypesConfig = {
  [FileType.ExpiringProgram]: {
    filter: true,
    selectable: true,
  },
  [FileType.MarketQuote]: {
    filter: true,
    selectable: true,
  },
  [FileType.Submission]: {
    filter: true,
    selectable: true,
  },
  [FileType.MarketingReport]: {
    filter: true,
    selectable: true,
  },
  [FileType.ConceptualTower]: {
    filter: true,
    selectable: false,
  },
  [FileType.CapitolaBinder]: {
    filter: true,
    selectable: true,
  },
  [FileType.Policy]: {
    filter: true,
    selectable: true,
  },
  [FileType.CustomerEmail]: {
    filter: false,
    selectable: false,
  },
  [FileType.MarketingEmail]: {
    filter: false,
    selectable: false,
  },
  [FileType.InboundEmail]: {
    filter: false,
    selectable: false,
  },
  [FileType.ReplyEmail]: {
    filter: false,
    selectable: false,
  },
  [FileType.PastApplication]: {
    filter: true,
    selectable: true,
  },
  [FileType.LossRuns]: {
    filter: true,
    selectable: true,
  },
  [FileType.Supplement]: {
    filter: true,
    selectable: true,
  },
  [FileType.Proposal]: {
    filter: false,
    selectable: false,
  },
  [FileType.RansomwareApplication]: {
    filter: false,
    selectable: false,
  },
  [FileType.CapitolaQuote]: {
    filter: true,
    selectable: true,
  },
  [FileType.MarketInvoice]: {
    filter: true,
    selectable: true,
  },
  [FileType.CapitolaInvoice]: {
    filter: true,
    selectable: true,
  },
  [FileType.MarketBinder]: {
    filter: true,
    selectable: true,
  },
  [FileType.PolicyCoverLatter]: {
    filter: true,
    selectable: true,
  },
  [FileType.Financials]: {
    filter: true,
    selectable: true,
  },
  [FileType.SignedApplication]: {
    filter: true,
    selectable: true,
  },
  [FileType.DueDiligence]: {
    filter: true,
    selectable: true,
  },
  [FileType.Declination]: {
    filter: true,
    selectable: true,
  },
  [FileType.OtherSurplusForms]: {
    filter: true,
    selectable: true,
  },
  [FileType.PolicySpecimen]: {
    filter: true,
    selectable: true,
  },
  [FileType.Endorsement]: {
    filter: true,
    selectable: true,
  },
  [FileType.BOR]: {
    filter: true,
    selectable: true,
  },
  [FileType.QuoteComparison]: {
    filter: true,
    selectable: true,
  },
  [FileType.Subjectivities]: {
    filter: true,
    selectable: true,
  },
  [FileType.NoLossLetter]: {
    filter: true,
    selectable: true,
  },
  [FileType.CarrierSurplusForm]: {
    filter: true,
    selectable: true,
  },
  [FileType.FlowPolicy]: {
    filter: true,
    selectable: true,
  },
  [FileType.AOR]: {
    filter: true,
    selectable: true,
  },
  [FileType.StateCompliance]: {
    filter: true,
    selectable: true,
  },
};

export const defaultFileTypesSelection = (Object.keys(defaultFileTypesConfig) as FileType[]).filter(
  (key) => defaultFileTypesConfig[key as FileType].selectable,
);

export const defaultFileTypesFilter = (Object.keys(defaultFileTypesConfig) as FileType[])
  .filter((key) => defaultFileTypesConfig[key as FileType].filter)
  .map((key) => ({
    value: key,
    label: key,
  }));
