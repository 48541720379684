import { FormProvider, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { Box, Button, Chip, Divider, Stack } from '@common-components';
import { MailOutline } from '@icons';
import { useFormProvider } from 'hooks';
import { messages } from 'i18n';
import FormTextField from 'components/hookFormComponents/FormTextField';
import { activeTabUrlParamValues, BrokerUrlParams } from 'broker/broker-routes';
import { useUpdateQueryParam } from 'broker/hooks';
import { EmailListItemAttributes } from 'broker/pages/SubmissionWorkspacePage/components/Toolbox/components/Email/utils';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';

interface IndicationFormProps {
  id: string;
  isContentDirty: boolean;
  setIsContentDirty: (nextState: boolean) => void;
  closeWithoutPrompt: () => void;
  indicationEmail?: EmailListItemAttributes;
}

interface IndicationDataProps {
  indicationText?: string;
}

export const schema = yup.object().shape({
  indicationText: yup.string().trim().nullable(),
});

export default function IndicationForm({
  id,
  isContentDirty,
  setIsContentDirty,
  closeWithoutPrompt,
  indicationText,
  indicationEmail,
}: IndicationFormProps & IndicationDataProps) {
  const { updateSubmissionMarketRequest } = useSubmissionsWorkspace();
  const updateQueryParam = useUpdateQueryParam();

  const { methods } = useFormProvider<IndicationDataProps>({
    schema,
    setIsDirty: setIsContentDirty,
    defaultValues: { indicationText },
  });

  const onSubmit: SubmitHandler<IndicationDataProps> = async (data) => {
    await updateSubmissionMarketRequest(id, {
      indication: {
        value: data.indicationText || '',
        isAIExtracted: false,
      },
    });
    closeWithoutPrompt();
  };

  const onEmailChipClick = () => {
    if (!indicationEmail) {
      return;
    }
    updateQueryParam({
      addParams: [
        { queryParam: BrokerUrlParams.ACTIVE_TAB, value: activeTabUrlParamValues.EMAIL },
        { queryParam: BrokerUrlParams.SELECTED_EMAIL, value: indicationEmail.id },
      ],
    });
  };

  return (
    <Stack>
      <FormProvider {...methods}>
        <Box p={1} pb={0} width={400}>
          <FormTextField
            placeholder={messages.general.addNote}
            maxRows={6}
            minRows={6}
            fullWidth
            multiline
            autoFocus
            name="indicationText"
            id="indicationText"
            hideBorder
          />
        </Box>
      </FormProvider>
      <Divider />
      <Stack direction="row" p={2} justifyContent="flex-end" alignItems="center">
        <Stack mr="auto">
          {indicationEmail && (
            <Chip
              label={messages.general.email}
              onClick={onEmailChipClick}
              size="medium"
              startIcon={MailOutline}
              variant="contained"
              color="secondary"
            />
          )}
        </Stack>
        <Button
          size="small"
          variant="outlined"
          disabled={!isContentDirty || methods.formState.isSubmitting}
          onClick={methods.handleSubmit(onSubmit)}
        >
          {indicationText ? messages.buttons.update : messages.buttons.add}
        </Button>
      </Stack>
    </Stack>
  );
}
