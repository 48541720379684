import { useEffect, useRef } from 'react';
import { Stack } from '@common-components';
import { Insured } from 'types';
import { scrollIntoView } from 'utils';
import FormAddressInput from 'components/hookFormComponents/FormAddressInput';
import FormAutocomplete from 'components/hookFormComponents/FormAutocomplete/FormAutocomplete';
import FormAutocompleteCreatable from 'components/hookFormComponents/FormAutocomplete/FormAutocompleteCreatable';
import FormTextField from 'components/hookFormComponents/FormTextField';
import { SuggestionProps } from 'components/hookFormComponents/types';
import IndustryAutoComplete from 'broker/components/common/IndustryAutoComplete';
import {
  companyTypes,
  formFieldsConfig,
} from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/EditSubmissionNew/Screens/SubmissionStaticForm/StaticForm/utils/form-utils';

interface SubmissionInsuredInformationProps {
  insured: Insured[];
  navigatedToCreateContact: boolean;
  setNavigatedToCreateContact: (val: boolean) => void;
  onSelectedInsuredChange: (selectedInsured?: string | null) => void;
  appliedSuggestions?: Record<string, SuggestionProps>;
}

export default function SubmissionInsuredInformation({
  insured,
  navigatedToCreateContact,
  setNavigatedToCreateContact,
  onSelectedInsuredChange,
  appliedSuggestions,
}: SubmissionInsuredInformationProps) {
  const customerRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    // once returning from createContact screen then auto scroll to that field
    if (navigatedToCreateContact && customerRef.current) {
      scrollIntoView(customerRef.current);
      setNavigatedToCreateContact(false);
    }
  }, [navigatedToCreateContact, setNavigatedToCreateContact]);

  return (
    <Stack>
      <FormAutocompleteCreatable
        {...formFieldsConfig.insuredName}
        suggestion={appliedSuggestions?.[formFieldsConfig.insuredName.name]}
        onChange={onSelectedInsuredChange}
        options={insured.map((insuredOption) => ({
          label: insuredOption.name,
          value: insuredOption.id,
        }))}
      />

      <IndustryAutoComplete
        fieldProps={formFieldsConfig.industry}
        suggestion={appliedSuggestions?.[formFieldsConfig.industry.name]}
      />

      <FormAutocomplete
        {...formFieldsConfig.insuredCompanyType}
        options={companyTypes.map((type) => ({ label: type, value: type }))}
        autoSelect={false}
        suggestion={appliedSuggestions?.[formFieldsConfig.insuredCompanyType.name]}
      />

      <FormTextField
        {...formFieldsConfig.insuredEmployeeCount}
        isNumberFormat
        numberFormatProps={{ prefix: '' }}
        placeholder="1,000"
        fullWidth
        suggestion={appliedSuggestions?.[formFieldsConfig.insuredEmployeeCount.name]}
      />

      <FormTextField
        {...formFieldsConfig.insuredAnnualRevenue}
        suggestion={appliedSuggestions?.[formFieldsConfig.insuredAnnualRevenue.name]}
        isNumberFormat
        fullWidth
        placeholder="$10,000,000"
      />

      <FormAddressInput
        {...formFieldsConfig.insuredPrimaryAddress}
        suggestion={appliedSuggestions?.[formFieldsConfig.insuredPrimaryAddress.name]}
        fullWidth
      />

      <FormAddressInput
        {...formFieldsConfig.insuredMailingAddress}
        suggestion={appliedSuggestions?.[formFieldsConfig.insuredMailingAddress.name]}
        fullWidth
      />

      <FormTextField
        {...formFieldsConfig.insuredWebsite}
        fullWidth
        suggestion={appliedSuggestions?.[formFieldsConfig.insuredWebsite.name]}
      />
    </Stack>
  );
}
