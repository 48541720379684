import { BoxItem } from 'box-ui-elements/es';
import { useEffect, useState } from 'react';
import { BoxTemplateLabels } from 'enums';
import { useConditionallyRenderedModal, useGetParam } from 'hooks';
import { useBoxApi } from 'hooks/api/box';
import { BrokerUrlParams } from 'broker/broker-routes';
import { useFileActionsCallbacks } from 'broker/components/FilesExplorer/hooks';
import { componentId, ExtendedBoxItem, FilesExplorerProps } from 'broker/components/FilesExplorer/types';
import ContainedFileViewerModal from 'broker/dialogs/ContainedFileViewerModal';
import { OpenContainedFileViewerModalProps } from 'broker/dialogs/ContainedFileViewerModal/ContainedFileViewerModal';
import { useUpdateQueryParam } from 'broker/hooks';

interface ContainedFileViewerModalWrapperProps
  extends Pick<
    FilesExplorerProps,
    | 'getFileSecondaryActions'
    | 'isScanning'
    | 'setIsScanning'
    | 'onFileViewerStateChange'
    | 'viewOnlyMode'
    | 'selectionMode'
    | 'onFileSelected'
    | 'selectedFiles'
    | 'labelsMetadata'
    | 'submissionId'
  > {
  items: BoxItem[];
  afterFileOperation: () => Promise<void>;
  onLabelUpdate(
    extendedBoxItem: ExtendedBoxItem,
    labelFieldName: BoxTemplateLabels,
    selectedLabel?: string,
  ): Promise<void>;
}

export default function ContainedFileViewerModalWrapper({
  items,
  getFileSecondaryActions,
  afterFileOperation,
  isScanning,
  setIsScanning,
  onFileViewerStateChange,
  viewOnlyMode,
  selectionMode,
  onFileSelected,
  selectedFiles,
  onLabelUpdate,
  labelsMetadata,
  submissionId,
}: ContainedFileViewerModalWrapperProps) {
  const {
    openModal: openContainedFileViewerModal,
    closeModal: closeContainedFileViewerModal,
    modalState: containedFileViewerState,
  } = useConditionallyRenderedModal<OpenContainedFileViewerModalProps>({});

  const selectedFileId = useGetParam(BrokerUrlParams.SELECTED_FILE);
  const { getBoxItemById } = useBoxApi();
  const [selectedFile, setSelectedFile] = useState<BoxItem | undefined>();

  useEffect(() => {
    const setFile = async () => {
      if (!selectedFileId) {
        setSelectedFile(undefined);
      } else {
        const file = items.find((item) => item.id === selectedFileId);

        // If the file doesn't exist on the current list of files fetch it directly from Box.
        // This is needed to display files that are external to the submission
        setSelectedFile(file ?? (await getBoxItemById(selectedFileId))!);
      }
    };

    setFile();
  }, [items, selectedFileId, getBoxItemById]);

  const updateQueryParam = useUpdateQueryParam();

  const { onDeleteClicked, onDownloadClicked } = useFileActionsCallbacks({
    afterFileOperation,
    submissionId,
  });

  useEffect(() => {
    const isNewFileSelected =
      selectedFile &&
      containedFileViewerState.modalProps?.file &&
      selectedFile?.id !== containedFileViewerState.modalProps?.file.id;

    if ((!containedFileViewerState.isOpen || isNewFileSelected) && selectedFile) {
      openContainedFileViewerModal({ file: selectedFile });
    } else if (containedFileViewerState.isOpen && !selectedFile) {
      closeContainedFileViewerModal();
      setIsScanning?.(false);
    }
  }, [
    setIsScanning,
    closeContainedFileViewerModal,
    containedFileViewerState.isOpen,
    openContainedFileViewerModal,
    selectedFile,
    containedFileViewerState.modalProps?.file,
  ]);
  useEffect(() => {
    if (onFileViewerStateChange) {
      onFileViewerStateChange(containedFileViewerState.isOpen);
    }
    // callback shouldn't be in dependency as it is sent as a prop and could be unstable
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containedFileViewerState.isOpen]);

  const removeSelectedFileParam = () => {
    updateQueryParam({
      removeParams: [BrokerUrlParams.SELECTED_FILE],
    });
  };

  return containedFileViewerState.shouldRender ? (
    <ContainedFileViewerModal
      isScanning={isScanning}
      containerId={componentId}
      onDeleteClicked={onDeleteClicked}
      onDownloadClicked={onDownloadClicked}
      isOpened={containedFileViewerState.isOpen}
      onClose={removeSelectedFileParam}
      getFileSecondaryActions={getFileSecondaryActions}
      readonly={viewOnlyMode}
      selectionMode={selectionMode}
      onFileSelected={onFileSelected}
      selectedFiles={selectedFiles}
      onLabelUpdate={onLabelUpdate}
      labelsMetadata={labelsMetadata}
      viewOnlyMode={viewOnlyMode}
      {...containedFileViewerState.modalProps!}
    />
  ) : null;
}
