import { BoxTemplateLabels } from 'enums';
import FilesExplorer from 'broker/components/FilesExplorer';
import { useFileLabelsMetadata } from 'broker/pages/SubmissionWorkspacePage/hooks';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { useUiStoreState } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';

export default function FilesSideBar() {
  const { submission } = useSubmissionsWorkspace();
  const { filesExplorer } = useUiStoreState();
  const { getLabelsMetadata } = useFileLabelsMetadata();

  if (!submission) {
    return null;
  }

  return (
    <FilesExplorer
      folderId={submission?.boxFolderId}
      visibleFileIds={filesExplorer.visibleFileIds}
      hideFilesConfig={{
        [BoxTemplateLabels.IsVisibleToRetailer]: ['True'],
      }}
      viewOnlyMode
      labelsMetadata={getLabelsMetadata()}
      submissionId={submission.id}
    />
  );
}
