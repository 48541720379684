import { sortBy } from 'lodash';
import { Stack } from '@common-components';

import { FilterElementProps } from 'utils';
import CoveragesFilterItem from './CoveragesFilterItem';

interface CoveragesProps {
  coverageFilter: FilterElementProps[];
  handleVisibleLabelChange: (key: string) => void;
}

export default function CoveragesFilter({ coverageFilter, handleVisibleLabelChange }: CoveragesProps) {
  return (
    <Stack direction="row" width={1} gap={0.5} alignItems="flex-start" flexWrap="wrap">
      {sortBy(coverageFilter, (e) => e.label.toLowerCase()).map((coverage) => (
        <CoveragesFilterItem
          key={coverage.key}
          coverage={coverage}
          handleVisibleLabelChange={handleVisibleLabelChange}
        />
      ))}
    </Stack>
  );
}
