import * as yup from 'yup';
import { AnySchema } from 'yup';
import { FormFieldsConfigProps } from 'hooks';
import { messages } from 'i18n';
import { customYupValidateNumber } from 'utils';
import { DetailsFieldsNames } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/form-methods/enums';

const { invalidDate } = messages.formMessages;
const { requiredFieldShort } = messages.formMessages;

type DetailsFormFieldsConfig = Record<DetailsFieldsNames, FormFieldsConfigProps<DetailsFieldsNames>>;

export const detailsFormFieldsConfig: DetailsFormFieldsConfig = {
  [DetailsFieldsNames.InsuranceProductId]: {
    label: messages.addQuotePage.fieldLabels.product,
    placeholder: messages.addQuotePage.fieldPlaceholders.product,
    name: DetailsFieldsNames.InsuranceProductId,
    id: DetailsFieldsNames.InsuranceProductId,
  },
  [DetailsFieldsNames.Premium]: {
    label: messages.addQuotePage.fieldLabels.premium,
    placeholder: messages.addQuotePage.fieldPlaceholders.premium,
    name: DetailsFieldsNames.Premium,
    id: DetailsFieldsNames.Premium,
  },
  [DetailsFieldsNames.Limit]: {
    label: messages.addQuotePage.fieldLabels.limit,
    placeholder: messages.addQuotePage.fieldPlaceholders.limit,
    name: DetailsFieldsNames.Limit,
    id: DetailsFieldsNames.Limit,
    optional: true,
  },
  [DetailsFieldsNames.AgentCommission]: {
    label: messages.addQuotePage.fieldLabels.agentCommission,
    placeholder: messages.addQuotePage.fieldPlaceholders.agentCommission,
    name: DetailsFieldsNames.AgentCommission,
    id: DetailsFieldsNames.AgentCommission,
  },
  [DetailsFieldsNames.Comments]: {
    label: messages.addQuotePage.fieldLabels.comments,
    name: DetailsFieldsNames.Comments,
    id: DetailsFieldsNames.Comments,
  },
  [DetailsFieldsNames.ExpiresAt]: {
    label: messages.addQuotePage.fieldLabels.expiresAt,
    name: DetailsFieldsNames.ExpiresAt,
    id: DetailsFieldsNames.ExpiresAt,
  },
  [DetailsFieldsNames.PolicyNumber]: {
    label: messages.addQuotePage.fieldLabels.policyNumber,
    name: DetailsFieldsNames.PolicyNumber,
    id: DetailsFieldsNames.PolicyNumber,
  },
  [DetailsFieldsNames.PolicyEffectiveDate]: {
    label: messages.addQuotePage.fieldLabels.policyEffectiveDate,
    name: DetailsFieldsNames.PolicyEffectiveDate,
    id: DetailsFieldsNames.PolicyEffectiveDate,
  },
  [DetailsFieldsNames.PolicyExpirationDate]: {
    label: messages.addQuotePage.fieldLabels.policyExpirationDate,
    name: DetailsFieldsNames.PolicyExpirationDate,
    id: DetailsFieldsNames.PolicyExpirationDate,
  },
  [DetailsFieldsNames.FlowCommission]: {
    label: messages.addQuotePage.fieldLabels.flowCommission,
    name: DetailsFieldsNames.FlowCommission,
    id: DetailsFieldsNames.FlowCommission,
  },
  [DetailsFieldsNames.Date]: {
    label: messages.addQuotePage.fieldLabels.date,
    name: DetailsFieldsNames.Date,
    id: DetailsFieldsNames.Date,
  },
  [DetailsFieldsNames.SubLimits]: {
    label: '',
    name: DetailsFieldsNames.SubLimits,
    id: DetailsFieldsNames.SubLimits,
  },
  [DetailsFieldsNames.TriaPremium]: {
    label: messages.addQuotePage.fieldLabels.triaPremium,
    name: DetailsFieldsNames.TriaPremium,
    id: DetailsFieldsNames.TriaPremium,
  },
  [DetailsFieldsNames.CarrierFee]: {
    label: messages.addQuotePage.fieldLabels.carrierFee,
    name: DetailsFieldsNames.CarrierFee,
    id: DetailsFieldsNames.CarrierFee,
  },
  [DetailsFieldsNames.FlowBrokerFee]: {
    label: messages.addQuotePage.fieldLabels.flowBrokerFee,
    name: DetailsFieldsNames.FlowBrokerFee,
    id: DetailsFieldsNames.FlowBrokerFee,
  },
  [DetailsFieldsNames.SurplusLineTax]: {
    label: messages.addQuotePage.fieldLabels.surplusLineTax,
    name: DetailsFieldsNames.SurplusLineTax,
    id: DetailsFieldsNames.SurplusLineTax,
  },
  [DetailsFieldsNames.SurplusStampingFee]: {
    label: messages.addQuotePage.fieldLabels.surplusStampingFee,
    name: DetailsFieldsNames.SurplusStampingFee,
    id: DetailsFieldsNames.SurplusStampingFee,
  },
  [DetailsFieldsNames.Subjectivities]: {
    label: messages.addQuotePage.fieldLabels.subjectivities,
    name: DetailsFieldsNames.Subjectivities,
    id: DetailsFieldsNames.Subjectivities,
  },
  [DetailsFieldsNames.AttachmentPoint]: {
    label: messages.addQuotePage.fieldLabels.attachmentPoint,
    placeholder: messages.addQuotePage.fieldPlaceholders.attachmentPoint,
    name: DetailsFieldsNames.AttachmentPoint,
    id: DetailsFieldsNames.AttachmentPoint,
  },
  [DetailsFieldsNames.StateCompliance]: {
    label: messages.addQuotePage.fieldLabels.stateCompliance,
    placeholder: messages.addQuotePage.fieldPlaceholders.stateCompliance,
    name: DetailsFieldsNames.StateCompliance,
    id: DetailsFieldsNames.StateCompliance,
  },
  [DetailsFieldsNames.CoverageLines]: {
    label: messages.addQuotePage.fieldLabels.coverageLines,
    placeholder: messages.addQuotePage.fieldPlaceholders.coverageLines,
    name: DetailsFieldsNames.CoverageLines,
    id: DetailsFieldsNames.CoverageLines,
    optional: true,
  },
};

export const detailsFormValidationShape: Record<DetailsFieldsNames, AnySchema> = {
  [DetailsFieldsNames.InsuranceProductId]: yup.string().default('').nullable().required(requiredFieldShort),
  [DetailsFieldsNames.Limit]: yup
    .string()
    .default('')
    .test(
      'customYupValidateNumber',
      'customYupValidateNumber',
      customYupValidateNumber({ fieldName: messages.addQuotePage.fieldLabels.limit }),
    ),
  [DetailsFieldsNames.AttachmentPoint]: yup
    .string()
    .default('')
    .test(
      'customYupValidateNumber',
      'customYupValidateNumber',
      customYupValidateNumber({ fieldName: messages.addQuotePage.fieldLabels.attachmentPoint }),
    )
    .required(requiredFieldShort),
  [DetailsFieldsNames.Premium]: yup
    .string()
    .default('')
    .test(
      'customYupValidateNumber',
      'customYupValidateNumber',
      customYupValidateNumber({ fieldName: messages.addQuotePage.fieldLabels.premium }),
    )
    .required(requiredFieldShort),
  [DetailsFieldsNames.AgentCommission]: yup
    .string()
    .default('')
    .test(
      'customYupValidateNumber',
      'customYupValidateNumber',
      customYupValidateNumber({
        fieldName: messages.addQuotePage.fieldLabels.agentCommission,
        minNumber: 0,
        maxNumber: 100,
        allowZero: true,
      }),
    ),
  [DetailsFieldsNames.ExpiresAt]: yup.string().default('').notOneOf(['Invalid date'], invalidDate),
  [DetailsFieldsNames.Comments]: yup.string(),
  [DetailsFieldsNames.PolicyNumber]: yup.string(),
  [DetailsFieldsNames.PolicyEffectiveDate]: yup.string(),
  [DetailsFieldsNames.PolicyExpirationDate]: yup.string(),
  [DetailsFieldsNames.FlowCommission]: yup
    .string()
    .default('')
    .test(
      'customYupValidateNumber',
      'customYupValidateNumber',
      customYupValidateNumber({
        fieldName: messages.addQuotePage.fieldLabels.agentCommission,
        minNumber: 0,
        maxNumber: 100,
        allowZero: true,
      }),
    ),
  [DetailsFieldsNames.Date]: yup.string().default('').notOneOf(['Invalid date'], invalidDate),
  [DetailsFieldsNames.SubLimits]: yup.string(),
  [DetailsFieldsNames.TriaPremium]: yup
    .string()
    .default('')
    .test(
      'customYupValidateNumber',
      'customYupValidateNumber',
      customYupValidateNumber({ fieldName: messages.addQuotePage.fieldLabels.triaPremium }),
    ),
  [DetailsFieldsNames.CarrierFee]: yup
    .string()
    .default('')
    .test(
      'customYupValidateNumber',
      'customYupValidateNumber',
      customYupValidateNumber({ fieldName: messages.addQuotePage.fieldLabels.carrierFee }),
    ),
  [DetailsFieldsNames.FlowBrokerFee]: yup
    .string()
    .default('')
    .test(
      'customYupValidateNumber',
      'customYupValidateNumber',
      customYupValidateNumber({ fieldName: messages.addQuotePage.fieldLabels.flowBrokerFee }),
    ),
  [DetailsFieldsNames.SurplusLineTax]: yup
    .string()
    .default('')
    .test(
      'customYupValidateNumber',
      'customYupValidateNumber',
      customYupValidateNumber({ fieldName: messages.addQuotePage.fieldLabels.surplusLineTax }),
    ),
  [DetailsFieldsNames.SurplusStampingFee]: yup
    .string()
    .default('')
    .test(
      'customYupValidateNumber',
      'customYupValidateNumber',
      customYupValidateNumber({ fieldName: messages.addQuotePage.fieldLabels.surplusStampingFee }),
    ),
  [DetailsFieldsNames.Subjectivities]: yup.array().of(yup.object().shape({ value: yup.string() })),
  [DetailsFieldsNames.CoverageLines]: yup.array().of(yup.string()),
  [DetailsFieldsNames.StateCompliance]: yup.string(),
};
