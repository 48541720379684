import { groupBy, uniq } from 'lodash';
import { useMemo } from 'react';
import { HeraldFormType } from 'clients/types';
import { PartialSubmission } from 'types';
import mapHeraldParametersToFormState from './mappers/map-herald-parameters-to-form-state';
import { HeraldApplicationNormalized, HeraldNormalizedParameter } from './types';
import { flowStaticQuestionsConfig } from './utils/flow-static-questions/flow-static-questions-config';

interface GetHeraldComputedDataProps {
  formType: HeraldFormType;
  heraldApplicationNormalized: HeraldApplicationNormalized;
  submission: PartialSubmission;
}

function getFlowStaticQuestions(submission: PartialSubmission): HeraldNormalizedParameter[] {
  return flowStaticQuestionsConfig.map((config) => ({
    ...config,
    value: config.getValue(submission),
  }));
}

function addFlowStaticQuestionsToHeraldParameters(
  dynamicQuestions: HeraldNormalizedParameter[],
  flowStaticQuestions: HeraldNormalizedParameter[],
) {
  let internalFlowStaticQuestions = [...flowStaticQuestions];
  const unitedQuestions = [];
  for (const dynamicQuestion of dynamicQuestions) {
    unitedQuestions.push(dynamicQuestion);
    // Find all flowStaticQuestions that should be shown after this dynamicQuestion
    const matchingFlowStaticQuestions = internalFlowStaticQuestions.filter((flowStaticQuestion) =>
      flowStaticQuestion.showAfterOneOfTheseQuestions?.includes(dynamicQuestion.parameter_id),
    );

    // Add all matching flowStaticQuestions to unitedQuestions
    unitedQuestions.push(...matchingFlowStaticQuestions);

    // Remove the added questions from internalFlowStaticQuestions
    internalFlowStaticQuestions = internalFlowStaticQuestions.filter(
      (question) => !matchingFlowStaticQuestions.includes(question),
    );
  }
  return [...unitedQuestions, ...internalFlowStaticQuestions];
}

export function useGetHeraldComputedData({ heraldApplicationNormalized, submission }: GetHeraldComputedDataProps) {
  const filteredHeraldParameters = useMemo(() => {
    const allQuestions = addFlowStaticQuestionsToHeraldParameters(
      [...heraldApplicationNormalized.risk_values, ...heraldApplicationNormalized.coverage_values],
      getFlowStaticQuestions(submission),
    );
    return allQuestions.filter(
      (question) =>
        !question.isHidden?.([
          ...heraldApplicationNormalized.risk_values,
          ...heraldApplicationNormalized.coverage_values,
        ]),
    );
  }, [heraldApplicationNormalized.coverage_values, heraldApplicationNormalized.risk_values, submission]);
  const defaultValues = useMemo(
    () => mapHeraldParametersToFormState(filteredHeraldParameters),
    [filteredHeraldParameters],
  );

  const heraldParametersBySections: { [key in string]: HeraldNormalizedParameter[] } = useMemo(
    () => groupBy(filteredHeraldParameters, 'section'),
    [filteredHeraldParameters],
  );

  const sectionList = useMemo(
    () => uniq(filteredHeraldParameters.map((obj) => obj.section)),
    [filteredHeraldParameters],
  );

  return {
    filteredHeraldParameters,
    defaultValues,
    heraldParametersBySections,
    sectionList,
  };
}
