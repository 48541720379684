import { Collapse, Stack, Tooltip, Typography } from '@common-components';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@icons';
import { useBoolean } from 'hooks';
import { messages } from 'i18n';
import { PartialSubmission } from 'types';
import { truncateMultiLineText } from 'utils';

export default function SubmissionRiskAssessment({ submission }: { submission: PartialSubmission | null }) {
  const [isCollapsed, { toggle: toggleCollapse }] = useBoolean(false);

  if (!submission?.riskAssessment) {
    return undefined;
  }

  return (
    <Tooltip tooltipContent={submission.riskAssessment} placement="top">
      <Stack onClick={toggleCollapse} gap={0} bgcolor="blueGrey.50" py={1} px={1.5} mx={2} borderRadius={1} my={1}>
        <Stack direction="row">
          <Typography variant="captionBold">{messages.riskAssessmentNote.title}</Typography>
          <KeyboardArrowDownIcon sx={{ fontSize: '1rem' }} />
        </Stack>
        <Collapse collapsedSize={40} in={isCollapsed}>
          <Typography sx={{ ...(!isCollapsed && truncateMultiLineText(2)) }} variant="body2">
            {submission.riskAssessment}
          </Typography>
        </Collapse>
      </Stack>
    </Tooltip>
  );
}
