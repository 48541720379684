import { Divider, Stack, Typography } from '@common-components';
import { Search as SearchIcon } from '@icons';
import { messages } from 'i18n';
import { demoData } from 'utils';
import { ContentProps } from 'broker/components/FilesExplorer/components/Content/types';
import { useFileActionsCallbacks, useFilesDisplay } from 'broker/components/FilesExplorer/hooks';
import { shouldApplySearchInputFilter } from 'broker/components/FilesExplorer/utils';
import FileCard from './FileCard/FileCard';
import FileItemSkeleton from './FileItemSkeleton';

interface FilesListSectionProps
  extends Pick<
    ContentProps,
    | 'afterFileOperation'
    | 'isLoading'
    | 'doesFolderHasFiles'
    | 'uploaderShown'
    | 'filesSlice'
    | 'search'
    | 'silentAfterFileOperation'
    | 'selectionMode'
    | 'onFileSelected'
    | 'selectedFiles'
    | 'getFileSecondaryActions'
    | 'getPrimaryActionComponent'
    | 'highlightFileTypes'
    | 'viewOnlyMode'
    | 'labelsMetadata'
    | 'onLabelUpdate'
    | 'setSelectedFiles'
    | 'submissionId'
  > {}

export default function FilesListSection({
  afterFileOperation,
  isLoading,
  doesFolderHasFiles,
  uploaderShown,
  filesSlice,
  search,
  onLabelUpdate,
  selectionMode,
  onFileSelected,
  selectedFiles,
  getFileSecondaryActions,
  getPrimaryActionComponent,
  highlightFileTypes,
  viewOnlyMode,
  labelsMetadata,
  setSelectedFiles,
  submissionId,
}: FilesListSectionProps) {
  const { onDeleteClicked, onRenameSubmit, onPreviewClicked, onDownloadClicked } = useFileActionsCallbacks({
    afterFileOperation,
    selectedFiles,
    setSelectedFiles,
    submissionId,
  });

  const filesDisplay = useFilesDisplay();

  return (
    <>
      {isLoading && (
        <Stack flex={1} gap={4}>
          {demoData(4).map((key) => (
            <FileItemSkeleton key={key.id} />
          ))}
        </Stack>
      )}
      {doesFolderHasFiles &&
        !uploaderShown &&
        (filesSlice.length === 0 && !shouldApplySearchInputFilter(search) ? (
          <Stack flex={1} gap={1} justifyContent="center" alignItems="center">
            <SearchIcon opacity={0.2} fontSize="large" />
            <Typography color="text.disabled" variant="body2">
              {messages.fileExplorer.noItemsFound}
            </Typography>
          </Stack>
        ) : (
          <Stack flex={1} sx={{ overflowY: 'scroll' }} divider={<Divider sx={{ mx: 2 }} />}>
            {filesSlice?.map((file) => (
              <FileCard
                key={file.id}
                file={file}
                searchInput={search}
                onPreviewClicked={onPreviewClicked}
                onRenameSubmit={onRenameSubmit}
                onDownloadClicked={onDownloadClicked}
                onDeleteClicked={onDeleteClicked}
                onLabelUpdate={onLabelUpdate}
                selectionMode={selectionMode}
                onSelected={onFileSelected}
                isSelected={
                  selectedFiles && !!selectedFiles.find((currSelectedFile) => currSelectedFile.id === file.id)
                }
                filesDisplay={filesDisplay}
                getFileSecondaryActions={getFileSecondaryActions}
                getPrimaryActionComponent={getPrimaryActionComponent}
                highlightFileTypes={highlightFileTypes}
                showLabelSelector={!viewOnlyMode}
                viewOnlyMode={viewOnlyMode}
                labelsMetadata={labelsMetadata}
              />
            ))}
          </Stack>
        ))}
    </>
  );
}
