import { compact, isEmpty, keyBy } from 'lodash';
import { DeclinationType } from 'enums';
import { messages } from 'i18n';
import { DeclinationReason } from 'types';

export type DeclinationMetadata = { declinationType: DeclinationType; reason: string; placeholder: string };
export const declinationConfig: DeclinationMetadata[] = [
  {
    declinationType: DeclinationType.AUTO_INDUSTRY_BASED,
    reason: messages.declinationConfig.autoIndustryBasedReason,
    placeholder: messages.declinationConfig.autoIndustryBasedPlaceholder,
  },
  {
    declinationType: DeclinationType.OPEN_CLAIM_HISTORY,
    reason: messages.declinationConfig.openClaimHistoryReason,
    placeholder: messages.declinationConfig.openClaimHistoryPlaceholder,
  },
  {
    declinationType: DeclinationType.PRIOR_CLAIM_HISTORY,
    reason: messages.declinationConfig.priorClaimHistoryReason,
    placeholder: messages.declinationConfig.priorClaimHistoryPlaceholder,
  },
  {
    declinationType: DeclinationType.RISK,
    reason: messages.declinationConfig.riskReason,
    placeholder: messages.declinationConfig.riskPlaceholder,
  },
  {
    declinationType: DeclinationType.ATTACHMENT_POINT_TOO_LOW,
    reason: messages.declinationConfig.attachmentPointTooLowReason,
    placeholder: messages.declinationConfig.attachmentPointTooLowPlaceholder,
  },
  {
    declinationType: DeclinationType.UNDERLYING_PRICING,
    reason: messages.declinationConfig.underlyingPricingReason,
    placeholder: messages.declinationConfig.underlyingPricingPlaceholder,
  },
  {
    declinationType: DeclinationType.LEGAL_ENVIRONMENT,
    reason: messages.declinationConfig.legalEnvironmentReason,
    placeholder: messages.declinationConfig.legalEnvironmentPlaceholder,
  },
  {
    declinationType: DeclinationType.CANNOT_COMPETE,
    reason: messages.declinationConfig.cannotCompeteReason,
    placeholder: messages.declinationConfig.cannotCompetePlaceholder,
  },
  {
    declinationType: DeclinationType.MARKET_BLOCKED,
    reason: messages.declinationConfig.marketBlockedReason,
    placeholder: messages.declinationConfig.marketBlockedPlaceholder,
  },
  {
    declinationType: DeclinationType.OTHER,
    reason: messages.declinationConfig.otherReason,
    placeholder: '',
  },
];

const metadataByType = keyBy(declinationConfig, (metadata) => metadata.declinationType);

export const getDeclinationMetadataByType = (type: DeclinationType | undefined) =>
  type ? metadataByType[type] : undefined;

export const getDeclinationMessage = (declinations: DeclinationReason[]) => {
  if (isEmpty(declinations)) {
    return undefined;
  }

  return compact(
    declinations.map((declination) => {
      const declinationMetadata = getDeclinationMetadataByType(declination.type);
      if (!declinationMetadata) {
        return undefined;
      }
      if (declination.type === DeclinationType.OTHER) {
        return declination.details;
      }
      return messages.templateVariables.declination(declinationMetadata.reason, declination.details);
    }),
  ).join('\n\n');
};
