import { AddressDetails } from '@common/types';
import { usePlacesWidget } from 'react-google-autocomplete';
import { useFormContext } from 'react-hook-form';
import { mapGoogleMapResultToStructuredAddress } from 'utils';
import FormTextField, { FormTextFieldProps } from 'components/hookFormComponents/FormTextField';

interface AddressInputProps extends FormTextFieldProps {}

/*
state in form that use this field component should be AddressDetails
 */
export default function FormAddressInput(props: AddressInputProps) {
  const { name } = props;
  const { setValue, watch } = useFormContext();
  const addressValue = watch(name) as AddressDetails | undefined;
  const inputValue = addressValue?.formattedAddress || '';

  const onValueChange = (addressDetails: AddressDetails) => {
    setValue(name, addressDetails);
  };

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    options: {
      types: [],
    },
    language: 'en',
    onPlaceSelected: (place) => {
      if (place.formatted_address) {
        onValueChange({
          ...mapGoogleMapResultToStructuredAddress(place),
          formattedAddress: place.formatted_address,
        });
      }
    },
  });

  return (
    <FormTextField
      {...props}
      inputProps={{
        ref,
      }}
      stateOverride={{
        value: inputValue,
        onChange: (e) => {
          // once editing the address then reset the structured address state
          onValueChange({
            formattedAddress: e.target.value,
          });
        },
      }}
      fullWidth
    />
  );
}
