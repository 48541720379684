import { FocusEvent, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Autocomplete, Box, FormControl, FormFieldRequiredLabel, FormHelperText, TextField } from '@common-components';
import { FormControlHelperTextMarginBottom, FormControlPaddingBottom } from 'themes';
import { normalizeError } from 'utils';
import FormFieldSuggestionOverlay from 'components/FormFieldSuggestionOverlay';
import FormRenderOption from 'components/hookFormComponents/FormAutocomplete/FormRenderOption';
import { AutocompleteOption } from 'components/hookFormComponents/FormAutocomplete/utils';
import InputLabel from 'components/hookFormComponents/InputLabel';
import { FormHelperTextStyles } from 'components/hookFormComponents/styles';
import { SuggestionProps } from 'components/hookFormComponents/types';

interface ContentProps<T extends AutocompleteOption> {
  name: string;
  label: string;
  selectedOption?: T;
  onInputChange?: (value: string) => void;
  isLoading: boolean;
  options: T[];
  optional?: boolean;
  placeholder?: string;
  onBlur?: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement | HTMLElement>) => boolean | undefined;
  suggestion?: SuggestionProps;
  enhancedRequired?: boolean;
}

export function Content<T extends AutocompleteOption>({
  selectedOption,
  onInputChange,
  name,
  isLoading,
  options,
  label,
  optional,
  placeholder,
  onBlur,
  suggestion,
  enhancedRequired,
}: ContentProps<T>) {
  const [inputValue, setInputValue] = useState<string>(selectedOption?.label || '');
  const [ownSuggestion, setOwnSuggestion] = useState<SuggestionProps | undefined>(suggestion);

  useEffect(() => {
    setOwnSuggestion(suggestion);
  }, [suggestion]);
  const {
    control,
    formState: { errors },
    trigger,
  } = useFormContext();

  const errorMessage = normalizeError(errors, name);

  // Sync inputValue and check for initial compatibility
  useEffect(() => {
    if (selectedOption?.label) {
      setInputValue(selectedOption.label);
    }
  }, [selectedOption?.label]);

  return (
    <FormControl
      sx={{
        pb: FormControlPaddingBottom,
        mb: errorMessage ? FormControlHelperTextMarginBottom : 0,
      }}
      fullWidth
    >
      <InputLabel id={`${name}-label`} error={!!errorMessage} label={label} optional={optional} htmlFor={name} />
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value, onBlur: fieldPropsOnBlur, ...field } }) => (
          <Box position="relative">
            <Autocomplete
              {...field}
              onInputChange={(_, newInputValue) => {
                onInputChange?.(newInputValue);
                setInputValue(newInputValue || ''); // Ensure we handle undefined values
              }}
              onChange={(_, option) => {
                onChange(option || null); // Update selected option, handle potential null
                if (errorMessage) {
                  trigger(name);
                }
              }}
              size="small"
              filterOptions={(x) => x}
              inputValue={inputValue}
              value={selectedOption || null}
              options={options}
              getOptionLabel={(option) => option.label || ''}
              loading={isLoading}
              onBlur={(e) => {
                let allowPropagation: boolean = true;
                if (onBlur) {
                  const response = onBlur(e);
                  if (response !== undefined) {
                    allowPropagation = response;
                  }
                }
                if (allowPropagation) {
                  fieldPropsOnBlur();
                }
              }}
              renderOption={(props, option) => (
                <FormRenderOption key={option.value} option={option} htmlProps={props} />
              )}
              renderInput={(params) => (
                <TextField
                  error={!!errorMessage}
                  sx={(theme) => ({
                    input: {
                      typography: 'body2',
                    },
                    color: theme.palette.text.primary,
                    '& .MuiInputBase-input': {
                      padding: '0.5rem',
                    },
                  })}
                  placeholder={placeholder}
                  {...params}
                />
              )}
            />
            {ownSuggestion?.value && (
              <FormFieldSuggestionOverlay
                text={ownSuggestion.value}
                onClick={() => {
                  setOwnSuggestion(undefined);
                }}
              />
            )}
            {enhancedRequired && !optional && !selectedOption && <FormFieldRequiredLabel shiftLeft={24} />}
          </Box>
        )}
      />
      {errorMessage && (
        <FormHelperText error sx={FormHelperTextStyles}>
          {errorMessage}
        </FormHelperText>
      )}
      {ownSuggestion?.reason && <FormHelperText sx={FormHelperTextStyles}>{ownSuggestion.reason} </FormHelperText>}
    </FormControl>
  );
}
