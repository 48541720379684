import * as yup from 'yup';
import { messages } from 'i18n';

const { requiredField, positiveNumberOnly } = messages.formMessages;

export type FormData = yup.InferType<typeof schema>;

export const schema = yup.object().shape({
  attachmentPoint: yup.number().positive(positiveNumberOnly).typeError(requiredField),
});

export const formFieldsNames = {
  attachmentPoint: 'attachmentPoint',
};
