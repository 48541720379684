import * as yup from 'yup';
import { AnySchema } from 'yup';
import { ContactRole } from 'enums';
import { FormFieldsConfigProps } from 'hooks';
import { messages } from 'i18n';

const { requiredField, invalidEmail, uniqueEmail } = messages.formMessages;

export enum FormFieldsNames {
  MarketName = 'marketName',
  Memo = 'memo',
  RiskAppetite = 'riskAppetite',
  Contacts = 'contacts',
  Categories = 'categories',
}

export enum FormFieldsContactArrayNames {
  Id = 'id',
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  PhoneNumber = 'phoneNumber',
  Role = 'role',
  Title = 'title',
}

export enum FormMode {
  Create = 'create',
  Update = 'update',
}

export enum UrlParam {
  Id = 'id',
}

export const contactFieldsNames = {
  id: 'id',
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  phoneNumber: 'phoneNumber',
  role: 'role',
  title: 'title',
};

type FormFieldsConfig = Record<FormFieldsNames, FormFieldsConfigProps<FormFieldsNames>>;

export const formFieldsConfig: FormFieldsConfig = {
  [FormFieldsNames.MarketName]: {
    label: messages.userMarketModal.formFields.label.marketName,
    placeholder: messages.userMarketModal.formFields.placeholder.marketName,
    name: FormFieldsNames.MarketName,
    id: FormFieldsNames.MarketName,
  },
  [FormFieldsNames.Contacts]: {
    label: messages.userMarketModal.formFields.label.contacts,
    placeholder: messages.userMarketModal.formFields.placeholder.contacts,
    name: FormFieldsNames.Contacts,
    id: FormFieldsNames.Contacts,
  },
  [FormFieldsNames.Memo]: {
    label: '',
    placeholder: messages.userMarketModal.formFields.placeholder.memo,
    name: FormFieldsNames.Memo,
    id: FormFieldsNames.Memo,
    optional: true,
  },
  [FormFieldsNames.RiskAppetite]: {
    label: '',
    placeholder: messages.userMarketModal.formFields.placeholder.riskAppetite,
    name: FormFieldsNames.RiskAppetite,
    id: FormFieldsNames.RiskAppetite,
    optional: true,
  },
  [FormFieldsNames.Categories]: {
    label: '',
    placeholder: messages.userMarketModal.formFields.placeholder.categories,
    name: FormFieldsNames.Categories,
    id: FormFieldsNames.Categories,
    optional: true,
  },
};

const formValidationShape: Record<FormFieldsNames, AnySchema> = {
  [FormFieldsNames.MarketName]: yup.string().default('').nullable().required(requiredField),
  [FormFieldsNames.Memo]: yup.string().default(''),
  [FormFieldsNames.RiskAppetite]: yup.string().default(''),
  [FormFieldsNames.Contacts]: yup
    .array()
    .of(
      yup.object().shape({
        [FormFieldsContactArrayNames.FirstName]: yup
          .string()
          .trim()
          .when('role', {
            is: ContactRole.SubmissionBox,
            otherwise: (s) => s.required(requiredField),
          }),
        [FormFieldsContactArrayNames.LastName]: yup
          .string()
          .trim()
          .when('role', {
            is: ContactRole.SubmissionBox,
            otherwise: (s) => s.required(requiredField),
          }),
        [FormFieldsContactArrayNames.Email]: yup.string().trim().email(invalidEmail).required(requiredField),
        [FormFieldsContactArrayNames.PhoneNumber]: yup.string().default(null).trim().nullable(),
        [FormFieldsContactArrayNames.Title]: yup.string().default(null).trim().nullable(),
        [FormFieldsContactArrayNames.Role]: yup.string().required(requiredField),
      }),
    )
    .test('uniqueEmail', uniqueEmail, (value) => {
      let errorIndex = -1;

      value?.forEach((contact, index) => {
        if (errorIndex === -1) {
          errorIndex = value.findIndex(
            (innerContact, innerIndex) =>
              contact[FormFieldsContactArrayNames.Email] === innerContact[FormFieldsContactArrayNames.Email] &&
              index !== innerIndex,
          );
        }
      });

      if (errorIndex !== -1) {
        throw new yup.ValidationError(
          uniqueEmail,
          '',
          `${FormFieldsNames.Contacts}[${errorIndex}].${FormFieldsContactArrayNames.Email}`,
        );
      }

      return true;
    }),
  [FormFieldsNames.Categories]: yup.array().default([]),
};

export const schema = yup.object().shape(formValidationShape).required();
