import { BoxItem } from 'box-ui-elements/es';
import { compact, isEmpty } from 'lodash';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { BoxTemplateLabels, FileType, FormMode, PromptResultFeature, QuoteFileIdType } from 'enums';
import { useMutatePromptResult, useToast } from 'hooks';
import { useMutateBoxItems, useSearchBoxItems } from 'hooks/api/box';
import { messages } from 'i18n';
import { dialogTransitionDurationValue } from 'themes';
import { Layer, Quote, SubmissionMarket, UserMarket } from 'types';
import { addFileMarketTypeLabel, attachmentPointText, getFileType, isMarketIdInFileMarketTypeLabel } from 'utils';
import { asyncHandleSubmit } from 'utils/form-hooks-utils';
import { QuoteFormCreate } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/form-methods/types';
import { useSendPromptReport } from 'broker/utils/use-send-prompt-report';
import { useGenerateAndUploadFlowPDF } from './formSteps/FlowQuote/pdf-creator/flow-quote-pdf-creator-util';
import { QuoteFormProps } from './types';

interface QuoteDataToSendProps {
  data: QuoteFormCreate;
  userMarket: UserMarket;
  layer: Layer;
  hideToast?: boolean;
  saveForLaterFlowQuoteId?: string;
  submissionMarket: SubmissionMarket;
}

export interface UseSubmitQuoteFormProps extends QuoteFormProps {
  userMarkets: UserMarket[];
}

export default function useSubmitQuoteForm({
  submission,
  marketQuoteFileIds,
  initialProductId,
  submissionMarketRequestId,
  isDirty,
  mode,
  fileIds,
  flowQuoteIds,
  selectedQuote,
  userMarkets,
  products,
  submissionMarkets,
  layers,
  createQuoteApi,
  updateQuoteApi,
  createLayerApi,
  addSubmissionMarketApi,
  updateSubmissionMarketRequestApi,
  setIsDirty,
  fileType,
  insightPopulatedByAI,
  suggestedValueProps,
  stateCompliance,
  shouldShowQuoteCoverLetter,
}: UseSubmitQuoteFormProps) {
  const { copyFile, addFileMetadata, updateFileMetadata } = useMutateBoxItems();
  const { items: boxItems } = useSearchBoxItems({
    filter: { folderId: submission?.boxFolderId || '' },
  });
  const { showDelayedToast } = useToast();
  const sendPromptReport = useSendPromptReport();
  const quoteMethods = useFormContext();
  const { createPromptResult } = useMutatePromptResult();
  const [quoteSubmitting, setQuoteSubmitting] = useState(false);
  const generateAndUploadFlowPDF = useGenerateAndUploadFlowPDF();

  const getFormatDataToSend = ({ data, layer, saveForLaterFlowQuoteId }: QuoteDataToSendProps): Partial<Quote> => {
    const dataToSend: Partial<Quote> = {
      expiresAt: isEmpty(data.expiresAt) ? null : data.expiresAt,
      layerId: layer.id,
      submissionMarketId: data.submissionMarketId,
      insuranceProductId: data.insuranceProductId,
      premium: Number(data.premium),
      limit: data.limit ? Number(data.limit) : null,
      agentCommission: data.agentCommission ? Number(data.agentCommission) : null,
      // @ts-ignore
      ...(data?.primaryQuoteId && { primaryQuoteId: data.primaryQuoteId }),
      comments: data.comments,
      insights: data.insights,
      date: isEmpty(data.date) ? null : data.date,
      subLimits: data.subLimits,
      triaPremium: data.triaPremium ? Number(data.triaPremium) : null,
      carrierFee: data.carrierFee ? Number(data.carrierFee) : null,
      flowBrokerFee: data.flowBrokerFee ? Number(data.flowBrokerFee) : null,
      surplusLineTax: data.surplusLineTax ? Number(data.surplusLineTax) : null,
      surplusStampingFee: data.surplusStampingFee ? Number(data.surplusStampingFee) : null,
      // map back to array of strings
      subjectivities: {
        carrierSpecific: data.subjectivities?.map((subjectivity) => subjectivity.value) || [],
        stateCompliance: data.stateCompliance,
      },
      coverageLines: data.coverageLines,
    };
    if (fileType === QuoteFileIdType.MarketQuote) {
      dataToSend.marketQuoteFileIds = fileIds;
      dataToSend.capitolaQuoteFileIds = saveForLaterFlowQuoteId ? [saveForLaterFlowQuoteId] : flowQuoteIds;
    }
    return dataToSend;
  };

  const updateStateComplianceFiles = async (submissionMarket: SubmissionMarket) => {
    if (shouldShowQuoteCoverLetter && stateCompliance?.forms) {
      const stateComplianceFileType = FileType.StateCompliance;
      let submissionStateComplianceBoxItems = boxItems.filter(
        (boxItem) => getFileType(boxItem) === stateComplianceFileType,
      );
      const submissionHasStateComplianceDocuments = submissionStateComplianceBoxItems.length === 0;

      if (submissionHasStateComplianceDocuments) {
        submissionStateComplianceBoxItems = compact(
          await Promise.all(
            [...(stateCompliance.forms.stateSpecific || []), ...(stateCompliance.forms.diligentEffort || [])].map(
              (form) => copyFile.mutateAsync({ boxItemId: form, folderId: submission.boxFolderId }),
            ),
          ),
        );
      }

      if (submissionHasStateComplianceDocuments) {
        // add new files to be labeled as state compliance with the marketId label
        submissionStateComplianceBoxItems.map((boxItem) =>
          addFileMetadata.mutateAsync({
            fileId: boxItem.id,
            metadata: {
              [BoxTemplateLabels.MarketId]: addFileMarketTypeLabel(boxItem, submissionMarket.id),
              [BoxTemplateLabels.FileType]: stateComplianceFileType,
            },
          }),
        );
      } else {
        // update existing files labeled as state compliance with the marketId label
        await Promise.all(
          submissionStateComplianceBoxItems
            .filter((boxItem) => !isMarketIdInFileMarketTypeLabel(boxItem, submissionMarket.id))
            .map((boxItem) =>
              updateFileMetadata.mutateAsync({
                fileId: boxItem.id,
                metadata: {
                  [BoxTemplateLabels.MarketId]: addFileMarketTypeLabel(boxItem, submissionMarket.id),
                  [BoxTemplateLabels.FileType]: stateComplianceFileType,
                },
              }),
            ),
        );
      }
    }
  };

  const createQuote = async (quoteDataToSendProps: QuoteDataToSendProps): Promise<string | undefined> => {
    const { hideToast, userMarket, layer } = quoteDataToSendProps;
    const formatData = getFormatDataToSend(quoteDataToSendProps);
    const quote = await createQuoteApi?.(formatData);

    if (!hideToast) {
      // noinspection ES6MissingAwait
      showDelayedToast(
        'success',
        {
          message: messages.addQuotePage.addQuoteToastMessage(
            attachmentPointText(layer.attachmentPoint),
            userMarket.marketName,
          ),
        },
        dialogTransitionDurationValue,
      );
    }

    // don't wait for creating state compliance files
    updateStateComplianceFiles(quoteDataToSendProps.submissionMarket);

    return quote;
  };

  const updateQuote = async (quoteDataToSendProps: QuoteDataToSendProps) => {
    if (updateQuoteApi) {
      const formatData = getFormatDataToSend(quoteDataToSendProps);
      const quote = await updateQuoteApi(selectedQuote!.id!, formatData);

      const { userMarket } = quoteDataToSendProps;
      // noinspection ES6MissingAwait
      showDelayedToast(
        'success',
        { message: messages.addQuotePage.updateQuoteToastMessage(userMarket.marketName) },
        dialogTransitionDurationValue,
      );
      return quote;
    }
    return undefined;
  };

  const createQuoteInNewLayer = async (quoteDataToSendProps: Omit<QuoteDataToSendProps, 'layer'>) => {
    const { data } = quoteDataToSendProps;
    const newLayer = await createLayerApi?.(submission.id, Number(data.attachmentPoint));
    if (newLayer) {
      if (mode === FormMode.edit) {
        return updateQuote({ ...quoteDataToSendProps, layer: newLayer });
      }
      return createQuote({ ...quoteDataToSendProps, layer: newLayer });
    }
    return undefined;
  };

  const sendApiRequests = async (
    quoteFieldsState: QuoteFormCreate,
    saveForLaterFlowQuoteId?: string,
  ): Promise<string | undefined> => {
    const product = products.find((productItem) => productItem.id === quoteFieldsState.insuranceProductId);
    const userMarket = userMarkets.find((userMarketItem) => userMarketItem.marketId === product?.marketId)!;
    // search for submission market by userMarketId
    let submissionMarket = submissionMarkets.find(
      (submissionMarketItem) => submissionMarketItem.userMarketId === userMarket.id,
    );

    if (!submissionMarket) {
      // addSubmissionMarketApi is not send only when form is disabled
      const response = await addSubmissionMarketApi!(userMarket, quoteFieldsState.insuranceProductId);
      submissionMarket = response!;
    }

    // If the selected product is different from the one that was initially set, we need to update the market request first
    if (!!submissionMarketRequestId && !!initialProductId && initialProductId !== quoteFieldsState.insuranceProductId) {
      await updateSubmissionMarketRequestApi?.(submissionMarketRequestId, {
        insuranceProductId: quoteFieldsState.insuranceProductId,
      });
    }

    const ownQuoteFormProps: QuoteFormCreate = {
      ...quoteFieldsState,
      submissionMarketId: submissionMarket.id,
    };

    const layer = layers.find((layerItem) => layerItem.id === quoteMethods.getValues().attachmentPoint);

    if (layer) {
      // Capitola quote is added in a "view" mode so that all the quote fields are disabled.
      if (mode === FormMode.edit || mode === FormMode.view) {
        return updateQuote({
          data: ownQuoteFormProps,
          userMarket,
          layer,
          saveForLaterFlowQuoteId,
          submissionMarket,
        });
      }
      return createQuote({
        data: ownQuoteFormProps,
        userMarket,
        layer,
        saveForLaterFlowQuoteId,
        submissionMarket,
      });
    }
    return createQuoteInNewLayer({
      data: ownQuoteFormProps,
      userMarket,
      saveForLaterFlowQuoteId,
      submissionMarket,
    });
  };

  const submitForm = async (generateFlowQuote = false): Promise<string | undefined> => {
    let flowQuoteBoxItem: BoxItem | undefined | null;
    setQuoteSubmitting(true);
    try {
      if (generateFlowQuote) {
        if (mode === FormMode.create || (mode === FormMode.edit && (isDirty || isEmpty(flowQuoteIds)))) {
          const quoteFormValue = quoteMethods.getValues() as QuoteFormCreate;
          const marketName =
            products.find((product) => product.id === quoteFormValue.insuranceProductId)?.marketName ?? '';

          flowQuoteBoxItem = await generateAndUploadFlowPDF(submission, quoteFormValue, marketQuoteFileIds, marketName);
        }
      }
      const quoteFieldsState = (await asyncHandleSubmit(quoteMethods)) as QuoteFormCreate;
      setIsDirty?.(false);
      const quoteId = await sendApiRequests(quoteFieldsState, flowQuoteBoxItem?.id);
      //
      if (insightPopulatedByAI) {
        // run api in the background to create a prompt result (no need to wait for it)
        createPromptResult.mutateAsync({
          data: {
            result: insightPopulatedByAI.result,
            input: insightPopulatedByAI.input,
            modifiedResult: quoteFieldsState.insights || '',
            feature: PromptResultFeature.QuoteInsight,
            submissionId: submission.id,
          },
        });
      }
      if (quoteId && suggestedValueProps) {
        await sendPromptReport({
          formState: quoteFieldsState,
          suggestedValueProps,
          submissionId: submission.id,
          entityId: quoteId,
        });
      }
      return quoteId;
    } catch (e) {
      setQuoteSubmitting(false);
      setIsDirty?.(true);

      return undefined;
    }
  };

  return {
    submitForm,
    quoteSubmitting,
  };
}
