import { applySpecialLinesOfBusinessCoverageLinesRules } from '@common/utils';
import { isEmpty } from 'lodash';
import { useEffect, useMemo } from 'react';
import { CoverageLine } from 'enums';
import { coverageLineConfig, FilterElementProps } from 'utils';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import useProductFilter from './useProductFilter';

interface useCoverageLineFilterProps {
  paginationReset: () => void;
}

export function useCoverageLineFilter({ paginationReset }: useCoverageLineFilterProps) {
  const { partialSubmission } = useSubmissionsWorkspace();

  const preSelectedCoverageLines = useMemo(() => {
    const coverageLines = partialSubmission?.coverageLines ? [...partialSubmission.coverageLines] : [];
    return applySpecialLinesOfBusinessCoverageLinesRules(coverageLines);
  }, [partialSubmission]);

  const baseCoverageLineFiltersList: FilterElementProps[] = Object.keys(coverageLineConfig)
    .sort((a, b) =>
      coverageLineConfig[a as CoverageLine].text.localeCompare(coverageLineConfig[b as CoverageLine].text),
    )
    .map((coverageLine) => ({
      key: coverageLine,
      checked: false,
      label: coverageLineConfig[coverageLine as CoverageLine].text,
    }));

  const coverageLineFilter = useProductFilter({
    baseFiltersList: baseCoverageLineFiltersList,
    defaultSelectAll: isEmpty(preSelectedCoverageLines),
    paginationReset,
  });

  const setCoverageLineFilters = coverageLineFilter.setFilters;

  useEffect(() => {
    const coverageLineSetter = (coverageLinesList: string[]) => {
      setCoverageLineFilters(() =>
        coverageLinesList.map((coverageLine) => ({
          key: coverageLine,
          checked: preSelectedCoverageLines?.includes(coverageLine as CoverageLine) ?? false,
          label: coverageLineConfig[coverageLine as CoverageLine].text,
        })),
      );
    };

    const coverageLinesList = Object.keys(coverageLineConfig).sort((a, b) =>
      coverageLineConfig[a as CoverageLine].text.localeCompare(coverageLineConfig[b as CoverageLine].text),
    );

    coverageLineSetter(coverageLinesList);
  }, [preSelectedCoverageLines, setCoverageLineFilters]);

  return { coverageLineFilter, preSelectedCoverageLines };
}
