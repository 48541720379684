import { keyBy } from 'lodash';
import { useMemo, useState } from 'react';
import { Checkbox, FormControlLabel, Stack, TextField, Theme, Typography } from '@common-components';
import { DeclinationType, SubmissionMarketRequestStatus } from 'enums';
import { useBoolean, useToast } from 'hooks';
import { messages } from 'i18n';
import { DeclinationReason } from 'types';
import { declinationConfig, DeclinationMetadata } from 'broker/configuration-mappers/declination-config';
import InnerPageLayout from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/InnerPageLayout';
import { InnerPageProps, innerPageSpacing } from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/types';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { DeclinationLocationState } from './types';

interface ContentProps {
  locationState: DeclinationLocationState;
}
export default function Content({ locationState: { selectedMarket, marketRequestId } }: ContentProps) {
  const initialMarketRequest = selectedMarket.marketRequests.find(
    (marketRequest) => marketRequest.id === marketRequestId,
  );
  const { updateSubmissionMarketRequest } = useSubmissionsWorkspace();
  const [isLoading, { on: startLoading, off: stopLoading }] = useBoolean();
  const [selectedDeclinationTypes, setSelectedDeclinationTypes] = useState<DeclinationType[]>(
    initialMarketRequest?.declination?.declinationReasons.map((declinationReason) => declinationReason.type) || [],
  );
  const [declinationDetailsByType, setDeclinationDetailsByType] = useState<{ [type in DeclinationType]?: string }>(
    initialMarketRequest?.declination?.declinationReasons.reduce(
      (prev, current) => ({
        ...prev,
        [current.type]: current.details,
      }),
      {},
    ) || {},
  );

  const { submission } = useSubmissionsWorkspace();
  const declinationOptions = useMemo(() => keyBy(declinationConfig, (entry) => entry.declinationType), []);

  const { showToast } = useToast();
  const handleUpdateButtonClick = async ({ onClose, setIsDirty }: InnerPageProps) => {
    startLoading();

    setIsDirty(false);
    await updateSubmissionMarketRequest(marketRequestId, {
      declination: {
        declinationReasons: selectedDeclinationTypes.reduce((current, type) => {
          current.push({ type, details: declinationDetailsByType[type] || '' });
          return current;
        }, [] as DeclinationReason[]),
        isAIExtracted: false,
      },
      status: SubmissionMarketRequestStatus.Declined,
    });
    showToast('success', {
      message: messages.declinationPage.successMessage(selectedMarket.marketName, submission!.insuredName),
    });
    onClose();
    stopLoading();
  };

  const onDeclinationOptionChanged = (option: DeclinationMetadata, setIsDirty: (isDirty: boolean) => void) => {
    setIsDirty(true);
    setSelectedDeclinationTypes((prev) => {
      const updateData = [...prev];
      if (!updateData.includes(option.declinationType)) {
        updateData.push(option.declinationType);
      } else {
        updateData.splice(updateData.indexOf(option.declinationType), 1);
      }
      return updateData;
    });
  };

  return (
    <InnerPageLayout
      className="cap-declination"
      title={messages.declinationPage.title(selectedMarket.marketName)}
      subTitle={messages.declinationPage.subtitle}
      footerButtons={{
        proceedButton: (innerPageProps: InnerPageProps) => ({
          children: messages.buttons.update,
          onClick: () => handleUpdateButtonClick(innerPageProps),
          loading: isLoading,
          disabled: !selectedDeclinationTypes.length,
        }),
      }}
    >
      {({ setIsDirty }: InnerPageProps) => (
        <>
          <Typography component="div" variant="body1Bold" mb={2}>
            {`${messages.declinationPage.declinationContentTitle}`}
          </Typography>

          {Object.values(declinationOptions).map((option) => {
            const isSelected = selectedDeclinationTypes.includes(option.declinationType);

            return (
              <Stack
                mx={-innerPageSpacing}
                key={option.declinationType}
                mb={1}
                gap={2}
                bgcolor={isSelected ? 'blue.50' : 'inherit'}
              >
                <FormControlLabel
                  sx={{ px: innerPageSpacing }}
                  value={option.declinationType}
                  control={
                    <Checkbox
                      checked={isSelected}
                      onChange={() => {
                        onDeclinationOptionChanged(option, setIsDirty);
                      }}
                    />
                  }
                  label={option.reason}
                />
                {isSelected && (
                  <TextField
                    placeholder={declinationOptions[option.declinationType].placeholder}
                    multiline
                    minRows={5}
                    maxRows={8}
                    value={declinationDetailsByType[option.declinationType]}
                    onChange={(event) =>
                      setDeclinationDetailsByType((prev) => {
                        const { value } = event.target;
                        return { ...prev, [option.declinationType]: value };
                      })
                    }
                    sx={{ px: innerPageSpacing, height: (theme: Theme) => theme.spacing(24) }}
                  />
                )}
              </Stack>
            );
          })}
        </>
      )}
    </InnerPageLayout>
  );
}
