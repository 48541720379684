import { Transition } from 'history';
import { compact, sortBy, uniq } from 'lodash';
import { useMemo, useRef, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { Stack } from '@common-components';
import { SubmissionTask, SubmissionTaskStatus } from 'enums';
import { useFormProvider, useSearchInsured, useToast } from 'hooks';
import { messages } from 'i18n';
import { PartialSubmission } from 'types';
import { scrollToError } from 'utils';
import { BROKER_NESTED_ROUTES } from 'broker/broker-routes';
import FormWithStepper from 'broker/components/common/FormWithStepper';
import { convertIndustryObjectToOption } from 'broker/components/common/IndustryAutoComplete/utils';
import ScrollFormIndicator from 'broker/components/common/ScrollFormIndicator';
import SubmissionFooter from 'broker/components/common/SubmissionFooter';
import { DirtyRouteGuard } from 'broker/components/DirtyContentGuard';
import { useScrollButtonVisibility } from 'broker/hooks';
import { AIReason } from 'broker/pages/SubmissionWorkspacePage/components/AIGeneratedContent/AIReason';
import InnerPageLayout from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/InnerPageLayout';
import CoverageLinesChips from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/EditSubmissionNew/components/CoverageLinesChips';
import { SetNextStepsProps } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/EditSubmissionNew/useSetNextStep';
import { useGetMainAreaDimensionsState, useIsAcknowledgmentFlow } from 'broker/pages/SubmissionWorkspacePage/hooks';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { useSendPromptReport } from 'broker/utils/use-send-prompt-report';
import {
  AfterSubmitFormOptions,
  formFieldsConfig,
  schema,
  StaticForm,
  StaticFormSection,
  SubmissionFormData,
  SubmissionFormFieldsNames,
  useSubmissionFormHandler,
} from './StaticForm';
import { useGetStaticSubmissionExtractedData } from './StaticForm/useGetStaticSubmissionExtractedData';
import { staticSubmissionSectionConfig } from './StaticForm/utils/static-submission-section-config';

interface SubmissionStaticFormProps {
  submission: PartialSubmission;
  setNextStep: (props?: SetNextStepsProps) => void;
  firstVisit: boolean;
}

export default function SubmissionStaticForm({ submission, setNextStep, firstVisit }: SubmissionStaticFormProps) {
  const [isDirty, setIsDirty] = useState(false);
  const mainAreaDimensionsState = useGetMainAreaDimensionsState();
  const { isAcknowledgmentFlow } = useIsAcknowledgmentFlow();
  const sendPromptReport = useSendPromptReport();
  const { items: insuredList, isLoading: insuredListLoading } = useSearchInsured();

  const { reFetchWorkspace, updateSubmissionTasks } = useSubmissionsWorkspace();

  const defaultValues = useMemo(() => {
    const getDefaultValues = (submissionData: PartialSubmission): SubmissionFormData => ({
      insuredName: submissionData.insuredName || '',
      insuredId: submissionData.insuredId,
      industry: submissionData.industry ? convertIndustryObjectToOption(submissionData.industry) : undefined,
      insuredCompanyType: submissionData.insuredCompanyType,
      customers: submissionData.contacts?.map((contact) => contact.id),
      insuredPrimaryAddress: submissionData.insuredPrimaryAddress,
      insuredMailingAddress: submissionData.insuredMailingAddress,
      limit: submissionData.limit?.toString() || '',
      dueDate: submissionData.dueDate,
      needByDate: submissionData.needByDate,
      insuredAnnualRevenue: submissionData.insuredAnnualRevenue?.toString(),
      insuredEmployeeCount: submissionData.insuredEmployeeCount?.toString(),
      insuredWebsite: submissionData.insuredWebsite,
    });

    return submission ? getDefaultValues(submission) : undefined;
  }, [submission]);
  const extractedData = useGetStaticSubmissionExtractedData({
    submissionExtractedData: submission.submissionExtractedData,
    insuredListLoading,
    insuredList,
  });
  const { showToast } = useToast();
  const scrollableDivRef = useRef<HTMLDivElement | null>(null); // Ensure correct type

  const afterSubmitForm = async ({ isAllRequiredFieldsFilled }: AfterSubmitFormOptions) => {
    if (isAllRequiredFieldsFilled) {
      await updateSubmissionTasks({
        submissionId: submission.id,
        status: SubmissionTaskStatus.Done,
        task: SubmissionTask.ReviewSubmission,
      });
    }
    await reFetchWorkspace();

    if (!isAcknowledgmentFlow) {
      showToast('success', { message: messages.submissionModal.submissionUpdated });
    }
  };

  const { methods, appliedSuggestions } = useFormProvider<SubmissionFormData>({
    schema,
    defaultValues,
    setIsDirty,
    suggestedValues: extractedData,
    applySuggestionsOnEmptyFields: firstVisit,
  });

  const sectionsWithErrors = useMemo(
    () =>
      uniq(
        compact(
          Object.keys(methods.formState.errors).map(
            (key) => formFieldsConfig[key as SubmissionFormFieldsNames]?.section,
          ),
        ),
      ),
    [methods.formState],
  );

  const suggestions = useMemo(
    () =>
      Object.keys(appliedSuggestions).reduce(
        (acc, key) => ({
          ...acc,
          [key]: {
            ...appliedSuggestions[key],
            reason: <AIReason suggestion={appliedSuggestions[key]} />,
          },
        }),
        {},
      ),
    [appliedSuggestions],
  );

  const sectionList = useMemo(
    () =>
      sortBy(
        Object.keys(staticSubmissionSectionConfig).map((key) => ({
          ...staticSubmissionSectionConfig[key as StaticFormSection],
          id: key as StaticFormSection,
          hasError: sectionsWithErrors.includes(key as StaticFormSection),
        })),
        'priority',
      ),
    [sectionsWithErrors],
  );

  const { isSubmitting, handleSubmitSubmission } = useSubmissionFormHandler({
    setDirty: setIsDirty,
    existingSubmission: submission,
    afterSubmitForm,
    methods,
  });

  const beforeNavigation = async (transition: Transition) =>
    transition.location.pathname.includes(BROKER_NESTED_ROUTES.WORKSPACE.EDIT_SUBMISSION);

  const [activeSection, setActiveSection] = useState<string | undefined>(); // Update type to string
  const sectionRefs = useRef<Record<string, HTMLDivElement | null>>({});
  const { showScrollButton, handleScrollDown } = useScrollButtonVisibility({ ref: scrollableDivRef, sectionRefs });

  const onSetActiveSection = (section?: string) => {
    setActiveSection(section);
    if (section && sectionRefs.current[section]) {
      sectionRefs.current[section]?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const titleText = () => {
    if (isAcknowledgmentFlow) {
      return messages.acknowledgmentFlow.title;
    }
    return messages.editSubmission.formTitle;
  };

  return (
    <FormProvider {...methods}>
      <DirtyRouteGuard isDirty={isDirty} beforeNavigation={beforeNavigation}>
        <InnerPageLayout
          title={titleText()}
          className="submission-static-form"
          sx={{ px: 3, py: 1 }}
          hideBackButton={isAcknowledgmentFlow}
        >
          {() => (
            <Stack width={1} gap={3} height={1}>
              <CoverageLinesChips coverageLines={submission.coverageLines!} setNextStep={setNextStep} />
              <FormWithStepper
                ref={scrollableDivRef}
                mainAreaDimensionsState={mainAreaDimensionsState}
                sectionProps={{
                  sectionList,
                  activeSection,
                  setActiveSection: onSetActiveSection,
                }}
              >
                <StaticForm
                  scrollableDivRef={scrollableDivRef}
                  activeSection={activeSection}
                  appliedSuggestions={suggestions}
                  insuredList={insuredList}
                  setActiveSection={setActiveSection}
                  sectionRefs={sectionRefs}
                />
                <SubmissionFooter
                  isAcknowledgmentFlow={isAcknowledgmentFlow}
                  isSubmitting={isSubmitting}
                  active={activeSection} // Pass the updated active section
                  submitButtonText={messages.general.nextAddRiskAssessment}
                  list={sectionList.map((item) => item.id)}
                  onSubmit={() => {
                    methods.handleSubmit(
                      async (data: SubmissionFormData) => {
                        const response = await handleSubmitSubmission(data);
                        if (response) {
                          if (extractedData) {
                            sendPromptReport({
                              submissionId: submission.id,
                              formState: data,
                              suggestedValueProps: extractedData,
                              entityId: submission.id,
                            });
                          }
                          setNextStep();
                        }
                      },
                      (e) => {
                        scrollToError(e);
                      },
                    )();
                  }}
                />
              </FormWithStepper>
              {showScrollButton && <ScrollFormIndicator handleScrollDown={handleScrollDown} />}
            </Stack>
          )}
        </InnerPageLayout>
      </DirtyRouteGuard>
    </FormProvider>
  );
}
