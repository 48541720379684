import { Address, AddressDetails } from '@common/types';
import { compact, includes } from 'lodash';
import { StateCode } from 'enums';

export function formatAddress(address: Omit<AddressDetails, 'formattedAddress'>): string {
  const formatAddressArr = [
    address.line1,
    address.line2,
    address.city,
    `${address.state || ''}${address.postalCode ? ` ${address.postalCode}` : ''}`,
    address.country,
  ];
  return compact(formatAddressArr).join(', ');
}

export function convertAddressToAddressDetails(address: Address): AddressDetails {
  const structuredAddress = {
    line1: address.line1,
    line2: address.line2,
    city: address.city,
    state: includes(Object.values(StateCode), address.state) ? (address.state as StateCode) : undefined,
    postalCode: address.postal_code,
    country: address.country_code,
  };
  return {
    ...structuredAddress,
    formattedAddress: formatAddress(structuredAddress),
  };
}
