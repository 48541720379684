import { Address as ExtractedAddress, ExtractedAttribute } from '@common/types';
import { useMemo } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { useFormContext } from 'react-hook-form';
import { Stack, Typography } from '@common-components';
import { messages } from 'i18n';
import { mapGoogleMapResultToStructuredAddress } from 'utils';
import FormAutocomplete from 'components/hookFormComponents/FormAutocomplete/FormAutocomplete';
import FormTextField from 'components/hookFormComponents/FormTextField';
import { SuggestionProps } from 'components/hookFormComponents/types';
import {
  FormStateBaseProp,
  HeraldNormalizedParameter,
} from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/DynamicForm/types';
import {
  allAddressQuestionIds,
  buildDynamicFormAddressValue,
} from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/DynamicForm/utils/address-utils';
import { buildFieldName } from './utils/utils';

interface AddressProps {
  heraldParameter: HeraldNormalizedParameter;
  fieldNamePrefix: string;
  onBlur: () => boolean | undefined;
  hideBorder: boolean;
  optional?: boolean;
  suggestion?: SuggestionProps;
}

export default function Address({
  fieldNamePrefix,
  heraldParameter,
  onBlur,
  hideBorder = false,
  optional,
  suggestion,
}: AddressProps) {
  const fields = heraldParameter.schema.properties!;
  const { setValue, watch, trigger, getValues } = useFormContext();
  const watchValue = watch(fieldNamePrefix);
  const suggestionValue = suggestion as ExtractedAttribute<ExtractedAddress> | undefined;
  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    options: {
      types: ['address'],
    },
    language: 'en',
    onPlaceSelected: async (place) => {
      const mappedAddress = mapGoogleMapResultToStructuredAddress(place);
      const mainValue = {
        ...watchValue.main,
        ...buildDynamicFormAddressValue(mappedAddress),
      };

      setValue(fieldNamePrefix, {
        ...watchValue,
        main: mainValue,
      });

      const formValues = getValues();

      allAddressQuestionIds.forEach((questionId) => {
        const isArrayField = Array.isArray(formValues[questionId]);
        const valueItem = isArrayField ? formValues[questionId][0]?.main?.line1 : formValues[questionId]?.main?.line1;
        if (!questionId.includes(fieldNamePrefix) && !valueItem) {
          setValue(`${questionId}${isArrayField ? '.0' : ''}.${FormStateBaseProp.Main}`, mainValue);
        }
      });
      await trigger();
      // send form after google auto-complete chosen
      onBlur();
    },
  });

  const schemaRequired = heraldParameter.schema.required!;

  const ownBuildName = (fieldName: string) =>
    buildFieldName(
      `${fieldNamePrefix}.${FormStateBaseProp.Main}`,
      fieldName,
      `${fields[fieldName]!.title}`,
      heraldParameter,
    );

  const line1Suggestion = useMemo(
    () =>
      suggestionValue?.value?.line1 ? { value: suggestionValue.value.line1, reason: suggestion?.reason } : undefined,
    [suggestion?.reason, suggestionValue?.value?.line1],
  );

  const line2Suggestion = useMemo(
    () =>
      suggestionValue?.value?.line2 ? { value: suggestionValue.value.line2, reason: suggestion?.reason } : undefined,
    [suggestion?.reason, suggestionValue?.value?.line2],
  );

  const line3Suggestion = useMemo(
    () =>
      suggestionValue?.value?.line3 ? { value: suggestionValue.value.line3, reason: suggestion?.reason } : undefined,
    [suggestion?.reason, suggestionValue?.value?.line3],
  );

  const stateSuggestion = useMemo(
    () =>
      suggestionValue?.value?.state ? { value: suggestionValue.value.state, reason: suggestion?.reason } : undefined,
    [suggestion?.reason, suggestionValue?.value?.state],
  );

  const citySuggestion = useMemo(
    () =>
      suggestionValue?.value?.city ? { value: suggestionValue.value.city, reason: suggestion?.reason } : undefined,
    [suggestion?.reason, suggestionValue?.value?.city],
  );

  const postalSuggestion = useMemo(
    () =>
      suggestionValue?.value?.postal_code
        ? { value: suggestionValue.value.postal_code, reason: suggestion?.reason }
        : undefined,
    [suggestion?.reason, suggestionValue?.value?.postal_code],
  );

  const countryCodeSuggestion = useMemo(
    () =>
      suggestionValue?.value?.country_code
        ? { value: suggestionValue.value.country_code, reason: suggestion?.reason }
        : undefined,
    [suggestion?.reason, suggestionValue?.value?.country_code],
  );

  const organizationSuggestion = useMemo(
    () =>
      suggestionValue?.value?.organization
        ? { value: suggestionValue.value.organization, reason: suggestion?.reason }
        : undefined,
    [suggestion?.reason, suggestionValue?.value?.organization],
  );

  return (
    <Stack gap={1} mb={2}>
      <Stack direction="row" justifyContent="space-between">
        <Typography mb={1} variant="body2Bold" textTransform="capitalize">
          {heraldParameter.fieldLabel}
        </Typography>
        {optional && (
          <Typography variant="body2" color="textSecondary" mr={3}>
            {messages.general.optional}
          </Typography>
        )}
      </Stack>

      <Stack
        sx={
          !hideBorder
            ? {
                border: 1,
                borderColor: 'divider',
                borderRadius: 2,
              }
            : undefined
        }
        p={hideBorder ? 0 : 2}
        pb={0}
      >
        <FormTextField
          // onBlur should not be called as it will be called by the google auto-complete (if added it will be called twice and add bugs like scrolling back to the top and not saving last scroll position)
          {...ownBuildName('line1')}
          inputProps={{
            ref,
          }}
          suggestion={line1Suggestion}
          enhancedRequired
          optional={!heraldParameter.schema.required?.includes('line1')}
        />
        <FormTextField
          {...ownBuildName('line2')}
          suggestion={line2Suggestion}
          enhancedRequired
          optional={!heraldParameter.schema.required?.includes('line2')}
        />
        {schemaRequired.includes('line3') && (
          <FormTextField
            {...ownBuildName('line3')}
            onBlur={onBlur}
            suggestion={line3Suggestion}
            enhancedRequired
            optional={!heraldParameter.schema.required?.includes('line3')}
          />
        )}
        {schemaRequired.includes('organization') && (
          <FormTextField
            suggestion={organizationSuggestion}
            {...ownBuildName('organization')}
            onBlur={onBlur}
            enhancedRequired
            optional={!heraldParameter.schema.required?.includes('organization')}
          />
        )}
        <FormTextField
          {...ownBuildName('city')}
          onBlur={onBlur}
          suggestion={citySuggestion}
          enhancedRequired
          optional={!heraldParameter.schema.required?.includes('city')}
        />
        <Stack direction="row" gap={3}>
          <FormAutocomplete
            onBlur={onBlur}
            {...ownBuildName('state')}
            suggestion={stateSuggestion}
            enhancedRequired
            optional={!heraldParameter.schema.required?.includes('state')}
            options={fields.state.enum?.map((item) => ({ label: item, value: item })) || []}
          />
          <FormTextField
            onBlur={onBlur}
            {...ownBuildName('postal_code')}
            isNumberFormat
            numberFormatProps={{
              prefix: '',
              thousandSeparator: false,
              decimalScale: 0,
              allowLeadingZeros: true,
            }}
            suggestion={postalSuggestion}
            enhancedRequired
            optional={!heraldParameter.schema.required?.includes('postal_code')}
          />
        </Stack>

        <FormAutocomplete
          onBlur={onBlur}
          {...ownBuildName('country_code')}
          suggestion={countryCodeSuggestion}
          enhancedRequired
          optional={!heraldParameter.schema.required?.includes('country_code')}
          options={fields.country_code.enum?.map((item) => ({ label: item, value: item })) || []}
        />
      </Stack>
    </Stack>
  );
}
