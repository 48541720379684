import { useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { AIFeature, FileType, FormMode, QuoteFileIdType } from 'enums';
import {
  useBoolean,
  useMatchNavigation,
  useSearchInsuranceProduct,
  useSearchStateComplianceByState,
  useSearchUserMarket,
} from 'hooks';
import { UserMarket } from 'types';
import { getFileType } from 'utils';
import { QuoteFormRoutes } from 'broker/broker-routes';
import InnerPageLayout from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/InnerPageLayout';
import useLoadFilesSelection from 'broker/pages/SubmissionWorkspacePage/components/Toolbox/components/Files/useLoadFilesSelection';
import { AIContextProvider } from 'broker/pages/SubmissionWorkspacePage/contexts/ai/AIContextProvider';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { useUiStoreState } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';
import { useFileWithMetadata } from './components/formSteps/Upload/useFileWithMetadata';
import QuoteFooter from './components/QuoteFooter';
import QuoteFormFields from './components/QuoteFormFields';
import { InsightPopulatedByAI } from './components/types';
import { getQuoteConfig } from './config';
import { DetailsFieldsNames } from './form-methods/enums';
import { useQuoteFormMethods } from './form-methods/useQuoteFormMethods';
import { FlowGenerationStatus, QuoteLocationState } from './types';
import { useGetQuoteData } from './useGetQuoteData';

export interface QuoteContentProps {
  locationState: QuoteLocationState;
  setIsDirty: (isDirty: boolean) => void;
  fileType: QuoteFileIdType;
  onClose(): void;
  resetFilesExplorerSelectionOnUnMount?: boolean;
  isDirty: boolean;
}

export default function QuoteContent({
  locationState,
  isDirty,
  setIsDirty,
  onClose,
  fileType,
  resetFilesExplorerSelectionOnUnMount = true,
}: QuoteContentProps) {
  const {
    layers,
    submission,
    markets,
    quotes,
    addSubmissionMarket,
    createQuote,
    updateQuote,
    createLayer,
    createMarketIssuingCompany,
    updateSubmissionMarketRequest,
  } = useSubmissionsWorkspace();
  const state = locationState;
  const { items: userMarkets, isLoading: userMarketsLoading } = useSearchUserMarket({
    filter: {},
  });
  const { items: products, isLoading: productsLoading } = useSearchInsuranceProduct({
    filter: {},
  });
  // undefined means error
  const [flowGenerationStatus, setFlowGenerationStatus] = useState<FlowGenerationStatus>(FlowGenerationStatus.Idle);
  const {
    filesExplorer: { selectedFiles },
  } = useUiStoreState();
  const [insightPopulatedByAI, setInsightPopulatedByAI] = useState<InsightPopulatedByAI | undefined>();
  const { mode, selectedQuote } = state;
  const { selectedLayer } = useGetQuoteData({
    layer: state?.layer,
    selectedQuote: state?.selectedQuote,
    mode,
  });

  const preSelectedFileIds = useMemo(() => selectedQuote?.[fileType], [fileType, selectedQuote]);
  useLoadFilesSelection({
    setIsDirty,
    preSelectedFileIds: locationState?.quoteFileIds || preSelectedFileIds,
    resetOnUnMount: resetFilesExplorerSelectionOnUnMount,
    viewOnlyMode: mode === FormMode.view,
  });

  const { partialSubmission } = useSubmissionsWorkspace();

  const { items: stateComplianceItems } = useSearchStateComplianceByState(
    partialSubmission?.insuredPrimaryAddress?.state,
    mode === FormMode.create || mode === FormMode.edit,
  );

  const stateCompliance = stateComplianceItems?.[0];

  // get the fileWithExtractionMetadata according to the quote mode (market quote look for the first file with market quote label, policy look for the first file with market policy label)
  const fileWithExtractionMetadata = useFileWithMetadata(fileType, true);
  const currentStep = useMatchNavigation(QuoteFormRoutes, QuoteFormRoutes.Details);

  const quoteConfig = useMemo(
    () => getQuoteConfig({ fileType, formMode: mode, fileWithExtractionMetadata, currentStep, selectedFiles }),
    [fileType, mode, fileWithExtractionMetadata, currentStep, selectedFiles],
  );

  const hasMarketQuoteFileLabel = useMemo(
    () => selectedFiles.some((file) => getFileType(file) === FileType.MarketQuote),
    [selectedFiles],
  );

  // used in the upload step to show alert banner and disable proceed button until there is a file selected with the right label according to the mode quote/binder/policy
  const isRequiredLabelMissing = useMemo(
    () => !selectedFiles.some((file) => getFileType(file) === quoteConfig.requiredFileType),
    [selectedFiles, quoteConfig.requiredFileType],
  );

  const initialProductId = state?.insuranceProductId ?? state?.selectedQuote?.insuranceProduct?.id;
  const initialProduct = products.find((product) => product.id === initialProductId);

  const [isPrimary, { set: setIsPrimary }] = useBoolean(
    !submission || (selectedLayer && !selectedLayer.attachmentPoint),
  );

  const { filesExplorer } = useUiStoreState();

  const [flowQuoteIds, setFlowQuoteIds] = useState<string[]>(selectedQuote?.capitolaQuoteFileIds || []);

  // only once working in marketQuote mode, the filesExplorer.selectedFiles represents the latest selected market quote files
  const marketQuoteFileIds =
    fileType === QuoteFileIdType.MarketQuote
      ? filesExplorer.selectedFiles.map((selectedFile) => selectedFile.id)
      : selectedQuote?.marketQuoteFileIds || [];

  const { methods, appliedSuggestions } = useQuoteFormMethods(
    selectedQuote,
    setIsDirty,
    initialProduct,
    mode,
    quoteConfig.suggestedValueProps,
  );

  const selectedProductId = methods.watch(DetailsFieldsNames.InsuranceProductId);
  const selectedProduct = products.find((product) => product.id === selectedProductId);

  const shouldShowQuoteCoverLetter = selectedProduct?.isAdmitted === false;

  const addSubmissionMarketApi = async (
    addedMarket: Pick<UserMarket, 'id' | 'contacts'>,
    insuranceProductId?: string,
  ) =>
    addSubmissionMarket(
      {
        userMarketId: addedMarket.id,
        contactIds: addedMarket.contacts.map((contact) => contact.id),
        insuranceProductId,
      },
      false,
    );

  const createLayerApi = async (submissionId: string, attachmentPoint: number) =>
    createLayer({
      submissionId,
      attachmentPoint,
    });

  const createMarketIssuingCompanyApi = async (name: string, marketId: string) =>
    createMarketIssuingCompany({ name, marketId });

  if (!submission) {
    return null;
  }

  return (
    <AIContextProvider feature={AIFeature.QuoteGeneration}>
      <FormProvider {...methods}>
        <InnerPageLayout
          className="cap-quote-nested-view"
          title={quoteConfig.messages.title}
          sx={{ p: 0 }}
          subTitle={quoteConfig.messages.topHeaderSubtitle}
          {...(quoteConfig.showFooter && {
            footer: () => (
              <QuoteFooter
                fileType={fileType}
                boxFolderId={submission!.boxFolderId}
                fileIds={filesExplorer.selectedFiles.map((selectedFile) => selectedFile.id)}
                submission={submission!}
                mode={mode!}
                submissionMarkets={markets}
                layers={layers}
                quotes={quotes}
                selectedLayer={selectedLayer!}
                selectedQuote={selectedQuote!}
                addSubmissionMarketApi={addSubmissionMarketApi}
                createLayerApi={createLayerApi}
                updateQuoteApi={updateQuote}
                createQuoteApi={createQuote}
                createMarketIssuingCompanyApi={createMarketIssuingCompanyApi}
                updateSubmissionMarketRequestApi={updateSubmissionMarketRequest}
                onClose={onClose}
                setIsDirty={setIsDirty}
                isPrimary={isPrimary}
                setIsPrimary={setIsPrimary}
                userMarkets={userMarkets}
                userMarketsLoading={userMarketsLoading}
                currentStep={currentStep}
                products={products}
                productsLoading={productsLoading}
                initialProductId={initialProductId}
                submissionMarketRequestId={state.submissionMarketRequestId}
                flowQuoteIds={flowQuoteIds}
                flowGenerationStatus={flowGenerationStatus}
                setFlowGenerationStatus={setFlowGenerationStatus}
                isRequiredLabelMissing={isRequiredLabelMissing}
                hasMarketQuoteFileLabel={hasMarketQuoteFileLabel}
                insightPopulatedByAI={insightPopulatedByAI}
                setInsightPopulatedByAI={setInsightPopulatedByAI}
                marketQuoteFileIds={marketQuoteFileIds}
                isDirty={isDirty}
                suggestedValueProps={quoteConfig.suggestedValueProps}
                quoteConfig={quoteConfig}
                stateCompliance={stateCompliance}
                shouldShowQuoteCoverLetter={shouldShowQuoteCoverLetter}
              />
            ),
          })}
        >
          {() => (
            <QuoteFormFields
              boxFolderId={submission!.boxFolderId}
              submission={submission!}
              mode={mode!}
              submissionMarkets={markets}
              layers={layers}
              quotes={quotes}
              selectedLayer={selectedLayer!}
              selectedQuote={selectedQuote!}
              addSubmissionMarketApi={addSubmissionMarketApi}
              createLayerApi={createLayerApi}
              updateQuoteApi={updateQuote}
              createQuoteApi={createQuote}
              setIsDirty={setIsDirty}
              isPrimary={isPrimary}
              setIsPrimary={setIsPrimary}
              userMarkets={userMarkets}
              userMarketsLoading={userMarketsLoading}
              fileType={fileType}
              currentStep={currentStep}
              products={products}
              productsLoading={productsLoading}
              initialProductId={initialProductId}
              marketQuoteFileIds={marketQuoteFileIds}
              flowQuoteIds={flowQuoteIds}
              setFlowQuoteIds={setFlowQuoteIds}
              flowGenerationStatus={flowGenerationStatus}
              setFlowGenerationStatus={setFlowGenerationStatus}
              isRequiredLabelMissing={isRequiredLabelMissing}
              hasMarketQuoteFileLabel={hasMarketQuoteFileLabel}
              extractedDataSuggestions={appliedSuggestions}
              setInsightPopulatedByAI={setInsightPopulatedByAI}
              isDirty={isDirty}
              quoteConfig={quoteConfig}
              stateCompliance={stateCompliance}
              shouldShowQuoteCoverLetter={shouldShowQuoteCoverLetter}
              marketName={selectedProduct?.marketName ?? ''}
            />
          )}
        </InnerPageLayout>
      </FormProvider>
    </AIContextProvider>
  );
}
