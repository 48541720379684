import { StateCompliance } from '@common/types';
import { StateComplianceClient } from 'clients';
import { QueryKeys, StateCode } from 'enums';
import { UseQueryBaseSearchOptions } from './query/types';
import { useQuerySearch } from './query/useQuery';

export function useSearchStateComplianceByState(state?: StateCode, enabled = true) {
  const props: UseQueryBaseSearchOptions<StateCompliance> = { filter: { state }, enabled: !!state && enabled };
  const queryOptions = { enabled: !!state && enabled };
  return useQuerySearch({
    queryKey: QueryKeys.StateCompliance,
    clientClass: StateComplianceClient,
    ...props,
    queryOptions,
  });
}
