import { useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { BoxItem } from 'box-ui-elements/es';
import { flatten } from 'lodash';
import { useCallback, useMemo } from 'react';
import { CreateFolderOptions } from 'clients';
import { ForbiddenError } from 'clients/errors/ForbiddenError';
import { BoxTemplateLabels, QueryKeys } from 'enums';
import { useBoxClient, useCurrentUser } from 'hooks';
import { List } from 'types';
import { Features, isFeatureEnabled } from 'utils/features-config';
import { QueryKeyType } from './query/types';
import { useQueryMutation } from './query/useMutate';
import { defaultQueryOptions, useImperativeQuery } from './query/useQuery';

export const ITEM_NAME_TO_LONG_ERROR = 'Item name too long - (400)';

interface UseQueryBaseSearchOptions<Type> {
  filter: { folderId: string; chunkSize?: number };
  enabled?: boolean;
  queryOptions?: UseQueryOptions<List<Type> | null, Error, List<Type> | null, QueryKeyType>;
}

interface UploadFileProps {
  parentFolderId: string;
  fileName: string;
  file: Blob;
  retryWithDate?: boolean;
}

interface AddFileMetadataProps {
  fileId: string;
  metadata: Partial<Record<BoxTemplateLabels, string>>;
}

interface UploadFileWithMetadataProps extends UploadFileProps, Omit<AddFileMetadataProps, 'fileId'> {}

interface BoxDeleteMetadataError {
  code: string;
  message: string;
}

export function useSearchBoxItems(props: UseQueryBaseSearchOptions<BoxItem>) {
  const { filter, queryOptions, enabled } = props;
  const { folderId, chunkSize = 20 } = filter;

  const boxClient = useBoxClient();

  const query = useQuery(
    [QueryKeys.BoxItems, filter],
    async () => {
      if (filter.folderId) {
        const total = await boxClient.getTotalCount(filter.folderId);
        const pages = Math.ceil(total / chunkSize);

        const response = await Promise.all(
          Array.from(Array(pages).keys()).map(async (_val, index) =>
            boxClient.getFolderItems(folderId, index * chunkSize, chunkSize),
          ),
        );

        if (response) {
          const flattenedResponse = flatten(response).sort(
            (a, b) => new Date(b.modified_at!).getTime() - new Date(a.modified_at!).getTime(),
          );
          return { items: flattenedResponse || [], count: flattenedResponse.length };
        }
      }

      return { items: [], count: 0 };
    },
    {
      ...defaultQueryOptions,
      // if an empty folderId is sent then don't make search query to box
      enabled: !!filter.folderId && enabled,
      ...queryOptions,
    },
  );

  const { data, ...rest } = query;

  const items = useMemo(() => data?.items || [], [data?.items]);
  const count = data?.count || 0;

  return {
    items,
    count,
    ...rest,
  };
}

export const useMutateBoxItems = (cancelInvalidation = false) => {
  const boxClient = useBoxClient();
  const { me } = useCurrentUser();

  const authorizedOperation = <Output>(operation: () => Promise<Output>) => {
    if (!me || !isFeatureEnabled(Features.EditBoxData, me)) {
      throw new ForbiddenError();
    }
    return operation();
  };

  const uploadFile = useQueryMutation(
    ({ parentFolderId, fileName, file, retryWithDate }: UploadFileProps) =>
      authorizedOperation(() => boxClient.uploadFile(parentFolderId, fileName, file, retryWithDate)),
    QueryKeys.BoxItems,
    cancelInvalidation,
  );

  const createFolder = useQueryMutation(
    ({
      parentFolderId,
      folderName,
      createFolderOptions,
    }: {
      parentFolderId: string;
      folderName: string;
      createFolderOptions?: CreateFolderOptions;
    }) => authorizedOperation(() => boxClient.createFolder(parentFolderId, folderName, createFolderOptions)),
    QueryKeys.BoxItems,
    cancelInvalidation,
  );

  const updateFolder = useQueryMutation(
    ({ folderId, fields }: { folderId: string; fields: { name: string } }) =>
      authorizedOperation(() => boxClient.updateFolder(folderId, fields)),
    QueryKeys.BoxItems,
    cancelInvalidation,
  );

  const copyFile = useQueryMutation(
    ({ boxItemId, folderId }: { boxItemId: string; folderId: string }) =>
      authorizedOperation(() => boxClient.copyFile(boxItemId, folderId)),
    QueryKeys.BoxItems,
    cancelInvalidation,
  );

  const deleteFolder = useQueryMutation(
    ({ folderId, recursive }: { folderId: string; recursive?: boolean }) =>
      authorizedOperation(() => boxClient.deleteFolder(folderId, recursive)),
    QueryKeys.BoxItems,
    cancelInvalidation,
  );

  const updateFile = useQueryMutation(
    ({ fileId, fields }: { fileId: string; fields: { name?: string; parent?: { id: string } } }) =>
      authorizedOperation(() => boxClient.updateFile(fileId, fields)),
    QueryKeys.BoxItems,
    cancelInvalidation,
  );

  const deleteFile = useQueryMutation(
    ({ fileId }: { fileId: string }) => authorizedOperation(() => boxClient.deleteFile(fileId)),
    QueryKeys.BoxItems,
    cancelInvalidation,
  );

  const extendFileMetadata = (metadata: Partial<Record<BoxTemplateLabels, string>>) => {
    const extendedMetadata = { ...metadata };

    // Ensure that the fileType metadata is always synchronized with the user role who labelled the file
    // Allow the caller to explicitly set the classifiedByRole
    if (metadata.fileType && !metadata.classifiedByRole) {
      extendedMetadata.classifiedByRole = me?.role;
    }

    return extendedMetadata;
  };

  const addFileMetadata = useQueryMutation(
    ({ fileId, metadata }: AddFileMetadataProps) =>
      authorizedOperation(() => boxClient.addFileMetadata(fileId, extendFileMetadata(metadata))),
    QueryKeys.BoxItems,
    cancelInvalidation,
  );

  const updateFileMetadata = useQueryMutation(
    ({ fileId, metadata }: { fileId: string; metadata: Partial<Record<BoxTemplateLabels, string>> }) =>
      authorizedOperation(() => boxClient.updateFileMetadata(fileId, extendFileMetadata(metadata))),
    QueryKeys.BoxItems,
    cancelInvalidation,
  );

  const deleteFileMetadata = useQueryMutation(
    ({ fileId, metadataFields }: { fileId: string; metadataFields: Partial<Array<BoxTemplateLabels>> }) =>
      authorizedOperation(async () => {
        // We need to remove the "classifiedByRole" metadata field if the "fileType" field is being deleted
        // Create a temporary copy of the metadata fields to delete so that we can revert to the original on error
        const metadataFieldsToDelete = [...metadataFields];
        if (metadataFieldsToDelete.includes(BoxTemplateLabels.FileType)) {
          metadataFieldsToDelete.push(BoxTemplateLabels.ClassifiedByRole);
        }

        // For files that were labelled prior to introducing the "classifiedByRole" field, the "classifiedByRole" field will not exist
        // and Box will return a 400 error if we try to delete it.
        const response = await boxClient.deleteFileMetadata(fileId, metadataFieldsToDelete, {
          passthroughStatusCodes: [400],
        });

        const responseAsError = response as BoxDeleteMetadataError;
        // if we failed to delete the "classifiedByRole" field, fall back to the original metadata fields to delete
        if (
          metadataFieldsToDelete.includes(BoxTemplateLabels.FileType) &&
          responseAsError?.code === 'bad_request' &&
          responseAsError.message?.includes('classifiedByRole')
        ) {
          // don't swallow 400 error if we're retrying with the original metadata fields
          return boxClient.deleteFileMetadata(fileId, metadataFields);
        }

        return response;
      }),
    QueryKeys.BoxItems,
    cancelInvalidation,
  );

  const createShareLink = useQueryMutation(
    ({ fileId }: { fileId: string }) => authorizedOperation(() => boxClient.createShareLink(fileId)),
    QueryKeys.BoxItems,
    true,
  );

  const createShareLinks = useQueryMutation(
    ({ files }: { files: BoxItem[] }) => authorizedOperation(() => boxClient.createShareLinks(files)),
    QueryKeys.BoxItems,
    true,
  );

  const createZip = useQueryMutation(
    ({ attachedFiles, downloadFileName }: { attachedFiles: BoxItem[]; downloadFileName?: string }) =>
      boxClient.createZip(attachedFiles, downloadFileName),
    QueryKeys.BoxItems,
    true,
  );

  const downloadFileAsBlob = useCallback(async (fileId: string) => boxClient.downloadFileAsBlob(fileId), [boxClient]);

  const uploadFileWithMetadata = async ({
    parentFolderId,
    fileName,
    file,
    retryWithDate,
    metadata,
  }: UploadFileWithMetadataProps) => {
    const boxFile = await uploadFile.mutateAsync({ parentFolderId, fileName, file, retryWithDate });
    await addFileMetadata.mutateAsync({ fileId: boxFile?.id!, metadata });
    return boxFile;
  };

  return {
    createFolder,
    updateFolder,
    deleteFolder,
    updateFile,
    deleteFile,
    uploadFile,
    addFileMetadata,
    uploadFileWithMetadata,
    updateFileMetadata,
    deleteFileMetadata,
    createShareLink,
    createShareLinks,
    createZip,
    downloadFileAsBlob,
    copyFile,
  };
};

export function useReFetchBoxItems() {
  const queryClient = useQueryClient();

  const reFetchFileItems = async () => {
    await queryClient.invalidateQueries([QueryKeys.BoxItems]);
  };

  return { reFetchFileItems };
}

export const useBoxApi = () => {
  const client = useBoxClient();

  const getBoxItemById = useImperativeQuery({
    queryFn: async (id: string) => client.getFile(id),
    queryKey: [QueryKeys.BoxItem],
  });

  const getFilePdfRepresentations = useImperativeQuery({
    queryFn: async (id: string) => client.getFilePdfRepresentations(id),
    queryKey: [QueryKeys.BoxPdfRepresentation],
  });

  const downloadFileRepresentation = useImperativeQuery({
    queryFn: async (url: string) => client.downloadFileRepresentation(url),
    queryKey: [QueryKeys.BoxDownloadPdfRepresentation],
  });

  return {
    getBoxItemById,
    getFilePdfRepresentations,
    downloadFileRepresentation,
  };
};
