import { BoxItem } from 'box-ui-elements/es';
import { map } from 'lodash';
import { useCallback, useEffect } from 'react';
import { ActivityType } from 'enums';
import { useGetParam, useSearchSubmissionFileMetadata, useUnmount } from 'hooks';
import { SyncedSystemType } from 'types';
import { getFileSyncStatus } from 'utils';
import { BrokerUrlParams } from 'broker/broker-routes';
import SyncFileButtons from 'broker/components/common/SyncFileButtons';
import FilesExplorer from 'broker/components/FilesExplorer';
import { ExtendedBoxItem } from 'broker/components/FilesExplorer/types';
import { useToolboxExpanded } from 'broker/pages/SubmissionWorkspacePage/components/Toolbox/useToolboxExpanded';
import { useFileLabelsMetadata } from 'broker/pages/SubmissionWorkspacePage/hooks';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { useUiStoreActions, useUiStoreState } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';
import { useGetFileSecondaryActions } from './useGetFileSecondaryActions';

export interface FilesProps {
  alwaysShowUploader?: boolean;
  isSelectionMode?: boolean;
  isEmailComposeMode?: boolean;
  autoLabel?: boolean;
  hideQuoteActions?: boolean;
}

export default function Files({
  alwaysShowUploader,
  isSelectionMode,
  isEmailComposeMode = false,
  hideQuoteActions,
  autoLabel,
}: FilesProps) {
  const { partialSubmission, markets, logSubmissionActivity } = useSubmissionsWorkspace();
  const { setFilesExplorer, setToolboxState, syncSelectedFiles } = useUiStoreActions();
  const { filesExplorer } = useUiStoreState();
  const getFileSecondaryActions = useGetFileSecondaryActions(markets, hideQuoteActions);
  const selectedFileUrlParam = useGetParam(BrokerUrlParams.SELECTED_FILE);
  const { getLabelsMetadata } = useFileLabelsMetadata();

  useToolboxExpanded(!!selectedFileUrlParam);

  const { items: submissionFileMetadata, isLoading: isLoadingSubmissionFileMetadata } = useSearchSubmissionFileMetadata(
    {
      filter: {
        submissionId: partialSubmission?.id,
      },
      enabled: !!partialSubmission,
    },
  );

  useEffect(() => {
    setFilesExplorer({ selectionMode: isSelectionMode });
  }, [isSelectionMode, setFilesExplorer]);

  useUnmount(() => {
    setToolboxState({ isExpanded: false });
  });

  const onFileViewerStateChange = (isOpened: boolean) => {
    setToolboxState({ isExpanded: isOpened });
  };

  const onFileUploadCompleted = async (boxItems: ExtendedBoxItem[]) => {
    await logSubmissionActivity(
      {
        activityType: ActivityType.FilesUploaded,
        metadata: { fileIds: map(boxItems, 'id') },
      },
      true,
    );
  };

  const toggleFile = (file: BoxItem) => {
    const currentFiles = [...filesExplorer.selectedFiles];
    const index = currentFiles.findIndex((currentFile) => currentFile.id === file.id);
    if (index !== -1) {
      currentFiles.splice(index, 1);
      setFilesExplorer({
        selectedFiles: currentFiles,
        isSelectedFilesDirty: true,
      });
    } else {
      setFilesExplorer({
        selectedFiles: [...currentFiles, file],
        isSelectedFilesDirty: true,
      });
    }
  };

  const getPrimaryActionComponent = (file: ExtendedBoxItem) => (
    <SyncFileButtons
      submissionId={partialSubmission!.id}
      syncStatus={getFileSyncStatus(file)}
      syncSystem={SyncedSystemType.FilesStorage}
      id={file.id}
      displayText={file.name}
    />
  );

  const setSelectedFiles = useCallback(
    (boxItems: ExtendedBoxItem[]) => setFilesExplorer({ selectedFiles: boxItems, isLoaded: true }),
    [setFilesExplorer],
  );

  const ownUpdateSelectedFilesMetadata = useCallback(
    async (boxItems: BoxItem[]) => {
      syncSelectedFiles({ updatedBoxItems: boxItems, submissionFileMetadata });
    },
    [submissionFileMetadata, syncSelectedFiles],
  );

  return partialSubmission ? (
    <FilesExplorer
      isScanning={filesExplorer.isScanning}
      folderId={partialSubmission.boxFolderId}
      filter={filesExplorer.filter}
      alwaysShowUploader={alwaysShowUploader}
      selectionMode={filesExplorer.selectionMode}
      selectedFiles={filesExplorer.selectedFiles}
      preSelectedFileIds={filesExplorer.preSelectedFileIds}
      onFileSelected={toggleFile}
      setIsScanning={(isScanning) => setFilesExplorer({ isScanning })}
      setSelectedFiles={setSelectedFiles}
      autoLabel={autoLabel}
      onFileViewerStateChange={onFileViewerStateChange}
      onFileUploadCompleted={onFileUploadCompleted}
      getPrimaryActionComponent={getPrimaryActionComponent}
      getFileSecondaryActions={isEmailComposeMode ? undefined : getFileSecondaryActions}
      isLoadingAdditionalFileMetadata={isLoadingSubmissionFileMetadata}
      highlightFileTypes={filesExplorer.highlightFileTypes}
      labelsMetadata={getLabelsMetadata()}
      viewOnlyMode={filesExplorer.viewOnlyMode}
      syncSelectedFiles={ownUpdateSelectedFilesMetadata}
      submissionId={partialSubmission.id}
    />
  ) : null;
}
