import { SubmissionEmailLabel } from '../enums';
import { messages } from '../messages';

interface SubmissionEmailLabelConfigProp {
    text: string;
    autoLabelReadonly?: boolean;
    isSingle?: boolean;
    isInboundOnly?: boolean;
}

type EmailLabelConfig = Record<SubmissionEmailLabel, SubmissionEmailLabelConfigProp>;

const submissionEmailLabelMessages = messages.emailLabels;

export const emailLabelConfig: EmailLabelConfig = {
    [SubmissionEmailLabel.Proposal]: {
        text: submissionEmailLabelMessages.Proposal,
    },
    [SubmissionEmailLabel.InformationRequest]: {
        text: submissionEmailLabelMessages.InformationRequest,
    },
    [SubmissionEmailLabel.BinderRequested]: {
        text: submissionEmailLabelMessages.BinderRequested,
    },
    [SubmissionEmailLabel.PolicyRequested]: {
        text: submissionEmailLabelMessages.PolicyRequested,
    },
    [SubmissionEmailLabel.Declination]: {
        text: submissionEmailLabelMessages.Declination,
    },
    [SubmissionEmailLabel.MarketingUpdate]: {
        text: submissionEmailLabelMessages.MarketingUpdate,
    },
    [SubmissionEmailLabel.SubmissionMarketing]: {
        text: submissionEmailLabelMessages.SubmissionMarketing,
    },
    [SubmissionEmailLabel.TowerUpdate]: {
        text: submissionEmailLabelMessages.TowerUpdate,
    },
    [SubmissionEmailLabel.SubmissionUpdate]: {
        text: submissionEmailLabelMessages.SubmissionUpdate,
    },
    [SubmissionEmailLabel.CannotPursue]: {
        text: submissionEmailLabelMessages.CannotPursue,
    },
    [SubmissionEmailLabel.PositiveExpectation]: {
        text: submissionEmailLabelMessages.PositiveExpectation,
    },
    [SubmissionEmailLabel.Acknowledgment]: {
        text: submissionEmailLabelMessages.Acknowledgment,
    },
    [SubmissionEmailLabel.Quote]: {
        text: submissionEmailLabelMessages.Quote,
    },
    [SubmissionEmailLabel.Indication]: {
        text: submissionEmailLabelMessages.Indication,
    },
    [SubmissionEmailLabel.LossRuns]: {
        text: submissionEmailLabelMessages.LossRuns,
    },
    [SubmissionEmailLabel.SubmissionBox]: {
        text: submissionEmailLabelMessages.SubmissionBox,
        autoLabelReadonly: true,
        isSingle: true,
        isInboundOnly: true,
    },
    [SubmissionEmailLabel.Correspondence]: {
        text: submissionEmailLabelMessages.Correspondence,
    },
    [SubmissionEmailLabel.SurplusDocuments]: {
        text: submissionEmailLabelMessages.SurplusDocuments,
    },
    [SubmissionEmailLabel.Binder]: {
        text: submissionEmailLabelMessages.Binder,
    },
    [SubmissionEmailLabel.Policy]: {
        text: submissionEmailLabelMessages.Policy,
    },
    [SubmissionEmailLabel.AdditionalInfo]: {
        text: submissionEmailLabelMessages.AdditionalInfo,
    },
    [SubmissionEmailLabel.PaymentRequested]: {
        text: submissionEmailLabelMessages.PaymentRequested,
    },
};
