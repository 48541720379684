/* global google */

import { AddressDetails } from '@common/types';
import { StateCode } from 'enums';
import { formatAddress } from './address-utils';

export function mapGoogleMapResultToStructuredAddress(googleResult: google.maps.places.PlaceResult): AddressDetails {
  const streetNumber = googleResult.address_components?.reduce<string | undefined>((current, addressComponent) => {
    if (addressComponent.types.includes('street_number')) {
      return addressComponent.short_name;
    }
    return current;
  }, undefined);

  const route = googleResult.address_components?.reduce<string | undefined>((current, addressComponent) => {
    if (addressComponent.types.includes('route')) {
      return addressComponent.short_name;
    }
    return current;
  }, undefined);
  const streetNumberPrefix = streetNumber ? `${streetNumber} ` : '';

  const structuredAddress = {
    placeId: googleResult.place_id,
    state: googleResult.address_components?.reduce<string | undefined>((current, addressComponent) => {
      if (addressComponent.types.includes('administrative_area_level_1')) {
        return addressComponent.short_name;
      }
      return current;
    }, undefined),
    country: googleResult.address_components?.reduce<string | undefined>((current, addressComponent) => {
      if (addressComponent.types.includes('country')) {
        return addressComponent.short_name;
      }
      return current;
    }, undefined),
    line1: `${streetNumberPrefix}${route}`,
    city: googleResult.address_components?.reduce<string | undefined>((current, addressComponent) => {
      if (addressComponent.types.includes('locality')) {
        return addressComponent.short_name;
      }
      return current;
    }, undefined),
    postalCode: googleResult.address_components?.reduce<string | undefined>((current, addressComponent) => {
      if (addressComponent.types.includes('postal_code')) {
        return addressComponent.short_name;
      }
      return current;
    }, undefined),
  };

  const structuredAddressNormalized = {
    ...structuredAddress,
    state: structuredAddress.state as StateCode | undefined,
  };

  return {
    ...structuredAddressNormalized,
    formattedAddress: formatAddress(structuredAddressNormalized),
  };
}
