import { BoxItem } from 'box-ui-elements/es';
import { useBoolean, useConditionallyRenderedModal, useFormProvider } from 'hooks';
import { FileActionType, FilesDisplay } from 'broker/components/FilesExplorer/hooks/useFilesDisplay';
import { FileRenameFormData, fileRenameSchema } from 'broker/components/FilesExplorer/utils';

export default function useFileCard(fileInfo: BoxItem, filesDisplay: FilesDisplay) {
  // Rename
  const getFileStem = (fileName: string) => {
    const fileNameSplit = fileName.split('.');
    fileNameSplit.pop();
    return fileNameSplit.join('.');
  };
  const { methods: renameMethods } = useFormProvider<FileRenameFormData>({
    defaultValues: { name: '' },
    schema: fileRenameSchema,
  });

  const closeRenameField = () => {
    filesDisplay.closeInlineAction(fileInfo.id);
    renameMethods.setValue('name', '');
  };

  const showRenameField = () => {
    const fileStem = getFileStem(fileInfo.name);
    renameMethods.setValue('name', fileStem);
    filesDisplay.openInlineAction(fileInfo.id, FileActionType.Rename);
  };

  // Delete
  const [isSubmittingDelete, { on: startSubmittingDelete, off: stopSubmittingDelete }] = useBoolean(false);

  const {
    openModal: openDeleteConfirmation,
    closeModal: closeDeleteConfirmation,
    modalState: { isOpen: isDeleteConfirmationOpen, shouldRender: shouldRenderDeleteConfirmation },
  } = useConditionallyRenderedModal({});

  const hideAllActions = () => {
    closeRenameField();
  };

  return {
    rename: {
      methods: renameMethods,
      show: showRenameField,
      hide: closeRenameField,
    },
    deleteConfirmation: {
      isOpen: isDeleteConfirmationOpen,
      shouldRender: shouldRenderDeleteConfirmation,
      open: openDeleteConfirmation,
      close: closeDeleteConfirmation,
      isSubmitting: isSubmittingDelete,
      stopSubmitting: stopSubmittingDelete,
      startSubmitting: startSubmittingDelete,
    },
    hideAllActions,
  };
}
