import { ChangeEvent, useEffect } from 'react';
import { BoxSkeleton, CircularProgress, Stack, Switch, Typography } from '@common-components';
import { Circle } from '@icons';
import { NylasAuthenticationStatus } from 'enums';
import { NylasAuthTrigger, useBoolean, useHotjar, useRetrieveNylasAuthenticationStatus } from 'hooks';
import { messages } from 'i18n';
import { HotjarEvents } from 'utils/hotjar-events';
import { useNylasAuth } from 'broker/hooks';
import { useRevokeNylasGrant } from 'broker/hooks/useRevokeNylasGrant';

interface AuthenticationStatusInfo {
  label: string;
  badgeColor?: string;
}

const { emailIntegration } = messages.SettingsDialog.emailSettings;

const authenticationStatusMap: Record<NylasAuthenticationStatus, AuthenticationStatusInfo> = {
  [NylasAuthenticationStatus.UnAuthenticated]: {
    label: emailIntegration.status.unauthenticated,
  },
  [NylasAuthenticationStatus.InvalidGrant]: {
    label: emailIntegration.status.invalidToken,
    badgeColor: 'warning.main',
  },
  [NylasAuthenticationStatus.Authenticated]: {
    label: emailIntegration.status.authenticated,
    badgeColor: 'success.main',
  },
};

export default function EmailIntegrationToggle({ disabled }: { disabled: boolean }) {
  const hotjar = useHotjar();
  const { data: nylasUser, isLoading } = useRetrieveNylasAuthenticationStatus({
    enabled: !disabled,
  });
  const [isSwitchChecked, { set: setSwitchChecked }] = useBoolean(false);
  const { triggerAuthFlow, isLoadingAuthUrl } = useNylasAuth(NylasAuthTrigger.EmailIntegrationToggle);
  const { revokeNylasGrant, isLoading: isLoadingRevokeGrant } = useRevokeNylasGrant();

  // Block the user from disconnecting, see CAP-4975
  // Disable the switch if the user is not authenticated or if the switch is already connected
  const disabledSwitch = disabled || isSwitchChecked;

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      hotjar.event(HotjarEvents.EmailIntegrationSettingsToggleOn);
      triggerAuthFlow();
    } else {
      hotjar.event(HotjarEvents.EmailIntegrationSettingsToggleOff);
      await revokeNylasGrant();
      setSwitchChecked(false);
    }
  };

  useEffect(() => {
    if (nylasUser) {
      setSwitchChecked(nylasUser.authenticationStatus === NylasAuthenticationStatus.Authenticated);
    }
  }, [nylasUser, setSwitchChecked]);

  const authStatus = disabled ? NylasAuthenticationStatus.UnAuthenticated : nylasUser?.authenticationStatus;

  return (
    <BoxSkeleton isLoading={!disabled && isLoading} sx={{ width: 1 }}>
      {authStatus && (
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" alignItems="center">
            {authenticationStatusMap[authStatus].badgeColor && (
              <Circle
                sx={(theme) => ({
                  width: theme.spacing(1),
                  height: theme.spacing(1),
                  mr: 1,
                  color: authenticationStatusMap[authStatus].badgeColor,
                })}
              />
            )}
            <Typography variant="body2" color={disabled ? 'text.disabled' : 'text.primary'}>
              {authenticationStatusMap[authStatus].label}
            </Typography>
          </Stack>
          {isLoadingAuthUrl || isLoadingRevokeGrant ? (
            <CircularProgress size={24} />
          ) : (
            <Switch checked={isSwitchChecked} onChange={handleChange} disabled={disabledSwitch} />
          )}
        </Stack>
      )}
    </BoxSkeleton>
  );
}
