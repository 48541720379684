import { ReactNode } from 'react';
import { QuoteExits } from 'clients/types';
import { InsuranceProduct, SubmissionMarket } from 'types';
import { FilterElementProps, SearchInputProps } from 'utils';
import { ProductFilterAttributes } from './useProductFilter';

export type SelectedProductsMap = Record<string, InsuranceProduct>;

export interface ProductsProps {
  selectedProducts: SelectedProductsMap;
  submissionMarkets: SubmissionMarket[];
  onProductSelected: (product: InsuranceProduct) => void;
  onProductRemoved: (productId: string) => void;
  quoteExits?: QuoteExits[];
  products?: InsuranceProduct[];
  isLoading?: boolean;
  bottomTableContent?: ReactNode;
  searchInput?: string;
}

export interface ProductItemProps {
  product: InsuranceProduct;
  selectedProducts: SelectedProductsMap;
  quoteExits?: QuoteExits[];
  submissionMarket?: SubmissionMarket;
  onProductSelected: (product: InsuranceProduct) => void;
  onProductRemoved: (productId: string) => void;
  searchInput?: string;
}

export enum FilterType {
  CoverageLine = 'coverageLines',
  IsAdmitted = 'isAdmitted',
  IsPrimary = 'isPrimary',
}

export interface UseProductFilterAttributes extends SearchInputProps {
  sortedAndFilteredItems: InsuranceProduct[];
  filters: Record<FilterType, ProductFilterAttributes>;
  clearFilters: () => void;
  isFilterApplied: boolean;
  coverageLineFilters: FilterElementProps[]; // Returning the new filters
  handleVisibleCoverageChange: (key: string) => void;
}
