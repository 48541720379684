export enum DetailsFieldsNames {
  InsuranceProductId = 'insuranceProductId',
  Premium = 'premium',
  Limit = 'limit',
  AgentCommission = 'agentCommission',
  Comments = 'comments',
  ExpiresAt = 'expiresAt',
  Date = 'date',
  SubLimits = 'subLimits',
  TriaPremium = 'triaPremium',
  CarrierFee = 'carrierFee',
  FlowBrokerFee = 'flowBrokerFee',
  SurplusLineTax = 'surplusLineTax',
  SurplusStampingFee = 'surplusStampingFee',
  Subjectivities = 'subjectivities',
  PolicyNumber = 'policyNumber',
  PolicyEffectiveDate = 'policyEffectiveDate',
  PolicyExpirationDate = 'policyExpirationDate',
  FlowCommission = 'flowCommission',
  AttachmentPoint = 'attachmentPoint',
  StateCompliance = 'stateCompliance',
  CoverageLines = 'coverageLines',
}

export enum SubjectivitiesFieldsNames {
  Value = 'value',
}

export enum SubjectivitiesOpenItemsArrayFieldsNames {
  Type = 'type',
  Checked = 'checked',
}

export enum InsightsFieldsNames {
  Insights = 'insights',
}
