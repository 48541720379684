import { compact, isEmpty, toNumber } from 'lodash';
// eslint-disable-next-line import/no-unresolved
import { Content } from 'pdfmake/interfaces';
import { PartialSubmission } from 'types';
import { coverageLineConfig, formatPrice, getTimezoneLessDisplayDate, htmlToPdfMakeWrapper, logger } from 'utils';
import { QuoteFormCreate } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/form-methods/types';

interface PdfContentProps {
  submission: PartialSubmission;
  quoteForm: QuoteFormCreate;
  marketName: string;
}
const currency = '$';

export const getPdfContent = ({ submission, quoteForm, marketName }: PdfContentProps): Content => {
  logger.log('info', { message: 'getPdfContent arguments', submissionId: submission.id, quoteForm, marketName });
  return compact([
    {
      style: 'tableExample',
      layout: 'noBorders',
      table: {
        widths: ['*', '*'],
        body: [
          [
            (submission.user?.firstName || '') + (submission.user?.lastName ? ` ${submission.user?.lastName}` : ''),
            { text: quoteForm.date ? getTimezoneLessDisplayDate(quoteForm.date) : '', alignment: 'right' },
          ],
          [submission.organizationName || '', ''],
        ],
      },
    },
    {
      text: '\n\n',
      lineHeight: 1.1,
    },
    {
      text: 'Flow Insurance Services LLC is pleased to provide the following quote for coverage. Please review this quote for accuracy and let us know if you have any questions or note any discrepancies prior to binding coverage. If we do not hear from you, it will be understood that the quote provides an accurate description of the coverage subject to the terms and conditions set forth herein.',
    },
    {
      text: '\n\n',
      lineHeight: 1.1,
    },
    {
      text: [{ text: 'Insured: ', bold: true }, { text: submission.insuredName || '' }],
    },
    submission.insuredPrimaryAddress?.formattedAddress && {
      text: [{ text: 'Insured Address: ', bold: true }, { text: submission.insuredPrimaryAddress.formattedAddress }],
    },
    quoteForm.coverageLines?.length && {
      text: [
        { text: 'Coverage Lines: ', bold: true },
        { text: quoteForm.coverageLines.map((coverageLine) => coverageLineConfig[coverageLine].text).join(', ') },
      ],
    },
    {
      text: [{ text: 'Capacity Provider: ', bold: true }, { text: marketName }],
    },
    submission.dueDate && {
      text: [{ text: 'Effective Date: ', bold: true }, { text: getTimezoneLessDisplayDate(submission.dueDate) }],
    },
    quoteForm.expiresAt && {
      text: [
        { text: 'Quote Expiration Date: ', bold: true },
        { text: getTimezoneLessDisplayDate(quoteForm.expiresAt) },
      ],
    },
    quoteForm.subLimits && {
      text: 'Limits & Deductibles: ',
      bold: true,
    },
    quoteForm.subLimits && {
      text: quoteForm.subLimits,
    },
    {
      text: '\n',
      lineHeight: 1.1,
    },
    quoteForm.agentCommission && {
      text: [{ text: 'Agent Commission: ', bold: true }, { text: `${quoteForm.agentCommission}%` }],
    },
    {
      text: [{ text: 'Premium: ', bold: true }, { text: currency + formatPrice(Number(quoteForm.premium), 2) }],
    },
    quoteForm.triaPremium && {
      text: [
        { text: 'TRIA premium: ', bold: true },
        { text: currency + formatPrice(Number(quoteForm.triaPremium), 2) },
      ],
    },
    quoteForm.carrierFee && {
      text: [
        { text: 'Capacity provider fee: ', bold: true },
        { text: currency + formatPrice(Number(quoteForm.carrierFee), 2) },
      ],
    },
    quoteForm.flowBrokerFee && {
      text: [
        { text: 'Flow broker fee: ', bold: true },
        { text: currency + formatPrice(Number(quoteForm.flowBrokerFee), 2) },
      ],
    },
    quoteForm.surplusLineTax && {
      text: [
        { text: 'Surplus line tax: ', bold: true },
        { text: currency + formatPrice(Number(quoteForm.surplusLineTax), 2) },
      ],
    },
    quoteForm.surplusStampingFee && {
      text: [
        { text: 'Surplus stamping fee: ', bold: true },
        { text: currency + formatPrice(Number(quoteForm.surplusStampingFee), 2) },
      ],
    },
    {
      text: [
        { text: 'Total: ', bold: true },
        {
          text:
            currency +
            formatPrice(
              toNumber(quoteForm.premium) +
                toNumber(quoteForm.triaPremium) +
                toNumber(quoteForm.carrierFee) +
                toNumber(quoteForm.flowBrokerFee) +
                toNumber(quoteForm.surplusLineTax) +
                toNumber(quoteForm.surplusStampingFee),
              2,
            ),
        },
      ],
    },
    {
      text: '\n',
      lineHeight: 1.1,
    },
    {
      text: 'Please refer to the attached for a full breakdown of limits, retentions, and applicable clauses.',
    },
    {
      text: '\n',
      lineHeight: 1.1,
    },
    quoteForm.insights && {
      text: 'Quote Analysis:',
      bold: true,
    },
    quoteForm.insights ? htmlToPdfMakeWrapper(quoteForm.insights) : undefined,
    {
      text: '\n',
      lineHeight: 1.1,
    },
    quoteForm.subjectivities?.some((subjectivity) => !isEmpty(subjectivity.value)) && {
      text: `Subjectivities:`,
      bold: true,
    },
    {
      ul: compact(quoteForm.subjectivities?.map((subjectivity) => subjectivity.value) || []),
    },
    {
      text: '\n',
      lineHeight: 1.1,
    },
    quoteForm.stateCompliance && {
      text: 'State Compliance:',
      bold: true,
    },
    quoteForm.stateCompliance ? htmlToPdfMakeWrapper(quoteForm.stateCompliance) : undefined,
    {
      text: '\n',
      lineHeight: 1.1,
    },
    {
      text: 'Subjectivities/conditions must be submitted for review within the timeframe specified. This quote is issued based on the truthfulness and accuracy of the information provided on the insurance application. The insurer reserves the right to modify the final terms and conditions or withdraw this offer based on review of the application and any other subjectivities.',
    },
    {
      text: '\n',
      lineHeight: 1.1,
    },
    {
      text: [
        { text: 'Cancellation: ', bold: true },
        {
          text: 'Insurance coverage cannot be cancelled flat; premium must be paid for the time insurance has been in force. ',
        },
        {
          text: 'Coverage may be cancelled by either the insured or the insurer by written notice to the other and is subject to cancellation terms specified in the attached.',
          decoration: 'underline',
        },
      ],
    },
    {
      text: '\n',
      lineHeight: 1.1,
    },
    {
      text: 'Thank you for the opportunity to partner with you. We appreciate your business and hope that we can be of further service to you in the future.  Please contact us with any questions.',
    },
  ]);
};
