import {
  HeraldApplication,
  HeraldCategoryType,
  HeraldCoverageParameterProps,
  HeraldFormType,
  HeraldIndexEntry,
  HeraldParameterBaseProps,
  HeraldRiskParameterProps,
} from 'clients/types/herald-types';
import {
  DynamicFormQuestionSource,
  HeraldApplicationNormalized,
  heraldIndustryQuestionParameterId,
  HeraldNormalizedParameter,
} from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/DynamicForm/types';

function normalizeCoverageValuesParam(
  coverageValues: HeraldCoverageParameterProps[],
  formType: HeraldFormType,
  forceShowQuestions?: string[],
): (HeraldNormalizedParameter & Pick<HeraldParameterBaseProps, 'creates_array'>)[] {
  return coverageValues.map((coverageValue) => {
    const forceShowQuestion = forceShowQuestions?.includes(coverageValue.coverage_parameter_id);
    const isRequiredForThisFormType = coverageValue.required_for.includes(formType);
    // form type: quote/binder/policy
    const isRequiredForAnyFormType = coverageValue.required_for.length > 0;
    // for coverage values, we also show the questions that aren't required for any formType (they will appear as optional)
    const isHidden = isRequiredForAnyFormType && !isRequiredForThisFormType && !forceShowQuestion;
    return {
      ...coverageValue,
      parameter_id: coverageValue.coverage_parameter_id,
      fieldLabel: coverageValue.parameter_text.agent_facing_text,
      heraldCategory: HeraldCategoryType.CoverageValues,
      childValues: coverageValue.child_coverage_values
        ? normalizeCoverageValuesParam(coverageValue.child_coverage_values, formType)
        : undefined,
      isRequired: isRequiredForThisFormType,
      isHidden: () => isHidden,
      additionalContent: coverageValue.parameter_text.applicant_agree_to_text,
      source: DynamicFormQuestionSource.Herald,
    };
  });
}

function normalizeRiskValuesParam(
  riskValues: HeraldRiskParameterProps[],
  formType: HeraldFormType,
  applicationIndustryDetails?: HeraldIndexEntry,
  forceShowQuestions?: string[],
): (HeraldNormalizedParameter & Pick<HeraldParameterBaseProps, 'creates_array'>)[] {
  return riskValues.map((riskValue) => {
    const forceShowQuestion = forceShowQuestions?.includes(riskValue.risk_parameter_id);
    const isRequiredForThisFormType = riskValue.required_for.includes(formType);
    return {
      ...riskValue,
      parameter_id: riskValue.risk_parameter_id,
      industryDetails:
        riskValue.risk_parameter_id === heraldIndustryQuestionParameterId ? applicationIndustryDetails : undefined,
      fieldLabel: riskValue.parameter_text.agent_facing_text,
      childValues: riskValue.child_risk_values
        ? normalizeRiskValuesParam(riskValue.child_risk_values, formType, applicationIndustryDetails)
        : undefined,
      heraldCategory: HeraldCategoryType.RiskValues,
      isRequired: isRequiredForThisFormType,
      isHidden: () => !isRequiredForThisFormType && !forceShowQuestion,
      additionalContent: riskValue.parameter_text.applicant_agree_to_text,
      source: DynamicFormQuestionSource.Herald,
    };
  });
}

function mergeHeraldArrayElements(heraldNormalizedParameters: HeraldNormalizedParameter[]) {
  const mergedHeraldNormalizedParameters: HeraldNormalizedParameter[] = [];
  const arrayElementsParameterIds: string[] = [];
  heraldNormalizedParameters.forEach((heraldNormalizedParameter) => {
    const childValues = heraldNormalizedParameter.childValues
      ? mergeHeraldArrayElements(heraldNormalizedParameter.childValues)
      : undefined;
    if (heraldNormalizedParameter.creates_array) {
      if (arrayElementsParameterIds.includes(heraldNormalizedParameter.parameter_id)) {
        const foundIndex = mergedHeraldNormalizedParameters.findIndex(
          (mergedHeraldNormalizedParameter) =>
            mergedHeraldNormalizedParameter.parameter_id === heraldNormalizedParameter.parameter_id,
        )!;

        mergedHeraldNormalizedParameters[foundIndex] = {
          ...mergedHeraldNormalizedParameters[foundIndex],
          arrayElements: [
            ...mergedHeraldNormalizedParameters[foundIndex].arrayElements!,
            {
              ...heraldNormalizedParameter,
              childValues,
            },
          ],
        };
      } else {
        arrayElementsParameterIds.push(heraldNormalizedParameter.parameter_id);
        mergedHeraldNormalizedParameters.push({
          ...heraldNormalizedParameter,
          arrayElements: [
            {
              ...heraldNormalizedParameter,
              childValues,
            },
          ],
        });
      }
    } else {
      mergedHeraldNormalizedParameters.push({
        ...heraldNormalizedParameter,
        childValues,
      });
    }
  });
  return mergedHeraldNormalizedParameters;
}

export default function heraldApplicationNormalizer(
  application: HeraldApplication,
  formType: HeraldFormType,
  applicationIndustryDetails?: HeraldIndexEntry,
  forceShowQuestions?: string[],
): HeraldApplicationNormalized {
  return {
    ...application,
    coverage_values: mergeHeraldArrayElements(
      normalizeCoverageValuesParam(application.coverage_values, formType, forceShowQuestions),
    ),
    risk_values: mergeHeraldArrayElements(
      normalizeRiskValuesParam(application.risk_values, formType, applicationIndustryDetails, forceShowQuestions),
    ),
  };
}
