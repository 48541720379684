import { useEffect } from 'react';
import { Button, Paper, Stack } from '@common-components';
import { BoxTemplateLabels } from 'enums';
import { useMount, useUnmount } from 'hooks';
import { messages } from 'i18n';
import { getMarketNameWithType } from 'broker/components/UserMarkets/components/utils';
import { useNavigateBack } from 'broker/hooks';
import MarketRequestItem from 'broker/pages/SubmissionWorkspacePage/components/common/MarketRequestItem';
import InnerPageLayout from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/InnerPageLayout';
import { useCurrentSubmissionMarket } from 'broker/pages/SubmissionWorkspacePage/hooks';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { useUiStoreActions } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';

interface MarketCardProps {
  onMarketChanged: () => void;
}

export default function MarketCard({ onMarketChanged }: MarketCardProps) {
  const navigateBack = useNavigateBack();
  const market = useCurrentSubmissionMarket();
  const { setFilesExplorer, resetFilesExplorer } = useUiStoreActions();
  const { activities } = useSubmissionsWorkspace();

  const marketId = market?.id || '';
  const marketActivities = activities.filter((a) => a.submissionMarketId === marketId);
  const close = () => {
    navigateBack();
  };

  useEffect(() => {
    if (market) {
      setFilesExplorer({
        filter: {
          [BoxTemplateLabels.MarketId]: [
            {
              value: market.id,
              label: market.marketName,
            },
          ],
        },
      });
    }
  }, [market, setFilesExplorer]);

  useUnmount(() => {
    resetFilesExplorer();
  });

  useMount(() => {
    if (!market) {
      close();
    } else {
      onMarketChanged();
    }
  });

  if (!market) {
    return null;
  }

  return (
    <InnerPageLayout
      className="cap-single-market-page"
      sx={{ p: 0 }}
      title={getMarketNameWithType(market.marketName, market.marketOrganizationType)}
      footer={() => (
        <Stack direction="row" justifyContent="flex-end">
          <Button variant="contained" onClick={close}>
            {messages.buttons.close}
          </Button>
        </Stack>
      )}
    >
      {() => (
        <Stack component={Paper} variant="outlined" overflow="hidden" flex="1 1 0" m={3}>
          <Stack>
            {market.marketRequests.map((marketRequest) => (
              <MarketRequestItem
                showSelectionCheck={false}
                key={marketRequest.id}
                marketRequest={{ ...marketRequest, market }}
                activities={marketActivities}
              />
            ))}
          </Stack>
        </Stack>
      )}
    </InnerPageLayout>
  );
}
