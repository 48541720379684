import { createSearchParams } from 'react-router-dom-latest';
import { FormMode, LayerStatus, QuoteFileIdType } from 'enums';
import { Layer, Quote } from 'types';
import { BrokerUrlParams } from 'broker/broker-routes';
import { RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';
import { useModalActions } from 'broker/pages/SubmissionWorkspacePage/dialogs/modalProvider/modalProvider';
import { useWorkspaceUrls } from 'broker/pages/SubmissionWorkspacePage/hooks';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { TOOLBOX_TABS } from 'broker/pages/SubmissionWorkspacePage/types';

export interface QuoteItemActions {
  view: () => void;
  remove: () => void;
  hideQuoteActions?: boolean;
}

export default function useQuoteItemActions(quote: Quote, layer: Layer): QuoteItemActions | undefined {
  const modalActions = useModalActions();
  const { quoteFormUrl } = useWorkspaceUrls();
  const navigate = useNavigate();
  const { markets } = useSubmissionsWorkspace();

  const deleteQuote = () => {
    modalActions?.openRemoveQuoteDialog({
      marketActiveQuotes: [quote],
      disableBackdrop: false,
      quoteType: QuoteFileIdType.MarketQuote,
    });
  };

  const viewAndUpdateQuote = () => {
    const quoteMarketRequest = markets
      .find((market) => market.id === quote.submissionMarketId)
      ?.marketRequests.find((marketRequest) => marketRequest.quoteId === quote.id);

    navigate(
      { pathname: quoteFormUrl, search: `${createSearchParams([[BrokerUrlParams.ACTIVE_TAB, TOOLBOX_TABS.FILES]])}` },
      {
        routeKey: RouteKey.Quote,
        state: { selectedQuote: quote, mode: FormMode.edit, submissionMarketRequestId: quoteMarketRequest?.id },
      },
    );
  };

  const viewAndUpdatePolicy = () => {
    navigate(
      { pathname: quoteFormUrl, search: `${createSearchParams([[BrokerUrlParams.ACTIVE_TAB, TOOLBOX_TABS.FILES]])}` },
      {
        routeKey: RouteKey.Quote,
        state: {
          selectedQuote: quote,
          fileType: QuoteFileIdType.Policy,
          mode: FormMode.edit,
        },
      },
    );
  };

  const removePolicy = () => {
    modalActions?.openRemoveQuoteDialog({
      marketActiveQuotes: [quote],
      disableBackdrop: false,
      quoteType: QuoteFileIdType.Policy,
    });
  };

  const removeBinder = () => {
    modalActions?.openRemoveQuoteDialog({
      marketActiveQuotes: [quote],
      disableBackdrop: false,
      quoteType: QuoteFileIdType.Binder,
    });
  };

  const viewAndUpdateBinder = () => {
    navigate(
      { pathname: quoteFormUrl, search: `${createSearchParams([[BrokerUrlParams.ACTIVE_TAB, TOOLBOX_TABS.FILES]])}` },
      {
        routeKey: RouteKey.Quote,
        state: {
          selectedQuote: quote,
          fileType: QuoteFileIdType.Binder,
          mode: FormMode.edit,
        },
      },
    );
  };

  switch (layer.status) {
    case LayerStatus.Reviewing:
      return { view: viewAndUpdateQuote, remove: deleteQuote };
    case LayerStatus.Bound:
      return { view: viewAndUpdateBinder, remove: removeBinder };
    case LayerStatus.PolicyIssued:
      return { view: viewAndUpdatePolicy, remove: removePolicy };
    default:
      return undefined;
  }
}
