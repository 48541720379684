import { Stack } from '@common-components';
import { FormMode } from 'enums';
import FormTextField from 'components/hookFormComponents/FormTextField';
import { detailsFormFieldsConfig } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/form-methods/config/detailsFieldsConfig';
import { DetailsFieldsNames } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/form-methods/enums';
import { CommissionTextField } from './CommissionTextField';

interface BinderDetailsProps {
  mode: FormMode;
}
export default function BinderDetails({ mode }: BinderDetailsProps) {
  const isDisabled = mode === FormMode.view;
  return (
    <Stack gap={1}>
      <FormTextField
        disabled={isDisabled}
        fullWidth
        {...detailsFormFieldsConfig[DetailsFieldsNames.PolicyNumber]}
        optional
      />
      <CommissionTextField isDisabled={isDisabled} />
    </Stack>
  );
}
