import { Address } from '@common/types';
import { HeraldCategoryType, HeraldFormInputType, HeraldSchemaFormat } from 'clients/types';
import { PartialSubmission } from 'types';
import {
  DynamicFormQuestionSource,
  heraldDueDateParameterIds,
  HeraldNormalizedParameter,
} from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/DynamicForm/types';
import {
  buildDynamicFormAddressValue,
  heraldMailingAddressQuestionIds,
  heraldPrimaryAddressQuestionIds,
} from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/DynamicForm/utils/address-utils';
import { DynamicFormAddressSchema } from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/DynamicForm/utils/schema-utils';
import { FlowStaticQuestionId } from './flow-static-question-id';

interface FlowStaticQuestionConfigProps extends Omit<HeraldNormalizedParameter, 'value'> {
  getValue: (submission: PartialSubmission) => any;
}

export const flowStaticQuestionsConfig: FlowStaticQuestionConfigProps[] = [
  {
    getValue: (submission: PartialSubmission) => submission?.needByDate,
    parameter_id: FlowStaticQuestionId.NeedByDate,
    fieldLabel: 'Need By Date',
    section: 'Coverages',
    creates_array: false,
    affects_conditions: false,
    input_type: HeraldFormInputType.Date,
    heraldCategory: HeraldCategoryType.CoverageValues,
    isRequired: true,
    source: DynamicFormQuestionSource.Flow,
    schema: {
      title: '',
      type: 'string',
      format: HeraldSchemaFormat.Date,
    },
    showAfterOneOfTheseQuestions: heraldDueDateParameterIds,
  },
  {
    getValue: (submission: PartialSubmission): Address =>
      submission?.insuredPrimaryAddress ? buildDynamicFormAddressValue(submission.insuredPrimaryAddress) : {},
    parameter_id: FlowStaticQuestionId.PrimaryAddress,
    fieldLabel: "Applicant's Primary Address",
    section: 'Basic Information',
    creates_array: false,
    affects_conditions: false,
    input_type: HeraldFormInputType.Address,
    heraldCategory: HeraldCategoryType.RiskValues,
    isRequired: true,
    isHidden: (heraldNormalizedParameters: HeraldNormalizedParameter[]) =>
      heraldNormalizedParameters.some((heraldNormalizedParameter) =>
        heraldPrimaryAddressQuestionIds.includes(heraldNormalizedParameter.parameter_id),
      ),
    source: DynamicFormQuestionSource.Flow,
    schema: DynamicFormAddressSchema,
    showAfterOneOfTheseQuestions: ['rsk_m4p9_insured_name'],
  },
  {
    getValue: (submission: PartialSubmission): Address =>
      submission?.insuredMailingAddress ? buildDynamicFormAddressValue(submission.insuredMailingAddress) : {},
    parameter_id: FlowStaticQuestionId.MailingAddress,
    fieldLabel: "Applicant's Mailing Address",
    section: 'Basic Information',
    creates_array: false,
    affects_conditions: false,
    input_type: HeraldFormInputType.Address,
    heraldCategory: HeraldCategoryType.RiskValues,
    isRequired: false,
    isHidden: (heraldNormalizedParameters: HeraldNormalizedParameter[]) =>
      heraldNormalizedParameters.some((heraldNormalizedParameter) =>
        heraldMailingAddressQuestionIds.includes(heraldNormalizedParameter.parameter_id),
      ),
    source: DynamicFormQuestionSource.Flow,
    schema: DynamicFormAddressSchema,
    showAfterOneOfTheseQuestions: ['rsk_m4p9_insured_name'],
  },
];
