import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@common-components';
import { ThumbDownOutlined, ThumbUpOutlined } from '@icons';
import { FormControlHelperTextMarginBottom, FormControlRadioButtonPaddingBottom } from 'themes';
import { normalizeError } from 'utils';
import InputLabel from './InputLabel';
import { FormHelperTextStyles } from './styles';

type RadioButtonOption<T> = { value: T; label: string };

interface FormRadioProps<T> {
  label: string;
  name: string;
  id: string;
  defaultValue?: T;
  options: RadioButtonOption<T>[];
  onChange?: (value: string) => void;
  disabled?: boolean;
  paddingBottom?: number;
  suggestion?: T;
}

export default function FormRadioButton<T>({
  name,
  id,
  label,
  defaultValue,
  options,
  onChange,
  disabled = false,
  suggestion,
  paddingBottom = FormControlRadioButtonPaddingBottom,
}: FormRadioProps<T>) {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const errorMessage = normalizeError(errors, name);

  const [localSuggestion, setLocalSuggestion] = useState<T | null>(null);

  useEffect(() => {
    // If propString is not undefined and it's a string
    if (typeof suggestion === 'string') {
      setLocalSuggestion(suggestion);
      setValue(name, suggestion);
    }
  }, [name, setValue, suggestion]); // Run effect when propString changes

  return (
    <FormControl
      fullWidth
      sx={{
        pb: paddingBottom,
        mb: errorMessage ? FormControlHelperTextMarginBottom : 0,
        height: 1,
        justifyContent: 'space-between',
      }}
    >
      <InputLabel id={id} error={!!errorMessage} label={label} htmlFor={name} />
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || ''}
        render={({ field: { onChange: formHookOnChange, ...fieldProps } }) => (
          <Stack
            px={2}
            py={1}
            borderRadius={1}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              bgcolor: localSuggestion ? 'green.100' : 'inherit',
            }}
          >
            <RadioGroup
              {...fieldProps}
              id={id}
              row
              sx={{
                position: 'relative',
                '&:before': {
                  content: localSuggestion ? '""' : undefined,
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  zIndex: 2,
                  pointerEvents: 'all',
                },
              }}
              onChange={(event) => {
                formHookOnChange(event.target.value);
                onChange?.(event.target.value);
              }}
              defaultValue={defaultValue}
            >
              {options.map((option) => (
                <FormControlLabel
                  disabled={disabled}
                  key={option.label}
                  value={option.value}
                  control={<Radio />}
                  label={
                    <Typography
                      variant="body1"
                      fontWeight={localSuggestion && localSuggestion === option.value ? 'bolder' : 'normal'}
                    >
                      {option.label}
                    </Typography>
                  }
                />
              ))}
            </RadioGroup>
            {localSuggestion && (
              <Stack direction="row" gap={1} alignItems="center">
                <IconButton
                  tooltipContent="Reject suggestion"
                  icon={ThumbDownOutlined}
                  size="small"
                  variant="text"
                  onClick={() => {
                    setLocalSuggestion(null);
                    formHookOnChange('');
                  }}
                  color="secondary"
                />
                <IconButton
                  tooltipContent="Accept suggestion"
                  icon={ThumbUpOutlined}
                  size="small"
                  onClick={() => {
                    setLocalSuggestion(null);
                    formHookOnChange(localSuggestion);
                    onChange?.(localSuggestion as unknown as string);
                  }}
                  color="secondary"
                  bgcolor="green.300"
                />
              </Stack>
            )}
          </Stack>
        )}
      />
      {errorMessage && (
        <FormHelperText sx={FormHelperTextStyles} error>
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  );
}
