import { partition } from 'lodash';
import { useMemo } from 'react';
import { InboundEmailMessageStatus } from 'enums';
import { useCurrentUser } from 'hooks';
import { useSearchInboundEmailMessage } from 'hooks/api/inboundEmailMessage';
import { useSearchSubmissionEmail } from 'hooks/api/submissionEmail';
import { InboundEmailMessageSearchItem, SubmissionEmailSearchItem } from 'types';
import { getDotSeparated, sortByDate } from 'utils';
import { getRecipientName } from 'broker/components/Emails/recipient-utils';
import { EmailItemType } from 'broker/components/Emails/types';
import { useGetFullNameByEmail } from 'broker/pages/SubmissionWorkspacePage/hooks/useGetFullNameByEmail';
import { EmailListItemAttributes } from './utils';

interface UseGetSubmissionRelatedEmailsProps {
  submissionId?: string;
  marketOrganizationId?: string;
}

function mapSubmissionEmailToEmailListItemAttributes(emails: SubmissionEmailSearchItem[]) {
  return emails.map(
    (email) =>
      ({
        id: email.id,
        recipientName: getRecipientName(email.recipients, email.recipientOrganizationDetails),
        date: email.date,
        hasAttachments: email.hasAttachments,
        subject: email.subject,
        boxFileId: email.boxFileId,
        type: EmailItemType.SubmissionOutbound,
        emailMessageBatchId: email.emailMessageBatchId,
        recipientOrganization: email.recipientOrganizationDetails,
        label: email.label,
        labels: email.labels,
      } as EmailListItemAttributes),
  );
}

const mapInboundEmailToEmailListItemAttributes = (
  emails: InboundEmailMessageSearchItem[],
  type: EmailItemType,
  getFullName: (email: string) => string,
) =>
  emails.map(
    (email) =>
      ({
        id: email.id,
        senderEmail: getDotSeparated([getFullName(email.sender?.toLocaleLowerCase()), email.senderOrganization?.name]),
        date: email.receivedAt,
        hasAttachments: email.hasAttachments,
        subject: email.subject,
        boxFileId: email.emlBoxFileId,
        type,
        senderOrganization: email.senderOrganization,
        submissionInboundEmailId: email.submissionInboundEmailId,
        label: email.label,
        labels: email.labels,
        classifiedByUser: email.classifiedByUser,
        submissionMarketRequestId: email.submissionMarketRequestId,
      } as EmailListItemAttributes),
  );

const getSubmissionRelatedInboxEmails = (
  emails: InboundEmailMessageSearchItem[],
  type: EmailItemType,
  getFullName: (email: string) => string,
) => sortByDate(mapInboundEmailToEmailListItemAttributes(emails, type, getFullName));

/*
 * This function is used to map the inbound emails that were sent from our user, so they are treated as outbound emails
 */
const mapInboundEmailToSubmissionEmail = (submissionInboundEmails: InboundEmailMessageSearchItem[]) =>
  submissionInboundEmails.map(
    (email) =>
      ({
        id: email.id,
        date: email.receivedAt,
        hasAttachments: email.hasAttachments,
        subject: email.subject,
        boxFileId: email.emlBoxFileId,
        type: EmailItemType.SubmissionInboundFromUser,
        recipientName: email.recipientOrganization?.name ?? email.recipient,
        recipientOrganization: email.recipientOrganization,
        senderOrganization: email.senderOrganization,
        submissionInboundEmailId: email.submissionInboundEmailId,
        label: email.label,
        labels: email.labels,
      } as EmailListItemAttributes),
  );

export default function useGetSubmissionRelatedEmails({
  submissionId,
  marketOrganizationId,
}: UseGetSubmissionRelatedEmailsProps) {
  const { me } = useCurrentUser();

  const { items: outboundEmails, isInitialLoading: loadingOutBoundEmails } = useSearchSubmissionEmail({
    filter: { submissionId, recipientOrganizationId: marketOrganizationId },
    enabled: !!submissionId,
  });
  const { items: unassignedInboundEmails, isLoading: loadingUnassignedInboundEmails } = useSearchInboundEmailMessage({
    filter: {
      status: InboundEmailMessageStatus.New,
    },
  });
  const { items: submissionInboundEmails, isInitialLoading: loadingSubmissionInboundEmails } =
    useSearchInboundEmailMessage({
      filter: {
        submissionId,
        senderOrganizationId: marketOrganizationId,
        excludedStatus: InboundEmailMessageStatus.Processing,
      },
      enabled: !!submissionId,
    });

  const { getFullName } = useGetFullNameByEmail();

  const [submissionInboundEmailsFromUser, submissionInboundEmailsNotFromUser] = useMemo(
    () =>
      partition(
        submissionInboundEmails,
        (submissionInboundEmail) => submissionInboundEmail.senderOrganization?.id === me?.organization.id,
      ),
    [submissionInboundEmails, me],
  );

  const isLoading = loadingUnassignedInboundEmails || loadingOutBoundEmails || loadingSubmissionInboundEmails;
  const inboundUnassignedEmailsList = useMemo(
    () => getSubmissionRelatedInboxEmails(unassignedInboundEmails, EmailItemType.UnassignedInbound, getFullName),
    [getFullName, unassignedInboundEmails],
  );
  const submissionEmailsList = useMemo(
    () =>
      sortByDate([
        ...mapInboundEmailToEmailListItemAttributes(
          submissionInboundEmailsNotFromUser,
          EmailItemType.SubmissionInbound,
          getFullName,
        ),
        ...mapSubmissionEmailToEmailListItemAttributes(outboundEmails),
        ...mapInboundEmailToSubmissionEmail(submissionInboundEmailsFromUser),
      ]),
    [getFullName, outboundEmails, submissionInboundEmailsFromUser, submissionInboundEmailsNotFromUser],
  );

  return {
    inboundUnassignedEmailsList,
    submissionEmailsList,
    isLoading,
  };
}
