import { Backdrop, Box, IconButton, Typography } from '@common-components';
import { SpeakerNotesOutlined as SpeakerNotesOutlinedIcon } from '@icons';
import { useBoolean } from 'hooks';

import { messages } from 'i18n';
import { truncateMultiLineText } from 'utils';
import { DirtyActionsGuard } from 'broker/components/DirtyContentGuard/DirtyActionsGuard';
import NoteForm from './NoteForm';

interface NoteProps {
  id: string;
  note?: string;
}

export default function Note({ note, id }: NoteProps) {
  const [isOpen, { off: close, on: open }] = useBoolean(false);

  return (
    <DirtyActionsGuard>
      {({ closeAttemptWithAction, closeWithoutPromptWithAction, isContentDirty, setIsContentDirty }) => (
        <>
          {note ? (
            <Typography
              width={1}
              maxWidth={400}
              onClick={open}
              title={note}
              variant="body2"
              sx={{
                ...truncateMultiLineText(6),
                cursor: 'pointer',
              }}
            >
              {note}
            </Typography>
          ) : (
            <Typography width={1} onClick={open} color="text.secondary" variant="body2">
              {messages.general.addNote}...
            </Typography>
          )}
          <Backdrop
            invisible
            open={isOpen}
            sx={{ zIndex: (theme) => theme.zIndex.tooltip - 1 }}
            onClick={() => closeAttemptWithAction(close)}
          />
          <Box textAlign="right">
            <IconButton
              edge="end"
              tooltipContent={
                isOpen ? (
                  <NoteForm
                    isContentDirty={isContentDirty}
                    setIsContentDirty={setIsContentDirty}
                    closeWithoutPrompt={() => closeWithoutPromptWithAction(close)}
                    id={id}
                    note={note}
                  />
                ) : (
                  ''
                )
              }
              isTooltipOpen={isOpen}
              icon={SpeakerNotesOutlinedIcon}
              onClick={open}
              variant="text"
              active={!!note}
              tooltipPlacement="left"
              size="small"
              color="secondary"
              disableTooltipPadding
            />
          </Box>
        </>
      )}
    </DirtyActionsGuard>
  );
}
