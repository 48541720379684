import { AddressDetails } from '@common/types';
import { CompanyType } from 'enums';
import { FormFieldsConfigProps } from 'hooks';
import { IndustryAutoCompleteOption } from 'broker/components/common/IndustryAutoComplete/types';

// interface that represents the submission Form
export interface SubmissionFormData {
  insuredName: string;
  insuredId?: string | null;
  industry?: IndustryAutoCompleteOption;
  insuredCompanyType?: CompanyType | null;
  customers?: string[];
  insuredPrimaryAddress?: AddressDetails;
  insuredMailingAddress?: AddressDetails;
  limit: string;
  dueDate?: Date;
  needByDate?: Date;
  insuredAnnualRevenue?: string | null;
  insuredEmployeeCount?: string | null;
  insuredWebsite?: string | null;
}

// used by the submission form config to define each field + config to determine if form fields are filled in the existing submission
export enum SubmissionFormFieldsNames {
  InsuredName = 'insuredName',
  Industry = 'industry',
  InsuredCompanyType = 'insuredCompanyType',
  InsuredPrimaryAddress = 'insuredPrimaryAddress',
  InsuredMailingAddress = 'insuredMailingAddress',
  Limit = 'limit',
  DueDate = 'dueDate',
  NeedByDate = 'needByDate',
  InsuredAnnualRevenue = 'insuredAnnualRevenue',
  InsuredEmployeeCount = 'insuredEmployeeCount',
  InsuredWebsite = 'insuredWebsite',
}

export enum StaticFormSection {
  InsuredInformation = 'InsuredInformation',
  CoverageParameters = 'CoverageParameters',
}

export type FormFieldsConfig = Record<
  SubmissionFormFieldsNames,
  FormFieldsConfigProps<SubmissionFormFieldsNames> & { section: StaticFormSection }
>;
